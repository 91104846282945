import React from 'react';
import Content from '../../services/models/content';
import Course from '../../services/models/course';
import Discipline from '../../services/models/discipline';

interface IDisciplineContext {
  trailId?: string;
  courseId?: string;
  course?: Course;
  content?: Content;
  discipline?: Discipline;
  trailAlreadyFinished: boolean;
  updateContentProgress: (time: number | null) => void;
  playerType: string;
  approvedOnAllCourses: boolean;
  certificateAlreadyCreated: boolean;
  startContent: (isPreview?: boolean) => Promise<void>;
  finishContent: (
    justModuleAndLesson?: boolean,
    isPreview?: boolean,
  ) => Promise<void>;
  goToNextContent: () => void;


}

const DisciplineContext = React.createContext<IDisciplineContext>({
  trailId: undefined,
  courseId: undefined,
  course: undefined,
  content: undefined,
  discipline: undefined,
  trailAlreadyFinished: false,
  updateContentProgress: () => {},
  playerType: '',
  approvedOnAllCourses: false,
  certificateAlreadyCreated: false,
  startContent: async () => {},
  finishContent: async () => {},
  goToNextContent: () => {},
});

export default DisciplineContext;
