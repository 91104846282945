import convertToExam from '../../helpers/converters/convert-exam';
import convertToQuestion from '../../helpers/converters/convert-question';
import httpClient from '../../http-client';
import Exam from '../../services/models/exam';
import ExamFromResponse from '../../services/models/from-api-result/exam';
import QuestionFromResponse from '../../services/models/from-api-result/exam-question';
import Question from '../../services/models/exam-question';

const getExam = async (examId: string) => {
  const exam = (await httpClient.get<ExamFromResponse>(`/exams/${examId}`))
    .data;

  let convertedExam = {} as Exam;
  if (exam && Object.keys(exam).length) {
    convertedExam = convertToExam(exam);
  }

  return convertedExam;
};

const getExamUser = async (examId: string, courseId: string) => {
  const examUser = (
    await httpClient.get<{ exam_user_id: string; final_date: string }[]>(
      `/exam-users/${examId}/${courseId}/attempts`,
    )
  ).data;

  return examUser;
};

const answer = async (examAnswerUserId: string, answerId: string) => {
  await httpClient.put(`/exam-users/answer`, {
    exam_answer_user_id: examAnswerUserId,
    answer_id: answerId,
  });
};

const getAnswers = async (examUserId: string) => {
  const answers = (
    await httpClient.get<
      { exam_answer_user_id: string; question_id: string; answer_id: string }[]
    >(`/exam-users/${examUserId}/answers`)
  ).data;

  return answers;
};

const startExam = async (examId: string, courseId: string) => {
  const startedExamData = (
    await httpClient.post<{ exam_user_id: string }>(`/exam-users/start`, {
      exam_id: examId,
      reference_id: courseId,
    })
  ).data;

  return startedExamData;
};

const finishExam = async (examUserId: string) => {
  const finishedExamData = (
    await httpClient.put<{ exam_user_id: string }>(`/exam-users/finish`, {
      exam_user_id: examUserId,
    })
  ).data;

  return finishedExamData;
};

const getQuestion = async (questionId: string) => {
  const question = (
    await httpClient.get<QuestionFromResponse>(`questions/${questionId}`)
  ).data;

  let convertedQuestion = {} as Question;
  if (question && Object.keys(question).length) {
    convertedQuestion = convertToQuestion(question);
  }

  return convertedQuestion;
};

export {
  getExam,
  startExam,
  answer,
  getAnswers,
  finishExam,
  getExamUser,
  getQuestion,
};
