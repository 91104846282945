import styled from 'styled-components';

export const CardContainer = styled.div`
  width: 90vw;
  margin: 3rem;
  .list-cards {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const NavContainer = styled.div`
  border-bottom: solid 5px #009ee0;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;

  .library-title {
    font-size: 40px;
    color: #009ee0;
  }
`;
