import React, { useEffect, useState } from 'react';
import { FavoriteCoursesContainer } from './styles';
import { getFavoriteCourses as getFavoriteCoursesService } from '../../../../../../services/MyProgress';
import Course from '../../../../../../services/models/course';
import CourseCard from '../../../../../../components/CourseCard';

const FavoriteCourses: React.FC = () => {
  const [FavoriteCourses, setFavoriteCourses] = useState(
    [] as Course[],
  );

  const getFavoriteCourses = async () => {
    const localFavoriteCourses = await getFavoriteCoursesService();
    setFavoriteCourses(localFavoriteCourses);
  };

  useEffect(() => {
    getFavoriteCourses();
  }, []);

  return (
    <FavoriteCoursesContainer>
      {FavoriteCourses && FavoriteCourses.length ? (
        FavoriteCourses.map(course => (
          <CourseCard
            key={course.id}
            course={course}
            onAddToList={getFavoriteCourses}
          />
        ))
      ) : (
        <h4>Não foram encontrados cursos favoritos!</h4>
      )}
    </FavoriteCoursesContainer>
  );
};

export default FavoriteCourses;
