import React from 'react';
import { BiTrophy } from 'react-icons/bi';
import { setMedalColor } from '../../index';
import { BoxItem } from '../../styles';

interface IMedalBox {
  medal: string;
  position: number;
}

const MedalBox: React.FC<IMedalBox> = ({ medal, position }) => {
  const medalColor = setMedalColor(position);
  return (
    <BoxItem>
      <BiTrophy size={35} color={medalColor} />
      <span>{medal}</span>
    </BoxItem>
  );
}

export default MedalBox;
