import styled from 'styled-components';
import { Container as GoBack } from '../../components/GoBack/style';

export const CoursesContainer = styled.div`
  .courses-cards {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(428px, 1fr));
    grid-gap: 16px;
    justify-items: center;
  }

  .box {
    width: 85vw;
    margin: 0 auto;

    ${GoBack} {
      margin-bottom: 5px;
      margin-top: 20px;
    }
  }
`;

export const Courses = styled.div``;

export const NavContainer = styled.div`
  border-bottom: 5px solid #009ee0;
  width: 100%;
  margin-bottom: 25px;
  padding-bottom: 30px;

  align-items: center;

  @media (min-width: 771px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const CourseTitle = styled.h2`
  color: #009ee0;
  font-size: 40px;
  margin: 2rem 0;
`;
