import React from 'react';
import User from './services/models/user';

interface IUserContext {
  user?: User;
  setUser?: (user: User) => void;
}

const UserContext = React.createContext<IUserContext>({
  user: {} as User,
  setUser: () => {},
});

export default UserContext;
