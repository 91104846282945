import styled from 'styled-components';

export const MenuItemContainer = styled.div `

  a {
    cursor: pointer;
    padding: 20px 20px;
    color: #333;
    text-decoration: none;
    font-weight: bold;
    border-bottom: solid 3px transparent;
    transition: border 250ms ease;

    &:hover, &.active {
      border-color: #009ee0;
    }
  }
`;
