import React, { useEffect, useState } from 'react';
import { DisciplineBookMarkContainer, DisciplineCardContainer } from './style';
import { useHistory } from 'react-router-dom';
import { HiDownload } from 'react-icons/hi';
import Discipline from '../../services/models/discipline';
import ProgressBar from '../ProgressBar';
import ThumbDefault from '../../assets/thumb-default.png';
import { downloadCertificate as downloadCertificateService } from '../../services/Certificate/';
import { BsBookmark, BsBookmarkFill } from 'react-icons/bs';
import { addDisciplineToList } from '../../services/Discipline';
import { toast } from 'react-toastify';

const DisciplineBookMark: React.FC<{
  disciplineId: string;
  addedToList: boolean;
  onAddToList?: () => Promise<void>
}> = ({ disciplineId, addedToList, onAddToList }) => {
  const [isAddedToList, setIsAddedToList] = useState(false);

  const addToList = async () => {
    const shouldAddToList = !isAddedToList;
    try {
      await addDisciplineToList(disciplineId!, shouldAddToList);
      setIsAddedToList(shouldAddToList);
      if (onAddToList) {
        onAddToList();
      }
    } catch (error) {
      const errorMessage =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message;

      toast.error(
        `Erro ao adicionar ${
          disciplineId ? 'a disciplina' : 'o curso'
        } aos favoritos. ${errorMessage}`,
      );
    }
  };

  useEffect(() => {
    setIsAddedToList(addedToList);
  }, [addedToList]);

  return (
    <DisciplineBookMarkContainer onClick={addToList}>
      {isAddedToList ? <BsBookmarkFill size={22} /> : <BsBookmark size={22} />}
    </DisciplineBookMarkContainer>
  );
};

interface DisciplineCardProps {
  discipline: Discipline;
  withoutProgressBar?: boolean;
  forCertificateDownload?: boolean;
  onAddToList?: () => Promise<void>;
}

const DisciplineCard: React.FC<DisciplineCardProps> = ({
  discipline,
  withoutProgressBar,
  forCertificateDownload,
  onAddToList
}) => {
  const history = useHistory();
  const [thumb, setThumb] = useState(discipline.thumbnail);

  const goToDiscipline = () => {
    history.push(`disciplina/${discipline.id}`);
  };

  const timeInt = discipline.duration;
  const durationConvert = Math.floor(timeInt / 60);

  const onErrorThumb = () => {
    setThumb(ThumbDefault);
  };

  const downloadCertificate = async () => {
    await downloadCertificateService(discipline.id);
  };

  return (
    <DisciplineCardContainer>
      <div className="upper-content">
        <div className="discipline-image-container" onClick={goToDiscipline}>
          <img
            src={thumb || ThumbDefault}
            onError={onErrorThumb}
            alt="disciplina"
          />
          <span className="discipline-duration">
            {durationConvert < 1 ? '< 1' : durationConvert} min
          </span>
        </div>
      </div>

      <div className="lower-content">
        <div className="discipline-infos">
          <h3 className="discipline-title">{discipline.name}</h3>

          <DisciplineBookMark
            disciplineId={discipline.id}
            addedToList={discipline.addedToList}
            onAddToList={onAddToList}
          />
        </div>

        {!withoutProgressBar && (
          <ProgressBar progress={discipline.trail_user_progress * 100} />
        )}

        {forCertificateDownload && (
          <div className="download-certificate" onClick={downloadCertificate}>
            <span className="download-certificate-label">
              Baixe o certificado
            </span>

            <span className="download-certificate-icon">
              <HiDownload size={20} color="#1C3383" />
            </span>
          </div>
        )}
      </div>
    </DisciplineCardContainer>
  );
};

export default DisciplineCard;
