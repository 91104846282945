import React from 'react';
import { FooterContainer } from './style';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {

  return (
    <FooterContainer>
      <img 
        src={`${process.env.PUBLIC_URL}/logo-vallourec.png`} 
        alt="logo" className="logo-footer"
      />

      <div className="spacer"></div>

      <div className="copy">
        &copy; 2020 Vallourec
      </div>

      <Link to="/contato" className="contact">
        Contato
      </Link>
    </FooterContainer>
  );
}

export default Footer;