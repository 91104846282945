import CertificateData from '../../../services/models/certificate-data';
import CertificateTemplate from '../templates/vallourec-template';
import { pdf } from '@react-pdf/renderer';
import { getDiscipline } from '../../../services/Discipline';
import { getActualUser } from '../../../services/User';

const generateCertificate = async (certificateData: CertificateData) => {
  const trail = await getDiscipline(certificateData.trail_id);
  const user = await getActualUser();

  const VallourecCertificateTemplate = (
    <CertificateTemplate
      certificateData={certificateData}
      trail={trail}
      user={user}
    />
  );

  return pdf(VallourecCertificateTemplate).toBlob();
};

export { generateCertificate };
