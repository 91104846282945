import styled from 'styled-components';

const CutImageContainer = styled.div`
  width: 100%;
  height: 100%;

  & button {
    padding: 10px 15px;
    background: var(--primary-blue);
    border: none;
    outline: none;
    cursor: pointer;
    opacity: 0.9;
    transition: all 0.3s;
    color: white;
    font-weight: bold;
    border-radius: 5px;

    &:hover {
      opacity: 1;
    }
  }

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const SelectImageContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageCutContainer = styled.div`
  flex-grow: 1;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export { CutImageContainer, SelectImageContainer, ImageCutContainer };
