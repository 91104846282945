import React, { useEffect, useMemo, useState } from 'react';
import GoBack from '../../components/GoBack';
import { LiveContainer } from './style';
import {
  getActiveLive as getActiveLiveService,
  getLives as getLivesService,
} from '../../services/Lives';
import ILive from '../../services/models/live';
import Slider from 'react-slick';
import { GrPrevious, GrNext } from 'react-icons/gr';
import styled from 'styled-components';

const PrevOrNextButtonContainer = styled.button`
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background: #009ee0;
  position: absolute;
  top: 40%;
  right: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  &.slick-disabled {
    background: #dcdcdc;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

interface IPrevOrNextButtonProps {
  onClick?: () => void;
  className?: string;
}

const SliderPrevButton: React.FC<IPrevOrNextButtonProps> = ({
  onClick,
  className,
}) => {
  return (
    <PrevOrNextButtonContainer onClick={onClick} className={className}>
      <GrPrevious size={24} />
    </PrevOrNextButtonContainer>
  );
};

const SliderNextButton: React.FC<IPrevOrNextButtonProps> = ({
  onClick,
  className,
}) => {
  return (
    <PrevOrNextButtonContainer onClick={onClick} className={className}>
      <GrNext size={24} />
    </PrevOrNextButtonContainer>
  );
};

const Live: React.FC = () => {
  const [lives, setLives] = useState([] as ILive[]);
  const [activeLive, setActiveLive] = useState(undefined as ILive | undefined);

  const getLives = async () => {
    let localLives = await getLivesService();

    const activeLives = await getActiveLiveService();
    setActiveLive((activeLives || []).find(live => live.channelSvId));

    localLives = (localLives || []).filter(
      live => !activeLives.map(actLive => actLive.liveId).includes(live.liveId),
    );

    setLives(localLives);
  };

  const liveUrl = useMemo(() => {
    if (activeLive) {
      const playerHash = process.env.REACT_APP_PLAYER_HASH;
      const playerBaseUrl = process.env.REACT_APP_BASE_REFERENCE_URL;
      if (activeLive.transmissionId) {
        return `${playerBaseUrl}/${playerHash}/live/${activeLive.channelSvId}`;
      }

      return `${playerBaseUrl}/${playerHash}`;
    }
    return '';
  }, [activeLive]);

  const nextLives = useMemo(() => {
    return (lives || []).filter(
      live =>
        live.status !== 'STOPPED' &&
        (live.start ? new Date(live.start) : new Date()).getTime() >=
          new Date().getTime(),
    );
  }, [lives]);

  useEffect(() => {
    getLives();
  }, []);

  return (
    <LiveContainer>
      <GoBack path="/home" />
      <h2 className="live-title">LIVE</h2>

      <div className="live-video-container">
        {activeLive ? (
          <>
            <iframe
              allowFullScreen={true}
              src={liveUrl}
              title="live"
              frameBorder={0}
            ></iframe>

            <h3 className="live-video-title">{activeLive.name}</h3>

            <p className="live-video-text">{activeLive.description}</p>
          </>
        ) : (
          <h4>Não foi encontrada nenhuma live ativa no momento.</h4>
        )}
      </div>

      <div className="next-lives">
        <h3>Próximas Lives</h3>
        {nextLives && nextLives.length ? (
          <Slider
            slidesToShow={3.2}
            slidesToScroll={1}
            infinite={false}
            speed={500}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2.2,
                },
              },
              {
                breakpoint: 550,
                settings: {
                  slidesToShow: 1.2,
                },
              },
            ]}
            prevArrow={<SliderPrevButton />}
            nextArrow={<SliderNextButton />}
          >
            {nextLives.map(live => (
              <span>
                <div
                  key={live.liveId}
                  className="live-card"
                  style={{ background: `url(${live.thumbnail})` }}
                >
                  <span>{live.name}</span>
                  <span>
                    <strong>Data de Inicio: </strong>{' '}
                    {live.start
                      ? new Intl.DateTimeFormat('pt-BR', {}).format(
                          new Date(live.start),
                        )
                      : ''}
                  </span>
                </div>
              </span>
            ))}
          </Slider>
        ) : (
          <h4>Não foram encontradas próximas lives.</h4>
        )}
      </div>
    </LiveContainer>
  );
};

export default Live;
