import styled from 'styled-components';

export const CourseRateContainer = styled.form`
  width: 450px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .cannot-change {
    button {
      cursor: normal;
    }
  }

  @media (max-width: 500px) {
    width: 320px;
  }

  & > div {
    display: flex;
    flex-direction: column;

    &.buttons,
    &.stars {
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }

    &.buttons {
      justify-content: flex-end;

      button {
        background: var(--primary-blue);
        color: white;
        cursor: pointer;
        border: none;
        outline: none;
        border-radius: 4px;
        padding: 8px 16px;
        transition: all 0.3s;

        :hover {
          opacity: 0.9;
        }
      }

      button[type='button'] {
        background: red;
      }
    }

    &.stars > div {
      margin-left: auto;

      button {
        background: none;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 2px;
        border-radius: 4px;

        :hover {
          background: rgba(0, 0, 0, 0.2);
        }
      }
    }

    textarea {
      resize: none;
      height: 120px;
      border-radius: 4px;
      border: solid 1px #999;
      outline: none;
      padding: 8px;
    }

    label {
      font-weight: bold;

      &.required {
        &::after {
          content: ' *';
          color: red;
        }
      }
    }
  }
`;
