import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../../pages/Home';
import Courses from '../../pages/Courses';
import FAQ from '../../pages/FAQ';
import MyData from '../../pages/MyData';
import MyProgress from '../../pages/MyProgress';
import MyAnnotations from '../../pages/MyAnnotations';
import Library from '../../pages/Library';
import Score from '../../pages/Score';
import Evaluation from '../../pages/Evaluation';
import VideoPage from '../../pages/Library/components/PageVideo';
import { RoutesContainer } from './style';
import Forum from '../../pages/Forum';
import LibraryVideos from '../../pages/Library/components/LibraryVideos';
import LibraryDocs from '../../pages/Library/components/LibraryDocs';
import Disciplines from '../../pages/Disciplines';
import Discipline from '../../pages/Discipline';
import NewQuestion from '../../pages/Forum/NewQuestion';
import Answers from '../../pages/Forum/Answers';
import Live from '../../pages/Live';
import Contact from '../Contact';


const Routes: React.FC = () => {
  return (
    <RoutesContainer>
      <Switch>
        <Route exact path="/home" component={Home} />
        <Route path="/perfil" component={MyData} />
        <Route path="/pontuacao" component={Score} />
        <Route path="/cursos" component={Courses} />
        <Route path="/disciplinas" component={Disciplines} />
        <Route path="/curso/:courseId/:contentId" component={Discipline} />
        <Route path="/curso/:courseId" component={Discipline} />
        <Route path="/disciplina/:disciplineId/:courseId/:contentId" component={Discipline}/>
        <Route path="/disciplina/:disciplineId" component={Discipline} />
        <Route path="/meus-cursos" component={MyProgress} />
        <Route path="/anotacoes" component={MyAnnotations} />
        <Route path="/biblioteca" component={Library} />
        <Route path="/biblioteca-videos" component={LibraryVideos} />
        <Route path="/biblioteca-materiais-apoio" component={LibraryDocs} />
        <Route path="/avaliacao/:examId/:courseId" component={Evaluation} />
        <Route path="/faq" component={FAQ} />
        <Route path="/live" component={Live} />
        <Route path="/forum" component={Forum} />
        <Route path="/forum-new" component={NewQuestion} />
        <Route path="/video/:id" component={VideoPage} />
        <Route path="/answers/:questionId" component={Answers} />
        <Route path="/contato" component={Contact} />
      </Switch>
    </RoutesContainer>
  );
};

export default Routes;
