import React from 'react';
import HomeInfoFromResponse from '../../../../services/models/from-api-result/home-info';
import { WhoWeAreContainer } from './style';

interface WhoWeAreProps {
  image: HomeInfoFromResponse;
}

const WhoWeAre: React.FC<WhoWeAreProps> = ({ image }) => {
  return (
    <WhoWeAreContainer>
      <img
        className="who-we-are-image"
        src={
          image && image.image
            ? image.image
            : `${process.env.PUBLIC_URL}/who-we-are.png`
        }
        alt="Quem somos"
      />

      <div className="who-we-are-text-content">
        <h3 className="who-we-are-title">QUEM SOMOS</h3>

        <h2 className="who-we-are-name">VALLOUREC</h2>

        <span className="who-we-are-text">
          {image.description ||
            `A Vallourec trabalha lado a lado com seus clientes para oferecer mais
          do que apenas tubos: oferecemos soluções inovadoras, seguras,
          competitivas e inteligentes para tornar todos os projetos possíveis`}
        </span>
      </div>
    </WhoWeAreContainer>
  );
};

export default WhoWeAre;
