import styled from 'styled-components';


export const UserDescriptionsBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px 0 15px;

  > h3 {
    font-size: 25px;
  }

  > span {
    font-size: 20px;
    font-weight: 700;
  }
`;
