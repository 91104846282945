import React, { useEffect, useState } from 'react';
import { getHomeInfo as getHomeInfoService } from '../../services/Home';
import HomeInfoFromResponse from '../../services/models/from-api-result/home-info';
import AvailableDisciplines from './components/AvailableDisciplines';
import HomeCarousel from './components/HomeCarousel';
import WhoWeAre from './components/WhoWeAre';
import { HomeContainer } from './style';
import Indicators from './components/Indicators';
import RecommendedCourses from './components/RecommendedCourses';

const Home: React.FC = () => {
  const [banners, setBanners] = useState([] as HomeInfoFromResponse[]);
  const [aboutUs, setAboutUs] = useState({} as HomeInfoFromResponse);

  const getHomeInfo = async () => {
    const localHomeInfo = await getHomeInfoService();

    if (localHomeInfo && localHomeInfo.length) {
      const bannerImages = localHomeInfo.filter(
        hi => hi.location.toLocaleLowerCase() === 'banner',
      );
      const aboutUsImages = localHomeInfo.find(
        hi => hi.location.toLocaleLowerCase() === 'about_us',
      );

      setBanners(bannerImages);
      setAboutUs(aboutUsImages || ({} as HomeInfoFromResponse));
    }
  };

  useEffect(() => {
    getHomeInfo();
  }, []);
  

  return (
    <HomeContainer>
      <HomeCarousel images={banners} />

      <AvailableDisciplines />

      <WhoWeAre image={aboutUs} />

      <RecommendedCourses />

      <Indicators />
    </HomeContainer>
  );
};

export default Home;
