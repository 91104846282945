import React, { useEffect, useState } from 'react';
import { getFavoriteDisciplines as getFavoriteDisciplinesService } from '../../../../../../services/MyProgress';
import Discipline from '../../../../../../services/models/discipline';
import DisciplineCard from '../../../../../../components/DisciplineCard';
import { FavoriteDisciplinesContainer } from './styles';

const FavoriteDisciplines: React.FC = () => {
  const [FavoriteDisciplines, setFavoriteDisciplines] = useState(
    [] as Discipline[],
  );

  const getFavoriteDisciplines = async () => {
    const localFavoriteDisciplines = await getFavoriteDisciplinesService();
    setFavoriteDisciplines(localFavoriteDisciplines);
  };

  useEffect(() => {
    getFavoriteDisciplines();
  }, []);

  return (
    <FavoriteDisciplinesContainer>
      {FavoriteDisciplines && FavoriteDisciplines.length ? (
        FavoriteDisciplines.map(discipline => (
          <DisciplineCard
            key={discipline.id}
            discipline={discipline}
            onAddToList={getFavoriteDisciplines}
          />
        ))
      ) : (
        <h4>Não foram encontradas disciplinas favoritas!</h4>
      )}
    </FavoriteDisciplinesContainer>
  );
};

export default FavoriteDisciplines;
