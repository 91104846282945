import styled from 'styled-components';

export const LibraryItemCardContainer = styled.div`
  margin: 15px;
  min-width: 320px;

  a {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    color: #333;
    text-decoration: none;

    .library-item-image-container {
      position: relative;

      .library-item-image {
        width: 100%;
        min-height: 200px;
      }

      .library-item-icon-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          
          path {
            color: #FFF;
          }
        }

        &:hover {
          background: rgba(255, 255, 255, 0.3);
          transition: 0.3s;
        }
      }
    }

    .library-item-title {
      color: #333;
      font-size: 22px;
      margin: 15px auto;

      &:hover {
        opacity: 0.7;
        transition: 0.3s;
      }
    }
  }
`;