import React, { useEffect, useState } from 'react';
import ExtraMaterial from '../../../../../../services/models/extra-material';
import ExtraMaterialCard from './components/ExtraMaterialCard';
import { ExtraMaterialList, SupportMaterialsContainer } from './style';
import { getExtraMaterials as getExtraMaterialsService } from '../../../../../../services/ExtraMaterials';

interface SupportMaterialsProps {
  contentId: string;
}

const SupportMaterials: React.FC<SupportMaterialsProps> = ({ contentId }) => {
  const [extraMaterials, setExtraMaterials] = useState([] as ExtraMaterial[]);

  const getExtraMaterials = async () => {
    setExtraMaterials([]);
    const localExtraMaterials = await getExtraMaterialsService(contentId);
    setExtraMaterials(localExtraMaterials);
  };

  useEffect(() => {
    getExtraMaterials();
  }, [contentId]);

  return (
    <SupportMaterialsContainer>
      {extraMaterials && extraMaterials.length ? (
        <ExtraMaterialList>
          {extraMaterials && extraMaterials.length ? (
            extraMaterials.map(em => (
              <ExtraMaterialCard key={em.id} extraMaterial={em} />
            ))
          ) : (
            <></>
          )}
        </ExtraMaterialList>
      ) : (
        <strong>Esta aula não possui material de apoio</strong>
      )}
    </SupportMaterialsContainer>
  );
};

export default SupportMaterials;
