import React, { useEffect, useState } from 'react';
import { FAQContainer } from './style';
import { getFaqItems as getFaqItemsService } from '../../services/FAQ';
import FAQItemCard from './FAQItemCard';
import GoBack from '../../components/GoBack';
import Faq from '../../services/models/faq';

const FAQ: React.FC = () => {
  const [faqs, setFaqs] = useState<Faq[]>([]);

  useEffect(() => {
    (async () => {
      const faqItems = await getFaqItemsService();
      setFaqs(faqItems);
    })();
  }, []);

  return (
    <FAQContainer>
      <GoBack path="/home" />
      <h2 className="faq-title">FAQ</h2>

      <div className="faq-content">
        {faqs && faqs.length ? (
          faqs.map(faqItem => (
            <FAQItemCard key={faqItem.faq_id} faqItem={faqItem} />
          ))
        ) : (
          <>Não foram encontrados FAQ's</>
        )}
      </div>
    </FAQContainer>
  );
};

export default FAQ;
