import styled from 'styled-components';

const PlayScormContainer = styled.div`
  width: 820px;
  height: 495px;
  background-color: red;

  .iframe-scorm{
    width: 100%;
    height: 495px;;
  }
`;

export { PlayScormContainer };
