import styled from 'styled-components';

export const PageContainer = styled.main`
  margin: 100px auto 30px;
  width: 100vw;
`;

export const Content = styled.div`
  width: 85vw;
  margin: 0 auto;
`;

export const Frame = styled.div`
  width: 100%;

  & iframe {
    height: 680px;

    @media (max-width: 1200px) {
      height: 550px;
    }

    @media (max-width: 768px) {
      height: 400px;
    }

    @media (max-width: 550px) {
      height: 250px;
    }
  }
`;

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 300;
  line-height: 44px;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #333;
`;

export const Description = styled.p`
  margin-bottom: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 17px;
  line-height: 28px;
  font-weight: 300;
`;
