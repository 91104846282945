import React, { useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { Item, Alternative } from './style';
import Question from '../../../../services/models/exam-question';
import { answer } from '../../../../services/Exam';
import Swal from 'sweetalert2';
import getErrorMessage from '../../../../helpers/get-error-message';
import Answer from '../../../../services/models/exam-answer';

interface AlternativeProps {
  id: string;
  question: Question;
  respectiveAnswer: Answer;
  onAnswer: (questionId: string, alternativeId: string) => void;
}

const EvaluationItem: React.FC<AlternativeProps> = ({
  id,
  question,
  respectiveAnswer,
  onAnswer,
}) => {
  const [shouldShowContent, setShouldShowContent] = useState(false);
  const [answered, setAnswered] = useState<boolean>(false);
  const [choosenAlt, setChoosenAlt] = useState('');
  const questionNumber = parseInt(id, 10);

  const submitFunction = async (event: React.FormEvent) => {
    event.preventDefault();

    Swal.fire({
      text: 'Tem certeza que deseja responder esta questão com a alternativa selecionada?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(result => {
      if (result.isConfirmed) {
        try {
          answer(respectiveAnswer.examAnswerUserId, choosenAlt);
          onAnswer(question.id, choosenAlt);
          setAnswered(true);
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            title: 'Erro',
            icon: 'error',
            text: 'Erro ao responder questão. ' + errorMessage,
          });
        }
      }
    });
  };

  useEffect(() => {
    setAnswered(!!(respectiveAnswer && respectiveAnswer.alternativeId));
    setChoosenAlt((respectiveAnswer && respectiveAnswer.alternativeId) || '');
  }, [respectiveAnswer]);

  return (
    <Item>
      <div className="question-container">
        <h3
          className="question-title"
          onClick={() => {
            setShouldShowContent(!shouldShowContent);
          }}
        >
          Questão {questionNumber + 1}
          <BiChevronDown size={30} color="#0f4dbc" />
        </h3>
        {shouldShowContent && (
          <div
            className={`question-content-container ${
              !shouldShowContent ? 'closed' : ''
            }`}
          >
            <h3>{question.enunciated}</h3>
            <form className="question-content">
              {question.alternative &&
                question.alternative.map((alt, index) => (
                  <Alternative key={alt.id}>
                    <input
                      key={index}
                      type="radio"
                      name="answer"
                      required
                      disabled={answered}
                      value={alt.value}
                      onChange={e => setChoosenAlt(alt.id)}
                      checked={alt.id === choosenAlt}
                    />
                    <span>{alt.value}</span>
                  </Alternative>
                ))}
              <div className="submit-section">
                <button
                  type="submit"
                  onClick={submitFunction}
                  disabled={answered}
                >
                  Responder
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Item>
  );
};

export default EvaluationItem;
