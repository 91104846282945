import styled from 'styled-components';

const DefaultAlert = styled.div`
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;

  &.success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &.danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  &.warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
`;

export default DefaultAlert;
