import IContent from '../models/content';
import ICourse from '../models/course';
import httpClient from '../../http-client';
import convertToCourse from '../../helpers/converters/convert-course';
import { checkIfCourseContentsAreAvailable } from '../../helpers/check-contents-available';
import Course from '../models/course';
import CourseFromResponse from '../models/from-api-result/course';

const getCourse = async (courseId: string) => {
  const course = (await httpClient.get(`courses/${courseId}`)).data;

  let convertedCourse = {} as ICourse;
  if (course.start_at && course.start_at.length) {
    convertedCourse = convertToCourse(
      course,
      {
        start_at: course.start_at,
        finish_at: course.finish_at,
        course_id: course.course_id,
        exam: course.exam,
        course_content_user: course.course_content_user,
      },
      '',
      true,
    );

    if (convertedCourse && Object.keys(convertedCourse).length) {
      convertedCourse = checkIfCourseContentsAreAvailable(convertedCourse);
    }
  } else {
    if (course && Object.keys(course).length) {
      convertedCourse = convertToCourse(course, undefined, '', true);
    }
  }

  return convertedCourse;
};

const getProgressCourse = (contents: Array<IContent>) => {
  if (contents && contents.length) {
    const numberOfContents = contents.length;
    const numberOfFinishedContents = contents.filter(
      content => content.alreadyFinished,
    ).length;

    if (numberOfContents && numberOfFinishedContents) {
      return Math.floor((numberOfFinishedContents / numberOfContents) * 100);
    }
  }
  return 0;
};

const addToFavorites = (adding: boolean, courseId: string) => {
  if (adding === false) {
    httpClient.post(`/book-marks`, { course_id: courseId }).catch(() => {
      return false;
    });

    return true;
  } else {
    httpClient.delete(`/book-marks/${courseId}`).catch(() => {
      return true;
    });

    return false;
  }
};

const searchCourses = async (value: string, callback: (data: any) => void) => {
  if (!value || value === '') {
    return {};
  }

  const courseUsers = (await httpClient.get(`/course-users?search=${value}`))
    .data;
  const coursesResult = Array<any>();

  courseUsers.forEach((c: any) =>
    c.courses.forEach((course: any) =>
      coursesResult.push({
        name: course.title,
        description: course.description,
        link: `/curso/${c.trail_id}/${course.course_id}`,
      }),
    ),
  );

  callback(coursesResult);
};

const setValueRating = async (value: number, courseId: string) => {
  await httpClient.post(`/course-users/rating/${courseId}`, { rate: value });
};

const updateContentProgress = async (
  trailId: string,
  courseId: string,
  contentId: string,
  content_view: number | null,
) => {
  if (!trailId || !courseId || !contentId) {
    return {};
  }

  return (
    await httpClient.patch(
      `/course-content-users/${courseId}/content-view/${contentId}`,
      {
        content_view,
      },
    )
  ).data;
};

const getCourses = async (filters?: Object) => {
  const localCourses = (
    await httpClient.get<CourseFromResponse[]>(`/courses`, { params: filters })
  ).data;

  let convertedCourses = [] as Course[];
  if (localCourses && localCourses.length) {
    convertedCourses = localCourses.map(course => convertToCourse(course));
  }

  return convertedCourses;
};

const addCourseToList = async (courseId: string, addToList: boolean) => {
  if (addToList) {
    await httpClient.post('/book-marks/', { course_id: courseId });
  } else {
    await httpClient.delete(`/book-marks/${courseId}`);
  }
};

const finishCourse = async (disciplineId: string, courseId: string) => {
  await httpClient.put('/trail-course-users/finish', {
    trail_id: disciplineId,
    course_id: courseId,
  });
};

const rateCourse = async (
  trailId: string,
  numberOfStars: number,
  description?: string,
) => {
  await httpClient.post(`/trail-user-reviews/`, {
    trail_id: trailId,
    stars: numberOfStars,
    description: description || ' ',
  });
};

const getReviews = async (trailId: string) => {
  const reviews = (await httpClient.get(`/trail-user-reviews/${trailId}`)).data;

  return reviews;
};

export {
  getProgressCourse,
  addToFavorites,
  getCourse,
  searchCourses,
  setValueRating,
  updateContentProgress,
  getCourses,
  addCourseToList,
  finishCourse,
  rateCourse,
  getReviews,
};
