import styled from 'styled-components';

export const MenuContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;

    & > div {
      margin-bottom: 15px;

      a {
        padding: 5px;
        width: 100%;
      }
    }
  }
`;