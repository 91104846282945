import Content from '../../services/models/content';
import Course from '../../services/models/course';
import ContentFromResponse from '../../services/models/from-api-result/content';
import ContentUserFromResponse from '../../services/models/from-api-result/content-user';
import CourseFromResponse from '../../services/models/from-api-result/course';
import convertToContent from './convert-content';
import CourseUserFromResponse from '../../services/models/from-api-result/course-user';

const getContentUser = (
  content: ContentFromResponse,
  user?: CourseUserFromResponse,
): ContentUserFromResponse => {
  if (user && user?.course_content_user && user?.course_content_user.length) {
    return (
      user.course_content_user.find(
        (contentUser: any) => contentUser.content_id === content.content_id,
      ) || ({} as ContentUserFromResponse)
    );
  } else if (user && user.course_content_user) {
    return (
      user.course_content_user.find(
        (contentUser: any) => contentUser.content_id === content.content_id,
      ) || ({} as ContentUserFromResponse)
    );
  }

  return {} as ContentUserFromResponse;
};

const convertToCourse = (
  course: CourseFromResponse,
  user?: CourseUserFromResponse,
  disciplineId?: string,
  isFirstCourse?: boolean,
): Course => {
  return {
    ...course,
    id: course.course_id,
    disciplineId: !!disciplineId ? disciplineId : '',
    alreadyStarted: !!(user ? user.start_at : false),
    alreadyFinished: !!(user ? user.finish_at : false),
    contents:
      course.contents && course.contents.length
        ? course.contents
            .sort((contentA, contentB) => contentA.position - contentB.position)
            .map(content =>
              convertToContent(
                content,
                getContentUser(content, user),
                disciplineId,
                course.course_id,
                course.contents[0] === content,
              ),
            )
        : ([] as Content[]),
    isFirstCourse: !!isFirstCourse,
    exam: course.exam || (!!user ? user!.exam : undefined),
    addedToList: course.book_mark,
    userRate: +course.user_rating
  };
};

export default convertToCourse;
