import React, { useState, useEffect } from 'react';
import CertificateCard from '../../../../components/CertificateCard';
import CourseCard from '../../../../components/CourseCard';
import DisciplineCard from '../../../../components/DisciplineCard';
import Course from '../../../../services/models/course';
import Discipline from '../../../../services/models/discipline';
import Certificates from './components/Certificates';
import FavoriteCourses from './components/FavoriteCourses';
import FavoriteDisciplines from './components/FavoriteDisciplines';
import OngoingCourses from './components/OngoingCourses';
import OngoingDisciplines from './components/OngoingDisciplines';
import { MyProgressTabsContainer } from './style';

export const MyProgressTabs: React.FC = () => {
  const [actualTab, setActualTab] = useState('ongoing-disciplines');
  const [isEntering, setIsEntering] = useState(false);

  const changeToTab = (tabName: string) => {
    setActualTab(tabName);
    setIsEntering(true);

    setTimeout(() => {
      setIsEntering(false);
    }, 300);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has('tab')) {
      changeToTab(params.get('tab')!);
    }
  }, []);

  return (
    <MyProgressTabsContainer>
      <div className="my-progress-tabs-links">
        <button
          className={actualTab === 'ongoing-disciplines' ? 'active' : ''}
          onClick={() => {
            changeToTab('ongoing-disciplines');
          }}
        >
          Disciplinas em andamento
        </button>

        <button
          className={actualTab === 'ongoing-courses' ? 'active' : ''}
          onClick={() => {
            changeToTab('ongoing-courses');
          }}
        >
          Cursos em andamento
        </button>

        <button
          className={actualTab === 'favorite-disciplines' ? 'active' : ''}
          onClick={() => {
            changeToTab('favorite-disciplines');
          }}
        >
          Disciplinas Favoritadas
        </button>

        <button
          className={actualTab === 'favorite-courses' ? 'active' : ''}
          onClick={() => {
            changeToTab('favorite-courses');
          }}
        >
          Cursos Favoritados
        </button>

        <button
          className={actualTab === 'certificates' ? 'active' : ''}
          onClick={() => {
            changeToTab('certificates');
          }}
        >
          Certificados
        </button>
      </div>

      <div
        className={`
        my-progress-tabs-content
        ${isEntering ? 'entering' : ''}
      `}
      >
        <>
          {(() => {
            switch (actualTab) {
              case 'ongoing-disciplines':
                return <OngoingDisciplines />;

              case 'ongoing-courses':
                return <OngoingCourses />;

              case 'favorite-disciplines':
                return <FavoriteDisciplines />;

              case 'favorite-courses':
                return <FavoriteCourses />;

              case 'certificates':
                return <Certificates />;
            }
          })()}
        </>
      </div>
    </MyProgressTabsContainer>
  );
};

export default MyProgressTabs;
