import styled from 'styled-components';

export const RecommendedCoursesContainer = styled.div`
  margin: 40px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .recommended-courses-label {
    color: #c4c4c4;
    margin-bottom: 15px;
    font-weight: bold;
    letter-spacing: 3px;
  }

  .recommended-courses-title {
    color: #009ee0;
    font-size: 40px;

    &::after {
      content: '';
      display: block;
      border-bottom: 5px solid #009ee0;
      width: 60px;
      margin: 15px auto;
    }
  }

  .recommended-courses-cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .all-courses-container {
      margin-bottom: 15px;
      width: 100%;
      padding: 0 20px;

      a {
        display: flex;
        justify-content: space-between;
        align-items: center;

        *:not(:last-child) {
          margin-right: 5px;
        }
      }
    }

    .recommended-courses-cards {
      padding: 0 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: stretch;
      justify-items: flex-start;
      grid-gap: 15px;

      @media (max-width: 768px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
`;
