import React from 'react';
import { IndicatorsContainer } from './style';
import { ImBooks } from 'react-icons/im';
import { FaCertificate } from 'react-icons/fa';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Indicators: React.FC = () => {
  return (
    <IndicatorsContainer>
      <div className="ongoing-courses">
        <div className="ongoing-courses-icon">
          <ImBooks size={50} color="#2f9ec8" />
        </div>

        <h3 className="ongoing-courses-title">Cursos em andamento</h3>

        <span className="ongoing-courses-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          varius enim elementum tristique.
        </span>

        <Link to="/meus-cursos?tab=ongoing-disciplines" className="ongoing-courses-link">
          <span>Ver todos cursos em andamento</span>

          <BsArrowRight size={20} />
        </Link>
      </div>

      <div className="certificates">
        <div className="certificates-icon">
          <FaCertificate size={50} color="#2f9ec8" />
        </div>

        <h3 className="certificates-title">Certificados</h3>

        <span className="certificates-text">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          varius enim elementum tristique.
        </span>

        <Link to="/meus-cursos?tab=certificates" className="certificates-link">
          <span>Ver meus certificados</span>

          <BsArrowRight size={20} />
        </Link>
      </div>
    </IndicatorsContainer>
  );
};

export default Indicators;
