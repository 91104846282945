import React from 'react';
import { Container } from './style';

function Loading(){
  return (
    <Container>
      <div id="loading">
        <div className="loader"></div>
        <h4 style={{ margin: '10px' }}>Carregando...</h4>
      </div>
    </Container>
  ) 
}

export default Loading;
