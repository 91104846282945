import styled from 'styled-components';

const ContentTabsContainer = styled.div`
  margin: 20px 0;
`;

const Tab = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  color: #009ee0;
  font-size: var(--font-medium);
  padding: 10px;
  margin: 0 0 15px;

  .icon {
    margin-right: 10px;
  }

  .text {
    color: #aaa8a8;
    font-size: 18px;
  }

  &.active {
    border-bottom: solid 3px #009ee0;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Tabs = styled.div`
  margin-bottom: 10px;

  & > :not(:last-child) {
    margin-right: 5px;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const TabContent = styled.div``;

export { ContentTabsContainer, Tab, Tabs, TabContent };
