import React, { useEffect, useState } from 'react';
import { DisciplinesTitle } from '../../pages/Disciplines/style';
import { ContactContainer, ContactForm, NavContent } from './styles';
import { sendContact as sendContactService } from '../../services/Contact';
import getErrorMessage from '../../helpers/get-error-message';
import Swal from 'sweetalert2';
import VMasker from 'vanilla-masker';
import { useHistory } from 'react-router';

const Contact: React.FC = () => {
  const history = useHistory();
  const [name, setName] = useState('');
  const [mail, setMail] = useState('');
  const [phone, setPhone] = useState('');
  const [matter, setMatter] = useState('');
  const [message, setMessage] = useState('');

  const sendContact = async (event: React.FormEvent) => {
    event.preventDefault();

    const localName = name;
    const localMail = mail;
    const localMessage = `Assunto: ${matter}\n\nTelefone: ${phone}\n\n${message}`;

    try {
      await sendContactService({
        name: localName,
        email: localMail,
        text: localMessage,
      });
      Swal.fire({ icon: 'success', text: 'Contato enviado com sucesso!' }).then(
        () => history.push('/home'),
      );
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        text: `Houve um erro ao enviar o contato. ${errorMessage}`,
      });
    }
  };

  useEffect(() => {
    const phoneInputs = Array.from(document.getElementsByName('phone'));
    for (let input of phoneInputs) {
      VMasker(input).maskPattern('(99) 99999-9999');
    }
  }, []);

  return (
    <ContactContainer className="content">
      <NavContent>
        <DisciplinesTitle>CONTATO</DisciplinesTitle>
      </NavContent>

      <ContactForm onSubmit={sendContact}>
        <div>
          <label htmlFor="name" className="required">
            Seu nome
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={event => setName(event.target.value)}
            required
          />
        </div>

        <div>
          <label htmlFor="mail" className="required">
            E-mail
          </label>
          <input
            type="email"
            id="mail"
            value={mail}
            onChange={event => setMail(event.target.value)}
            required
          />
        </div>

        <div>
          <label htmlFor="phone">Telefone</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={phone}
            onChange={event => setPhone(event.target.value)}
          />
        </div>

        <div>
          <label htmlFor="matter" className="required">
            Assunto
          </label>
          <input
            type="text"
            id="matter"
            value={matter}
            onChange={event => setMatter(event.target.value)}
            required
          />
        </div>

        <div>
          <label htmlFor="message" className="required">
            Mensagem
          </label>
          <input
            type="text"
            id="message"
            value={message}
            onChange={event => setMessage(event.target.value)}
            required
          />
        </div>

        <button type="submit">Enviar</button>
      </ContactForm>
    </ContactContainer>
  );
};

export default Contact;
