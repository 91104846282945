import styled from 'styled-components';

export const FAQContainer = styled.div`
  width: 100vw;
  padding: 40px 80px;

  .faq-title {
    font-size: 40px;
    color: #099EE0;

    &::after {
      content: "";
      display: block;
      border-bottom: solid 5px #099EE0;
      margin: 20px 0;
    }
  }

  .faq-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    & > div {
      margin-bottom: 20px;
    }
  }
`;