import styled, { css } from 'styled-components';

interface ISearchInput {
  expanded: boolean;
}

export const Container = styled.div<ISearchInput>`
  ${({ expanded }) => css`
    position: relative;
    display: flex;
    width: fit-content;
    max-height: 50px;
    align-items: center;
    background-color: ${expanded ? 'white' : '#009ee0'};
    padding: 8px 16px;
    border: ${expanded ? `2px solid #009ee0` : '2px solid transparent'};
    border-radius: 16px;
    cursor: pointer;
    &:hover {
      background-color: ${expanded ? 'white' : '#009ee0'};
    }
    button {
      height: 16px;
      margin: 0;
      cursor: pointer;
      border: none;
      transition: 0.5s ease;
      background-color: transparent;
      border-radius: 100%;
      &:focus {
        outline: none;
      }
      svg {
        max-height: 16px;
        max-width: 16px;
        path {
          fill: ${expanded ? `#009ee0` : 'white'};
        }
        color: ${expanded ? `#009ee0` : 'white'};
      }
    }
    input {
      opacity: ${expanded ? '1' : '0'};
      width: ${expanded ? 'auto' : '0'};
      transition: 0.5s ease;
      border: none;
      font-size: var(--font-medium);
      cursor: text;
      background-color: transparent;
      color: #009ee0;
      text-indent: 4px;
      font-weight: 500;
      transition: 0.5s ease;
      &::placeholder {
        color: #009ee0;
      }
      &:focus {
        outline: none;
      }
    }
  `}
`;

export const SearchResults = styled.ul`
  z-index: 15;
  position: absolute;
  top: 40px;
  right: 0;
  max-height: 360px;
  min-height: 64px;
  width: 360px;
  padding: 16px 0;
  border-radius: 16px;
  background-color: ${'white'};
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: -2px 6px 30px -10px rgba(0, 0, 0, 0.75);
  .sectionTitle {
    margin: 8px 16px;
    font-weight: bold;
    color: #009ee0;
  }
  li.noResults {
    height: 64px;
    width: 360px;
    padding: 8px;
    color: #009ee0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`;

export const SearchResultItemWrapper = styled.li`
  height: 64px;
  width: 360px;
  padding: 8px 16px;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-column-gap: 24px;
  position: relative;
  color: #009ee0;
  transition: 0.5s ease;
  list-style: none;
  z-index: 1;
  &:before {
    background-color: #009ee0;
    left: 0;
    bottom: 0;
    content: '';
    width: 100%;
    height: 0;
    position: absolute;
    transition: 0.5s ease;
    z-index: -1;
  }
  &:hover {
    &:before {
      height: 64px;
    }
    color: white;
  }
  .data {
    .name {
      font-size: 15px;
      font-weight: bold;
    }
    .description {
      font-size: 14px;
    }
  }
`;
