import Exam from "../../services/models/exam";
import ExamFromResponse from "../../services/models/from-api-result/exam";
import convertToQuestion from "./convert-question";

const convertToExam = (exam: ExamFromResponse): Exam => {
  return {
    id: exam.exam_id,
    title: exam.title,
    instructions: exam.instructions,
    enabled: !!exam.enabled,
    questions:
      exam.questions && exam.questions.length
        ? exam.questions.map(convertToQuestion)
        : [],
    attempts: exam.attempts,
    average: exam.average || 0,
    limitTime: exam.limit_time
  };
};

export default convertToExam;
