import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './style';
import { IoArrowBackCircle } from 'react-icons/io5';

type GoBackProps = {
  path: string;
}

const GoBack: React.FC<GoBackProps> = ({ path }) => {
  return (
    <Container>
     <Link to={path}>
        <IoArrowBackCircle size={25} color="#009ee0"/>
        <span>Voltar</span>
      </Link>
    </Container>
  )
}

export default GoBack;
