import React, { useState } from 'react';
import { AnnotationCourseCardContainer } from './style';
import { AnnotationCourse } from '../..';
import { BsChevronDown } from 'react-icons/bs';
import AnnotationLessonCard from '../AnnotationLessonCard';

interface AnnotationCourseProps {
  annotationCourse: AnnotationCourse;
}

const AnnotationCourseCard: React.FC<AnnotationCourseProps> = ({
  annotationCourse,
}) => {
  const [shouldShowContent, setShouldShowContent] = useState(false);

  const toogleShouldShowContent = () => {
    setShouldShowContent(!shouldShowContent);
  };

  return (
    <AnnotationCourseCardContainer>
      <div
        className="annotation-course-title-container"
        onClick={toogleShouldShowContent}
      >
        <span
          className={`
          annotation-course-toogle-icon-container
          ${shouldShowContent ? 'arrow-up' : 'arrow-down'}
        `}
        >
          <BsChevronDown size={20} color="#FFF" />
        </span>

        <h3 className="annotation-course-title">
          {annotationCourse.course_title}
        </h3>
      </div>

      <div
        className={`
        annotation-course-content
        ${shouldShowContent ? 'show' : ''}
      `}
      >
        {annotationCourse.contents.map(annotationLesson => (
          <AnnotationLessonCard
            key={annotationLesson.content_id}
            annotationLesson={annotationLesson}
            courseId={annotationCourse.course_id}
          />
        ))}
      </div>
    </AnnotationCourseCardContainer>
  );
};

export default AnnotationCourseCard;
