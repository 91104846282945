import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  opacity: 0;

  .graphical-progress-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    margin-left: 15px;
    width: 180px;
    height: 10px;
    border: solid 1px #172983;
    border-radius: 7px;

    .graphical-progress {
      background-color: #172983;
      height: 100%;
    }
  }
`;
