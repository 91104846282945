import Content from '../services/models/content';
import Course from '../services/models/course';
import Discipline from '../services/models/discipline';

const checkIfDisciplineContentsAreAvailable = (
  discipline: Discipline,
): Discipline => {
  for (let course of discipline.courses) {
    for (let content of course.contents) {
      const previousContents = [...course.contents].splice(
        0,
        course.contents.indexOf(content),
      );
      if (previousContents && previousContents.length) {
        if (!content.alreadyFinished) {
          const thereAreAtLeastOnePendingPreviousContent =
            previousContents.some(content => !content.alreadyFinished);
          if (thereAreAtLeastOnePendingPreviousContent) {
            content.isLocked = true;
          } else {
            content.isLocked = false;
          }
        } else if (content.alreadyFinished) {
          content.isLocked = false;
        } else {
          content.isLocked = true;
        }
      } else {
        content.isLocked = false;
      }
    }
  }

  return discipline;
};

const checkIfCourseContentsAreAvailable = (course: Course): Course => {
  const allContents =
    course && course.contents.length
      ? course.contents.flat()
      : ([] as Content[]);

  for (let content of course.contents) {
    const previousContents = allContents.splice(
      0,
      allContents.indexOf(content),
    );
    if (previousContents && previousContents.length) {
      if (!content.alreadyFinished) {
        const thereAreAtLeastOnePendingPreviousContent = previousContents.some(
          content => !content.alreadyFinished,
        );
        if (thereAreAtLeastOnePendingPreviousContent) {
          content.isLocked = true;
        } else {
          content.isLocked = false;
        }
      } else if (content.alreadyFinished) {
        content.isLocked = false;
      } else {
        content.isLocked = true;
      }
    } else {
      content.isLocked = false;
    }
  }

  return course;
};

export {
  checkIfCourseContentsAreAvailable,
  checkIfDisciplineContentsAreAvailable,
};
