import React from 'react';
import { CardContainer } from './styles';
import { useHistory } from 'react-router-dom';
import { HiDownload } from 'react-icons/hi';
import { AiFillFileText } from 'react-icons/ai';

export interface Doc {
  content_id: string;
  thumbnail: string;
  title: string;
  reference: string;
  type: string;
  description: string;
}

export interface DocProps {
  doc: Doc;
  download?: boolean;
}

const CardDocs: React.FC<DocProps> = ({ doc, download }) => {
  const history = useHistory();

  const goToDiscipline = () => {
    history.push(`trilhas/${doc.content_id}`);
  };

  return (
    <CardContainer>
      <div className="card-image-container">
        {doc.thumbnail === null ? (
          <div className="card-thumb">
            <AiFillFileText size={100} color="#fff" />
          </div>
        ) : (
          <img src={doc.thumbnail} alt="disciplina" />
        )}
      </div>

      <div className="card-infos">
        <div className="card-support">
          <span className="card-category">{doc.type}</span>

          {download && (
            <a
              className="download"
              href={doc.reference}
              target="_blank"
              download
            >
              <span className="download-label">Download</span>
              <span>
                <HiDownload size={20} color="#1C3383" />
              </span>
            </a>
          )}
        </div>

        <h3 className="card-title">{doc.title}</h3>
        <p className="fifteen-chars">{doc.description}</p>
      </div>
    </CardContainer>
  );
};

export default CardDocs;
