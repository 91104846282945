import Course from '../../services/models/course';
import Discipline from '../../services/models/discipline';
import CourseFromResponse from '../../services/models/from-api-result/course';
import CourseUserFromResponse from '../../services/models/from-api-result/course-user';
import DisciplineFromResponse from '../../services/models/from-api-result/discipline';
import DisciplineUserFromResponse from '../../services/models/from-api-result/discipline-user';
import convertToCourse from './convert-course';

const getCourseUser = (
  course: CourseFromResponse,
  user: DisciplineUserFromResponse,
): CourseUserFromResponse => {
  if (user && user.trail_courses && user.trail_courses.length) {
    return (
      user.trail_courses.find((courseUser: any) => {
        if (courseUser) {
          return !!courseUser.courses
            ? courseUser.courses.course_id === course.course_id
            : courseUser.course_id === course.course_id;
        }

        return false;
      }) || ({} as CourseUserFromResponse)
    );
  }

  return {} as CourseUserFromResponse;
};

const convertToDiscipline = (
  discipline: DisciplineFromResponse,
  user?: DisciplineUserFromResponse,
): Discipline => {
  const sortedCourses =
    discipline?.courses && discipline?.courses.length >= 1
      ? discipline.courses
          .sort(
            (disciplineA, disciplineB) =>
              disciplineA.position - disciplineB.position,
          )
          .map((course, index) =>
            convertToCourse(
              course,
              getCourseUser(course, user || ({} as DisciplineUserFromResponse)),
              discipline.trail_id,
              index === 0,
            ),
          )
      : ([] as Course[]);

  return {
    ...discipline,
    id: discipline.trail_id,
    alreadyStarted: !!(user && user.start_at),
    alreadyFinished: !!(user && user.finish_at),
    courses: sortedCourses,
    addedToList: discipline.book_mark,
    referenceUrl: discipline.video_reference || undefined,
    previewData: discipline.video_preview
      ? {
          contentView: discipline.video_preview.content_view,
          started: !!discipline.video_preview.start_at,
          finished: !!discipline.video_preview.finish_at,
        }
      : { contentView: 0, started: false, finished: false },
   };
};

export default convertToDiscipline;
