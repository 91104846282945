import styled from 'styled-components';

export const MyProgressTabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @keyframes entering {
    0% {
      display: none;
      opacity: 0;
    }
    1% {
      display: block;
      opacity: 0;
    }
    100% {
      display: block;
      opacity: 1;
    }
  }

  .my-progress-tabs-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    & > button:not(:last-child) {
      margin-right: 10px;
    }

    & > button {
      cursor: pointer;
      padding: 5px 10px;
      border: none;
      outline: none;
      background: transparent;
      color: #aaa8a8;
      font-size: 18px;
      font-weight: bold;

      @media (max-width: 1200px) {
        width: 100%;
      }

      &::after {
        content: '';
        display: block;
        border-bottom: solid 4px #aaa8a8;
        margin: 10px 0;
      }

      &.active {
        color: #099ee0;

        &::after {
          border-color: #099ee0;
        }
      }
    }
  }

  .my-progress-tabs-content {
    &.entering {
      animation: entering 0.5s;
    }

    .empty-list {
      span {
        color: #099ee0;
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
`;
