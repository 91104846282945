import React from 'react';
import MyProgressTabs from './components/MyProgressTabs';
import { MyProgressContainer } from './style';

const MyProgress: React.FC = () => {

  return (
    <MyProgressContainer>
      <h2 className="my-progress-title">MEU PROGRESSO</h2>

      <div className="my-progress-content">
        <MyProgressTabs />
      </div>
    </MyProgressContainer>
  );
}

export default MyProgress;