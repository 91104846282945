import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  text-align: center;

  margin: 30px auto;

  span {
    font-size: 24px;
    color: #009ee0;
    font-weight: bold;
    cursor: pointer;

    :hover {
      text-decoration: underline;
      color: #143282;
    }
  }
`;
