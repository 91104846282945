import React from 'react';
import { BiDownload } from 'react-icons/bi';
import ExtraMaterials from '../../../../../../../../services/models/extra-material';
import { ExtraMaterialCardContainer, ExtraMaterialCardName, ExtraMaterialTagRequired } from './style';

interface ExtraMaterialCardProps {
  extraMaterial: ExtraMaterials;
}

const ExtraMaterialCard: React.FC<ExtraMaterialCardProps> = ({
  extraMaterial,
}) => {
  const downloadMaterial = () => {
    window.open(extraMaterial.reference, '_blank');
  };

  return (
    <ExtraMaterialCardContainer onClick={downloadMaterial}>
      <ExtraMaterialCardName>{extraMaterial.name}</ExtraMaterialCardName>
      {extraMaterial.info?.tags?.required && <ExtraMaterialTagRequired>Obrigatório</ExtraMaterialTagRequired>}
      <BiDownload size={24} />
    </ExtraMaterialCardContainer>
  );
};

export default ExtraMaterialCard;
