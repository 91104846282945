import styled from 'styled-components';

export const CardContainer = styled.div`
  width: calc(100% / 4 - 40px);
  min-height: 390px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 20px;
  padding-bottom: 10px;
  position: relative;
  text-decoration: none;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  transition: transform 250ms ease;

  .fifteen-chars {
    margin-top: 1.1em;
    max-width: 25ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media (min-width: 900px) and (max-width: 1300px) {
    .fifteen-chars {
      max-width: 10ch;
    }
  }

  @media (max-width: 992px) {
    width: calc(100% / 2 - 40px);
  }

  @media (max-width: 768px) {
    width: calc(100% - 50px);
    margin: 10px;
  }

  &:hover {
    transform: translateY(-5px);
  }

  .card-infos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px;
    width: 100%;
  }

  .card-image-container {
    height: 280px;
    width: 100%;
    position: relative;
    cursor: pointer;

    .card-thumb {
      width: 100%;
      height: 100%;
      background: var(--primary-blue);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card-support {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem 0;
  }

  .card-category {
    color: #009ee0;
    text-decoration: none;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .card-title {
    color: #333;
    font-size: 17px;
    text-align: center;
    width: 100%;
  }

  .download {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 5px;
    font-size: 12px;
    font-weight: 600;
    color: #333;
    background: #fff;
    cursor: pointer;
    text-decoration: none;
  }
`;
