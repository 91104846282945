import styled from 'styled-components';

export const Container = styled.div``;

export const List = styled.ul`
  width: 70vw;
  margin: 0 auto;
`;

export const SubmitEvaluation = styled.div`
  height: 50px;
  width: 100%;
  margin-bottom: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  > button {
    padding: 10px 38px;
    border-radius: 6px;
    border: 1px solid #00985f;
    font-weight: 900;
    text-align: center;
    cursor: pointer;
    background: transparent;
    color: #00985f;
    transition: background 250ms ease, color 250ms ease;

    &:disabled {
      pointer-events: none;
      border-color: darkgray;
      background-color: darkgray;
      color: white;
      cursor: not-allowed;
    }

    &:hover {
      background: #00985f;
      color: #fff;
    }
  }
`;
