import styled from 'styled-components';

const DisciplineContentContainer = styled.div``;

const ContentMediaAndTitle = styled.div``;

const PlayerContainer = styled.div`
  iframe {
    width: 820px;
    height: 460px;
  }
`;

const ContentTitle = styled.h3`
  font-size: 26px;
  margin-top: 20px;
`;

const CourseRateContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & svg {
    color: #FFC90E;
  }
`;

const CourseRateButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 1px;
`;

const TitleAndRateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PlayScormContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export {
  DisciplineContentContainer,
  ContentMediaAndTitle,
  PlayerContainer,
  ContentTitle,
  CourseRateButton,
  CourseRateContainer,
  TitleAndRateContainer,
  PlayScormContainer
};
