import React from 'react';
import { MenuItemContainer } from './style';
import { Link, useLocation } from 'react-router-dom';

interface MenuItemProps {
  to: string;
}

const MenuItem: React.FC<MenuItemProps> = ({ to, children }) => {
  const location = useLocation();

  return (
    <MenuItemContainer>
      <Link to={to} className={location.pathname.includes(to) ? 'active' : ''}>
        {children}
      </Link>
    </MenuItemContainer>
  );
};

export default MenuItem;
