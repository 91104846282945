import React, { useState } from 'react';
import { AnnotationLessonCardContainer } from './style';
import { BsChevronDown } from 'react-icons/bs';
import AnnotationCard from '../AnnotationCard';
import { Link } from 'react-router-dom';
import { AnnotationLesson } from '../..';
import Annotation from '../../../../services/models/annotations';

interface AnnotationLessonProps {
  annotationLesson: AnnotationLesson,
  courseId: string
}

const AnnotationLessonCard: React.FC<AnnotationLessonProps> = ({annotationLesson, courseId}) => {
  const [shouldShowContent, setShouldShowContent] = useState(false);
  const [annotationsToBeShown, setAnnotationsToBeShown] = useState(annotationLesson.annotations);

  const toogleShouldShowContent = () => {
    setShouldShowContent(!shouldShowContent);
  }

  const getLessonLink = () => {
    return `curso/${courseId}/${annotationLesson.content_id}`;
  }

  return (
    <AnnotationLessonCardContainer>
      <div 
        className="annotation-lesson-title-container"
        onClick={toogleShouldShowContent}  
      >
        <span className={`
          annotation-lesson-toogle-icon-container
          ${shouldShowContent ? 'arrow-up' : 'arrow-down'}
        `}>
          <BsChevronDown size={20} color="#099ee0" />
        </span>

        <h3 className="annotation-lesson-title">
          {annotationLesson.title}
        </h3>
      </div>

      <ul className={`
        annotation-lesson-content
        ${shouldShowContent ? 'show' : ''}
      `}>
        {
          annotationsToBeShown
            .map(
              (annotation, index) => (
                <li key={index}>
                  <AnnotationCard key={annotation.annotation_id} annotation={annotation} />
                </li>
              )
            ) 
        }
      </ul>

      <Link to={getLessonLink} className="annotation-lesson-link">
        Ir para a aula
      </Link>
    </AnnotationLessonCardContainer>
  );
}

export default AnnotationLessonCard;