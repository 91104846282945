import React from 'react';
import CertificateFromResponse from '../../services/models/from-api-result/certificate';
import {
  CertificateCardContainer,
  CertificateCardThumb,
  CertificateDownload,
  CertificateTitle,
} from './style';
import ThumbDefault from '../../assets/thumb-default.png';
import { downloadCertificate } from '../../services/Certificate';
import { BsDownload } from 'react-icons/bs';

interface CertificateCardProps {
  certificate: CertificateFromResponse;
}

const CertificateCard: React.FC<CertificateCardProps> = ({ certificate }) => {
  return (
    <CertificateCardContainer
      onClick={() => downloadCertificate(certificate.trail_id)}
    >
      <CertificateCardThumb src={certificate.trail_thumbnail || ThumbDefault} />
      <CertificateTitle>{certificate.trail_name}</CertificateTitle>
      <CertificateDownload>
        <span>Baixe o certificado</span>
        <BsDownload size={18} />
      </CertificateDownload>
    </CertificateCardContainer>
  );
};

export default CertificateCard;
