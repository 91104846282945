import React, { useState } from 'react';
import GoBack from '../../components/GoBack';
import CourseCard from '../../components/CourseCard';
import ICourse from '../../services/models/course';
import Search from '../../components/Search';
import Pagination from '../../components/Pagination';
import { getCourses as getCoursesService } from '../../services/Course';
import { CoursesContainer, CourseTitle, NavContainer } from './style';
import Course from '../../services/models/course';

const Courses: React.FC = () => {
  const [courses, setCourses] = useState<Course[]>([]);

  const getCourses = async (filters?: object) => {
    const localCourses = await getCoursesService({
      ...filters,
      is_active: true,
      is_single: true,
    });
    return localCourses;
  };

  const onCourseAddedToList = async (
    courseId: string,
    addedToList: boolean,
  ) => {
    const foundCourse = courses.find(c => c.id === courseId);
    if (foundCourse && foundCourse.id) {
      foundCourse.book_mark = addedToList;
    }

    setCourses([...courses]);
  };

  return (
    <CoursesContainer>
      <div className="box">
        <GoBack path="/home" />
        <NavContainer>
          <CourseTitle>TODOS CURSOS</CourseTitle>
          <Search typeSearch="courses" />
        </NavContainer>
        <ul className="courses-cards">
          {courses && courses.length > 0 ? (
            courses.map((course: ICourse) => (
              <CourseCard
                key={course.id}
                course={course}
                onAddToList={onCourseAddedToList}
              />
            ))
          ) : (
            <h4>Não foram encontrados cursos.</h4>
          )}
        </ul>
      </div>
      <Pagination
        getAllItems={filters => getCourses(filters)}
        setAvailableItems={setCourses}
        availableItems={courses}
      />
    </CoursesContainer>
  );
};

export default Courses;