import download from 'downloadjs';
import { generateCertificate } from '../../helpers/cert/generate';
import httpClient from '../../http-client';
import CertificateData from '../models/certificate-data';

const downloadCertificate = async (trailId: string) => {
  let certificateData = {} as CertificateData;

  try {
    certificateData = await getCertificateData(trailId);
  } catch (error) {
    if (
      error &&
      error.response &&
      error.response.data &&
      typeof error.response.data.message === 'string' &&
      error.response.data.message.includes('not found')
    ) {
      certificateData = await createCertificate(trailId);
    }
  }

  const certificate = await generateCertificate(certificateData);

  download(
    certificate,
    `certificado - ${certificateData.trail_name.replaceAll(/(\.)|(\/)/g, '_')}`,
  );
};

const getCertificateData = async (trailId: string) => {
  const certificateData = (
    await httpClient.get<CertificateData>(`/certificates/${trailId}`)
  ).data;

  return certificateData;
}

const createCertificate = async (trailId: string) => {
  const certificateData = (await httpClient.post(`/certificates/${trailId}`))
    .data;

  return certificateData;
};

export { downloadCertificate, createCertificate, getCertificateData };
