import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
  useContext,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Bookmark from '../../components/Bookmark';
import Content from '../../services/models/content';
import IDiscipline from '../../services/models/discipline';
import Course from '../../services/models/course';
import DisciplineContent from './components/DisciplineContent';
import DisciplineContext from './DisciplineContext';
import {
  ContentAndCoursesContainer,
  DisciplineContainer,
  DisciplineCourses,
  DisciplineTitle,
  ProgressAndBookmarkContainer,
  CourseProgressContainer,
  CourseProgress,
  CourseProgressBarContainer,
  CourseRateButton,
  CourseRateContainer,
} from './style';
import {
  getDiscipline as getDisciplineService,
  startDiscipline as startDisciplineService,
  finishDiscipline as finishDisciplineService,
  updateContentProgress as updateContentProgressService,
  startCourse as startCourseService,
  startContent as startContentService,
  finishContent as finishContentService,
  finishCourse as finishCourseService,
} from '../../services/Discipline/index';
import DisciplineCourse from './components/DisciplineCourse';
import {
  createCertificate as createCertificateService,
  getCertificateData as getCertificateDataService,
  downloadCertificate as downloadCertificateService,
} from '../../services/Certificate';
import {
  getCourse as getCourseService,
  getReviews,
} from '../../services/Course';
import getErrorMessage from '../../helpers/get-error-message';
import { AiOutlineDownload } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { showModal } from '../../components/Modal';
import CourseRate from './components/DisciplineContent/components/CourseRate';
import UserContext from '../../UserContext';

interface DisciplineParams {
  disciplineId: string;
  contentId: string;
  courseId: string;
}
export interface DisciplineExtendedWindow extends Window {
  actualDisciplineId: string;
  actualCourseId: string;
  actualContentId: string;
  actualExamId: string;
}

const Discipline: React.FC = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const { contentId, courseId, disciplineId } = useParams<DisciplineParams>();
  const history = useHistory();
  const [discipline, setDiscipline] = useState({} as IDiscipline);
  const [course, setCourse] = useState({} as Course);
  const [selectedContent, setSelectedContent] = useState({} as Content);
  const [selectedCourse, setSelectedCourse] = useState({} as Course);
  const [certificateAlreadyCreated, setCertificateAlreadyCreated] =
    useState(false);
  const [currentContent, setCurrentContent] = useState({} as Content);
  const [contentToFinish, setContentToFinish] = useState({} as Content);
  const isBlockFinishRef = useRef<boolean>(false);
  const isBlockStartRef = useRef<boolean>(false);
  const [showReviewButton, setShowReviewButton] = useState(true);
  const { user } = useContext(UserContext);

  const playerType = useMemo(() => {
    return pathname.split('/')[1];
  }, [pathname]);

  const courseList = useMemo(() => {
    if (!!course.id && playerType === 'curso') {
      return [course];
    }
    if (!discipline || !discipline.courses) {
      return [];
    }

    if (discipline.courses.length <= 0) {
      return [];
    }

    return discipline.courses.sort((a, b) =>
      a.position > b.position ? 1 : -1,
    );
  }, [discipline.courses, course]);

  const contentList = useMemo(() => {
    if (courseList.length <= 0) {
      return [];
    }

    return courseList.map(course => course.contents).flat();
  }, [courseList, course]);

  const redirectURLOnOpen = useCallback(() => {
    if (!contentList.length || !courseList.length) return;

    const firstNonWatchedContent =
      contentList.find(content => !content.alreadyFinished) || contentList[0];
    const selectedCourse =
      courseList.find(
        course => course.id === firstNonWatchedContent.courseId,
      ) || courseList[0];

    if (playerType === 'curso') {
      history.push(
        `/${playerType}/${firstNonWatchedContent.courseId}/${firstNonWatchedContent.id}`,
      );
    } else {
      history.push(
        `/${playerType}/${firstNonWatchedContent.disciplineId}/${firstNonWatchedContent.courseId}/${firstNonWatchedContent.id}`,
      );
    }
    setSelectedCourse(selectedCourse);
    setCurrentContent(firstNonWatchedContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentList.length]);

  const getcurrentContent = useCallback(() => {
    const foundCourse = courseList.find(course => course.id === courseId);
    const foundContent = contentList.find(
      content =>
        content.id === contentId &&
        content.courseId === courseId &&
        content.disciplineId === disciplineId,
    );

    if (foundContent && foundContent.id && foundCourse && foundCourse.id) {
      setCurrentContent(foundContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentId, courseId]);

  const nextCourse = useMemo(() => {
    if (!courseList || courseList.length <= 0) {
      return null;
    }
    const findNextCourse = courseList.find(
      (course: Course) => course.position > selectedCourse.position,
    );

    if (findNextCourse) {
      return findNextCourse;
    }

    return null;
  }, [courseList]);

  const goToNextCourse = useCallback(() => {
    if (nextCourse) {
      setSelectedCourse(nextCourse);
      setSelectedContent(nextCourse.contents[0]);
    }
  }, []);

  useEffect(() => {
    if (!!nextCourse && selectedCourse.alreadyFinished) {
      goToNextCourse();
    }
  }, [nextCourse, selectedCourse.alreadyFinished]);

  const goToNextContent = () => {
    const nextContent = getNextContent();
    const content = nextContent as Content;

    if (playerType === 'curso') {
      history.push(`/${playerType}/${content?.courseId}/${content?.id}`);
    } else {
      history.push(
        `/${playerType}/${content?.disciplineId}/${content?.courseId}/${content?.id}`,
      );
    }
  };

  const selectContent = useCallback(() => {
    if (contentId) {
      const findContent = contentList.find(
        content => content.id === contentId && content.courseId === courseId,
      );

      if (!findContent) {
        return;
      }
      const contentCourse = courseList.find(course =>
        course.contents.some(
          content =>
            content.id === findContent.id && content.courseId === courseId,
        ),
      );

      if (findContent && contentCourse) {
        setSelectedCourse({ ...contentCourse });
        setSelectedContent({ ...findContent });
      }
    }
  }, [discipline, course, courseId, contentId, courseList, contentList]);

  useEffect(() => {
    if (
      (discipline.id || course.id) &&
      contentList.length &&
      !selectedContent.id
    ) {
      selectContent();
    }
  }, [selectContent, discipline, course, selectedContent]);

  const fetchDisciplineData = useCallback(
    async (disciplineId: string) => {
      try {
        await getDisciplineService(disciplineId).then(response => {
          setDiscipline(response);

          if (!!response.user?.certificate) {
            setCertificateAlreadyCreated(true);
          }
        });
      } catch (err: any) {
        console.error(err.message);
      }
    },
    [disciplineId, certificateAlreadyCreated],
  );

  const fetchCourseData = useCallback(
    async (courseId: string) => {
      try {
        await getCourseService(courseId).then(response => {
          if (
            response.is_single &&
            !response.alreadyFinished &&
            response.contents.every(content => content.alreadyFinished)
          ) {
            finishCourse();
          }
          setCourse(response);
        });
      } catch (err: any) {
        console.error(err.message);
      }
    },
    [courseId],
  );
  const refreshData = useCallback(() => {
    if (!disciplineId && courseId) {
      fetchCourseData(courseId);
    } else {
      fetchDisciplineData(disciplineId);
    }
  }, [disciplineId, courseId]);

  useEffect(() => {
    redirectURLOnOpen();
  }, [redirectURLOnOpen]);

  useEffect(() => {
    if (!course.id || !discipline.id) {
      refreshData();
      return;
    }
  }, [refreshData]);

  useEffect(() => {
    getcurrentContent();
  }, [getcurrentContent]);

  useEffect(() => {
    isBlockFinishRef.current = false;
    isBlockStartRef.current = false;
    setContentToFinish({
      ...contentToFinish,
      id: '',
      alreadyFinished: false,
      alreadyStarted: false,
    });
  }, [courseId, contentId]);

  const finishTheDiscipline = async () => {
    const { courses } = discipline;
    if (
      !discipline.alreadyFinished &&
      courses.every(course => course.alreadyFinished)
    ) {
      try {
        discipline.alreadyFinished = true;
        await finishDisciplineService(disciplineId);
        createCertificate();
      } catch (error) {
        discipline.alreadyFinished = false;
      }
    }
  };

  const finishCourse = async () => {
    try {
      selectedCourse.alreadyFinished = true;
      await finishCourseService(courseId);
    } catch (error) {
      selectedCourse.alreadyFinished = false;
    }
  };

  const finishCourseAndDiscipline = async () => {
    if (selectedContent.alreadyFinished) {
      if (
        !selectedCourse.alreadyFinished &&
        selectedCourse.contents.every(content => content.alreadyFinished)
      ) {
        finishCourse();
      }

      if (
        !!disciplineId &&
        !discipline.alreadyFinished &&
        discipline?.courses.every(course => course.alreadyFinished)
      ) {
        finishTheDiscipline();
      }
      setDiscipline({ ...discipline });
    }
  };

  useEffect(() => {
    if ((discipline.id && contentList.length) || (!disciplineId && courseId)) {
      finishCourseAndDiscipline();
    }

    if (!selectedCourse.exam) {
      selectedCourse.approved = true;
      setDiscipline({ ...discipline });
    }
  }, [selectedContent.alreadyFinished]);

  const startContent = async () => {
    if (!discipline.alreadyStarted) {
      try {
        discipline.alreadyStarted = true;
        await startDisciplineService(disciplineId);
      } catch (error) {
        discipline.alreadyStarted = false;
      }
    }

    const localSelectedContent = selectedContent as Content;
    if (!selectedCourse.alreadyStarted) {
      try {
        selectedCourse.alreadyStarted = true;
        await startCourseService(courseId);
        setCurrentContent({ ...currentContent, alreadyStarted: true });
      } catch (error) {
        selectedCourse.alreadyStarted = false;
        console.error(error);
      }
    }

    if (!localSelectedContent.alreadyStarted) {
      try {
        localSelectedContent.alreadyStarted = true;
        await startContentService(
          localSelectedContent.courseId,
          localSelectedContent.id,
        );
        if (contentToFinish.id) {
          setContentToFinish({ ...contentToFinish, alreadyStarted: true });
        }
        setCurrentContent({ ...currentContent, alreadyStarted: true });
      } catch (error) {
        localSelectedContent.alreadyStarted = false;
        console.error(error);
      }
    }

    if (contentToFinish.id) {
      setContentToFinish({ ...contentToFinish, alreadyStarted: true });
    }
    setCurrentContent({ ...currentContent, alreadyStarted: true });

    if (playerType === 'disciplina') {
      setDiscipline({ ...discipline });
    } else {
      setCourse({ ...course });
    }
  };
  const finishContent = async () => {
    const localSelectedContent = selectedContent as Content;
    if (!localSelectedContent.alreadyFinished) {
      try {
        localSelectedContent.alreadyFinished = true;
        await finishContentService(
          localSelectedContent.courseId,
          localSelectedContent.id,
        );
        setContentToFinish({ ...currentContent, alreadyFinished: true });
        setCurrentContent({ ...currentContent, alreadyFinished: true });
      } catch (error) {
        localSelectedContent.alreadyFinished = false;
      }
    }
    if (selectedContent.alreadyFinished) {
      enableNextContent();
    }
    if (playerType === 'disciplina') {
      setDiscipline({ ...discipline });
    } else {
      setCourse({ ...course });
    }
  };

  const getNextContent = () => {
    const actualCourse =
      playerType === 'disciplina'
        ? discipline.courses.find(
            course => course.id === selectedContent.courseId,
          )
        : course;
    if (actualCourse) {
      let nextContent = actualCourse.contents.find(
        content => content.position > selectedContent.position,
      );
      if (nextContent) {
        return nextContent;
      } else if (playerType === 'disciplina') {
        const nextCourse = discipline.courses.find(
          course => course.position > actualCourse.position,
        );
        if (nextCourse) {
          const minContentPosition = Math.min(
            ...nextCourse.contents.map(content => content.position),
          );
          nextContent = nextCourse.contents.find(
            content => content.position === minContentPosition,
          );

          if (nextContent) {
            return nextContent;
          }
        }
      }
    }
    return {} as Content;
  };

  const enableNextContent = () => {
    let nextContent = undefined as Content | undefined;
    nextContent = getNextContent();
    nextContent!.isLocked = false;
  };

  const changeContent = () => {
    let localSelectedContentCourse = undefined as Course | undefined;
    if (playerType === 'disciplina') {
      localSelectedContentCourse = discipline.courses.find(
        course => course.id === courseId,
      );
    } else {
      localSelectedContentCourse = course;
    }
    if (
      localSelectedContentCourse &&
      localSelectedContentCourse.contents &&
      localSelectedContentCourse.contents.length
    ) {
      const localSelectedLesson = localSelectedContentCourse.contents.find(
        content => content.id === contentId,
      );
      if (localSelectedLesson) {
        setSelectedCourse(localSelectedContentCourse);
        setSelectedContent(localSelectedLesson);
      }
    }
  };

  const updateContentProgress = useCallback(
    async (time: number | null) => {
      await updateContentProgressService(courseId, contentId, time);
    },
    [disciplineId, courseId, contentId],
  );

  useEffect(() => {
    if (playerType === 'disciplina') {
      if (
        discipline &&
        discipline.id &&
        (selectedContent.courseId !== courseId ||
          selectedContent.id !== contentId)
      ) {
        changeContent();
      }
    } else {
      if (course && course.id && selectedContent.id !== contentId) {
        changeContent();
      }
    }
  }, [location.pathname, discipline, contentId]);

  const disciplineProgress = useMemo(() => {
    if (playerType === 'disciplina') {
      if (discipline && discipline.courses && discipline.courses.length) {
        const allContents = discipline.courses
          .map(course => course.contents || [])
          .flat();
        const completedContents = allContents.filter(
          content => content.alreadyFinished,
        );
        return (completedContents.length * 100) / allContents.length;
      }
    } else {
      if (course && course.contents && course.contents.length) {
        const allContents = course.contents || [];
        const completedContents = allContents.filter(
          content => content.alreadyFinished,
        );
        return (completedContents.length * 100) / allContents.length;
      }
    }
    return 0;
  }, [playerType, discipline, course]);

  const updateCourseApprove = (courseId: string, approved: boolean) => {
    const foundCourse = courseList.find(course => course.id === courseId);
    if (foundCourse) {
      foundCourse.approved = approved;
    }
    if (playerType === 'disciplina') {
      setDiscipline({ ...discipline });
    } else {
      setCourse({ ...course });
    }
  };

  const onAddedToList = (addedToList: boolean) => {
    discipline.addedToList = addedToList;
    setDiscipline({ ...discipline });
  };

  const createCertificate = async () => {
    try {
      const { courses } = discipline;

      if (courses.every(course => !course.exam) && !certificateAlreadyCreated) {
        await createCertificateService(disciplineId);
        setCertificateAlreadyCreated(true);
      }

      if (
        courses.every(course => !!course.exam && course.approved) &&
        !certificateAlreadyCreated
      ) {
        await createCertificateService(disciplineId);
        setCertificateAlreadyCreated(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const approvedOnAllCourses = useMemo(() => {
    const { user } = discipline;

    if (!!user && user.trail_courses.length) {
      return user.trail_courses.every(course => course.isApproved);
    }

    return false;
  }, [discipline]);

  useEffect(() => {
    if (
      disciplineId &&
      !certificateAlreadyCreated &&
      discipline.alreadyFinished &&
      approvedOnAllCourses
    ) {
      createCertificate();
    }
  }, [discipline, approvedOnAllCourses]);

  const CourseListResolver = useCallback(() => {
    if (!courseList || courseList.length <= 0) {
      return <></>;
    }

    return (
      <>
        {courseList.map((course: Course) => (
          <DisciplineCourse
            key={course.id}
            course={course}
            onCheckApprove={updateCourseApprove}
          />
        ))}
      </>
    );
  }, [courseList]);

  const downloadCertificate = async () => {
    try {
      await downloadCertificateService(discipline.id || '');
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: 'Erro',
        text: 'Erro ao baixar certificado. ' + errorMessage,
        icon: 'error',
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (discipline.id) {
        const reviewsResponse: any[] = await getReviews(discipline.id);
        if (!!reviewsResponse.find(r => r.user_name === user?.name)) {
          return setShowReviewButton(false);
        }
      }
    })();
  }, [discipline.id, showReviewButton, discipline]);

  const showCourseRateModal = () => {
    showModal(
      'Avaliar Curso',
      <CourseRate
        trailId={discipline.id!}
        onRate={stars => {
          course.userRate = stars;
        }}
        setShowReviewButton={setShowReviewButton}
      />,
    );
  };

  return (
    <DisciplineContext.Provider
      value={{
        trailId: discipline.id,
        content: selectedContent,
        course: selectedCourse,
        discipline: discipline,
        trailAlreadyFinished: discipline.alreadyFinished,
        updateContentProgress,
        goToNextContent,
        playerType,
        approvedOnAllCourses,
        certificateAlreadyCreated,
        startContent,
        finishContent,
      }}
    >
      <DisciplineContainer id="no-loading">
        {
          <div className="content">
            <DisciplineTitle>{discipline.name}</DisciplineTitle>

            <ProgressAndBookmarkContainer>
              <CourseProgressContainer>
                <div>
                  <strong>Progresso:</strong>
                  <strong>{(disciplineProgress || 0).toFixed(2)}%</strong>
                </div>
                <CourseProgressBarContainer>
                  <CourseProgress>
                    <span
                      style={{ width: `${disciplineProgress * 1.5}px` }}
                    ></span>
                  </CourseProgress>
                </CourseProgressBarContainer>
              </CourseProgressContainer>
              <Bookmark
                courseId={courseId}
                disciplineId={disciplineId}
                addedToList={discipline.addedToList || !!course.addedToList}
                onUpdate={onAddedToList}
              />
            </ProgressAndBookmarkContainer>
            <ContentAndCoursesContainer>
              <DisciplineContent />
              <DisciplineCourses>
                <CourseListResolver />
                {discipline.alreadyFinished && approvedOnAllCourses && (
                  <div className="get-certified-button-container">
                    <button
                      type="button"
                      className="get-certified-button"
                      onClick={downloadCertificate}
                    >
                      <span>Baixar Certificado</span>
                      <AiOutlineDownload color="#FFF" size={20} />
                    </button>
                  </div>
                )}

                {discipline.alreadyFinished && showReviewButton && (
                  <CourseRateContainer onClick={showCourseRateModal}>
                    <CourseRateButton>Avaliar disciplina</CourseRateButton>
                  </CourseRateContainer>
                )}
              </DisciplineCourses>
            </ContentAndCoursesContainer>
          </div>
        }
      </DisciplineContainer>
    </DisciplineContext.Provider>
  );
};

export default Discipline;
