import styled from 'styled-components';

export const RankingComponent = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  margin-bottom: 10px;
  padding: 10px 15px;
  border: 1px solid #e3e3e3;
  transition: all 350ms ease;
  cursor: pointer;

  &.blue-border {
    border: 2px solid #009ee0 !important;
  }
`;

export const Position = styled.span`
  color: #009ee0;
  font-size: 23px;
  font-weight: 700;
`;

export const ProfileAvatar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    margin-right: 10px;
  }
`;
