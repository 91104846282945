import httpClient from '../../http-client';

const sendContact = async (contactData: {
  name: string;
  email: string;
  text: string;
}) => {
  await httpClient.post(`faqs/mail`, contactData);
};

export { sendContact };
