import styled from 'styled-components';

export const AnnotationsContainer = styled.div`
  .new-annotation {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    *:not(:first-child) {
      margin-top: 10px;
    }
    input,
    textarea {
      width: 100%;
      padding: 10px;
      border-radius: 5px;
      outline: none;
      border: none;
      background: #f5f5f5;
    }
    textarea {
      height: 80px;
      resize: none;
    }
    button {
      outline: none;
      border: 1px solid #00985f;
      color: #000;
      background-color: transparent;
      padding: 10px;
      margin-left: auto;
      cursor: pointer;
      transition: background 250ms ease;
      &:hover {
        background: #00985f;
      }
    }
    span {
      font-weight: bold;
    }
  }
  .annotations {
    margin-top: 15px;
    .annotation-top-content-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      > h3 {
        color: var(--primary-blue);
      }
      
      .annotation-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        .action-button {
          padding: 5px 10px;
          background: #fff;
          border: 1px solid rgba(0, 0, 0, 0.15);
        }
        .edit-annotation {
          > span {
            color: #000;
          }
        }
        .remove-annotation {
          > span {
            color: #ff0000;
          }
        }
      }
    }
    
    .annotation {
      padding: 20px;
      color: #505F79;
      border: 1px solid rgba(0, 0, 0, 0.15);
      .annotation-content {
        margin-top: 15px;
      }
      &:not(:first-child) {
        margin-top: 10px;
      }
      button {
        border: none;
        outline: none;
        cursor: pointer;
        background-color: #F0F3F6;
        padding: 2px;
        border-radius: 2px;
        color: #97A0AF;
        &:not(:first-child) {
          margin-left: 5px;
        }
      }
    }
  }
`;
