import httpClient from '../../http-client';
import HomeInfoFromResponse from '../models/from-api-result/home-info';

const getHomeInfo = async () => {
  const homeInfo = (await httpClient.get<HomeInfoFromResponse[]>('/home-infos'))
    .data;

  return homeInfo;
};

const getAccessToken = () => {
  const url_string3 = window.location.hash;
  var findAccessToken = url_string3.search('access_token=');
  var findIdToken = url_string3.search('&id_token=');
  var findTokenType = url_string3.search('&token_type=');
  if (!findAccessToken && !findIdToken && !findTokenType) {
    return;
  }
  var accessTokenTotalLength = findAccessToken + 13;
  var idTokenTotalLength = findAccessToken + 13;
  var resultAccessToken = url_string3.substring(
    accessTokenTotalLength,
    findIdToken,
  );
  var resultIdToken = url_string3.substring(idTokenTotalLength, findTokenType);

  return [resultAccessToken, resultIdToken];
};

export { getHomeInfo, getAccessToken };
