import styled from 'styled-components';

export const AnnotationCourseCardContainer = styled.div`
  padding: 20px 5px 20px 20px;
  background: #099EE0;

  .annotation-course-title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    color: #333;

    & > *:first-child {
      margin-right: 15px;
    }

    .annotation-course-toogle-icon-container {
      transition: 0.3s;

      &.arrow-up {
        transform: rotate(180deg);
      }

      &.arrow-down {
        transform: rotate(0deg);
      }
    }
  }

  .annotation-course-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    
    transform: scaleY(0);
    height: 0;
    overflow: hidden;

    &.show {
      height: auto;
      transition: 0.3s;
      transform: scaleY(1);
      margin-top: 10px;
    }
  }
`;