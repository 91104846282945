import httpClient from "../../http-client";
const getFaqItems = async () => {
  const faqItems = (await httpClient.get('/faqs')).data;

  return faqItems;
}

export {
  getFaqItems
}
