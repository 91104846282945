import React, { useEffect, useState, useRef, FormEvent } from 'react';
import { useParams } from 'react-router-dom';
import {
  getAnswers,
  getQuestionById,
  sendAnswerReply,
  updateRating,
  sendAnswer,
} from '../../../services/Forum';
import { AiOutlineLike, AiOutlineDislike } from 'react-icons/ai';
import { GoReply } from 'react-icons/go';
import GoBack from '../../../components/GoBack';
import IAnswer from '../../../services/models/forum/answer';
import IQuestion from '../../../services/models/forum/question';
import Avatar from '../../../assets/avatar.png';
import {
  Container,
  Content,
  QuestionInfo,
  OrderNav,
  AnswersContainer,
  Answer,
  QuestionContent,
  ReplyContainer,
} from './style';

interface AnswerParams {
  questionId: string;
}

const formattedAnswerDate = (data: string) => {
  return new Intl.DateTimeFormat('pt-BR').format(new Date(data));
};

const AnswerPost = ({ answerData, getAnswers }: any) => {
  const { answer_id, comment, user, created_at, description, likes } =
    answerData || {};
  const replyRef = useRef<HTMLTextAreaElement>(null);
  const [reply, setReply] = useState(false);
  const [ratingData, setRatingData] = useState(likes);

  const sendComment = (e: FormEvent) => {
    e.preventDefault();
    const desc = replyRef.current?.value;

    if (desc)
      sendAnswerReply(answer_id, desc, () => {
        setReply(false);
        getAnswers();
        if (replyRef && replyRef.current) {
          replyRef.current.value = '';
        }
      });
  };

  const handleUpdateRating = async (rating: boolean, commentId?: string) => {
    await updateRating(commentId || answer_id, rating).then(
      ({ negative, positive }) => {
        if (!!positive) {
          setRatingData({
            ...ratingData,
            total_positive: +ratingData.total_positive + 1,
            total_negative:
              ratingData.user_review === false
                ? +ratingData.total_negative - 1
                : +ratingData.total_negative,
            user_review: true,
          });

          return;
        }

        setRatingData({
          ...ratingData,
          total_negative: +ratingData.total_negative + 1,
          total_positive:
            ratingData.user_review === true
              ? +ratingData.total_positive - 1
              : +ratingData.total_positive,
          user_review: false,
        });

        return;
      },
    );
  };

  return (
    <div className="answer-section">
      <Answer>
        <div className="user-content">
          <div className="avatar">
            <img src={user.avatar || Avatar} alt="avatar" />
          </div>
          <h3>{user.name}</h3>
          <span>{formattedAnswerDate(created_at)}</span>
        </div>
        <div className="answer-content">
          <p>{description}</p>
        </div>
        <div className="answer-interaction">
          <div className="action">
            <span>{ratingData.total_negative}</span>
            {ratingData.user_review === false ? (
              <AiOutlineDislike size={32} />
            ) : (
              <AiOutlineDislike
                size={32}
                onClick={() => handleUpdateRating(false)}
              />
            )}
          </div>
          <div className="action">
            <span>{ratingData.total_positive}</span>
            {ratingData.user_review === true ? (
              <AiOutlineLike size={32} />
            ) : (
              <AiOutlineLike
                size={32}
                onClick={() => handleUpdateRating(true)}
              />
            )}
          </div>
          <GoReply onClick={() => setReply(!reply)} />
        </div>
      </Answer>
      {reply && (
        <ReplyContainer>
          <form onSubmit={sendComment}>
            <label>Escreva sua resposta</label>
            <textarea ref={replyRef} />
            <button type="submit">Enviar</button>
          </form>
        </ReplyContainer>
      )}
      {comment &&
        comment.length > 0 &&
        comment.map((reference: any) => (
          <Answer className="reference">
            <div className="user-content">
              <div className="avatar">
                <img
                  src={(reference.user && reference.user.avatar) || Avatar}
                  alt="avatar"
                />
              </div>
              <h3>{reference.user.name}</h3>
              <span>{formattedAnswerDate(reference.created_at)}</span>
            </div>
            <div className="answer-content">
              <p>{reference.description}</p>
            </div>
          </Answer>
        ))}
    </div>
  );
};

const Answers: React.FC = () => {
  const replyRef = useRef<HTMLTextAreaElement>(null);
  const { questionId } = useParams() as AnswerParams;
  const [answers, setAnswers] = useState([] as IAnswer[]);
  const [question, setQuestion] = useState({} as IQuestion);

  useEffect(() => {
    getQuestionById(questionId, data => {
      setQuestion(data);

      getAnswers(questionId, data => {
        setAnswers(data);
      });
    });
  }, []);

  const updateAnswer = () => {
    getAnswers(questionId, data => {
      setAnswers(data);
    });
  };

  const sendAnswerQuestion = (event: FormEvent) => {
    event.preventDefault();

    if (replyRef.current?.value) {
      sendAnswer(question.question_id, replyRef.current.value, () => {
        getAnswers(questionId, data => {
          setAnswers(data);
          if (replyRef && replyRef.current) {
            replyRef.current.value = '';
          }
        });
      });
    }
  };

  const orderBy = (type: string) => {
    getAnswers(
      questionId,
      data => {
        setAnswers(data);
      },
      type === 'most_recent' ? 'most_recent=true' : 'most_liked=true',
    );
  };

  return (
    <Container>
      <Content>
        <GoBack path="/forum" />
        {question && question.question_id && (
          <QuestionContent>
            <QuestionInfo>
              {question.categories &&
                question.categories.length > 0 &&
                question.categories.map(categ => (
                  <div className="card-categorie">{categ.description}</div>
                ))}
            </QuestionInfo>
            <div className="question">
              <div className="question-data">
                <h1>{question.title}</h1>
                <span>{question.description}</span>
              </div>
              <div className="user-content">
                <img
                  src={(question.user && question.user.avatar) || Avatar}
                  alt="avatar"
                />
                <h3>{question.user.name}</h3>
                <span>{formattedAnswerDate(question.created_at)}</span>
              </div>
            </div>
          </QuestionContent>
        )}
        <OrderNav>
          <span>Ordenar por:</span>
          <button onClick={() => orderBy('most_recent')}>
            Respostas mais novas
          </button>
          <button onClick={() => orderBy('most_liked')}>
            Melhores Respostas
          </button>
        </OrderNav>
        <AnswersContainer>
          {answers && answers.length && answers.length > 0 ? (
            answers.map(data => (
              <AnswerPost getAnswers={updateAnswer} answerData={data} />
            ))
          ) : (
            <h1>Não há nenhuma resposta para essa pergunta!</h1>
          )}
        </AnswersContainer>
        <form onSubmit={sendAnswerQuestion} className="reply-question">
          <label>Enviar uma resposta:</label>
          <textarea required ref={replyRef}></textarea>
          <button type="submit">Enviar</button>
        </form>
      </Content>
    </Container>
  );
};

export default Answers;
