import styled from 'styled-components';

export const MyAnnotationsContainer = styled.div`
  width: 100vw;
  @media (min-width: 768px) {
    padding: 40px 80px;
  }

  display: flex;
  flex-direction: column;
  justify-content: center;

  .my-annotations-title {
    font-size: 40px;
    color: #099EE0;

    &:after {
      content: "";
      display: block;
      border-bottom: solid 5px #099EE0;
      margin: 20px 0;
    }
  }

  .my-annotations-content {
    & > li {
      list-style: none;
      margin: 15px;
    }
  }
`;