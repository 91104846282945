import styled from 'styled-components';

const DisciplinesContainer = styled.div`
  .content {
    padding: 10px 10px;
  }

  @media (min-width: 847px) {
    .content {
      padding: 45px 60px;
    }
  }
`;

const NavContent = styled.div`
  border-bottom: solid 5px #009ee0;
  padding-bottom: 20px;

  margin-bottom: 40px;

  @media (min-width: 847px) {
    display: flex;
    justify-content: space-between;
  }
`;

const DisciplinesTitle = styled.h2`
  color: #009ee0;

  font-size: var(--font-larger);
  font-weight: bold;
`;

const DisciplinesList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(428px, 1fr));
  grid-gap: 16px;
`;

export {
  DisciplinesContainer,
  DisciplinesList,
  DisciplinesTitle,
  NavContent,
};
