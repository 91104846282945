import styled from 'styled-components';

export const LibraryContainer = styled.div`
  width: 100vw;
  padding: 40px;
  
  .library-item-image-container {
    height: 250px;
    width: 100%;
    background-color: #1584c4;
  }

  .library-title {
    font-size: 40px;
    color: #009ee0;

    &::after {
      content: "";
      display: block;
      border-bottom: solid 5px #009ee0;
      margin: 20px 0;
    }
  }

  .library-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;

    & > div {
      width: 30%;
    }
  }
`;
