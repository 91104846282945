import React, {
  useCallback,
  useEffect,
  useState,
  FormEvent,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import ITag from '../../../services/models/forum/tag';
import GoBack from '../../../components/GoBack';
import MultipeSelect from '../../../components/MultipleSelect';
import {
  getTagsService,
  createNewQuestion,
} from '../../../services/Forum';
import { Container, Card, CardTitle, Button, FormFooter } from './style';
import DefaultAlert from '../../../components/DefaultAlert';
import { getForumCategories } from '../../../services/ForumCategories';

const categoryError = 'Informe ao menos uma categoria para a discussão.';
const titleError = 'Informe um título válido para a discussão.';
const tagsError = 'Informe ao menos uma tag para a discussão.';
const descriptionError = 'Informe uma descrição válida para a discussão.';

const schema = Yup.object().shape({
  title: Yup.string().trim().typeError(titleError).required(titleError),
  categories: Yup.array()
    .min(1, categoryError)
    .typeError(categoryError)
    .required(categoryError),
  tags: Yup.array().min(1, tagsError).typeError(tagsError).required(tagsError),
  description: Yup.string()
    .typeError(descriptionError)
    .required(descriptionError),
});

interface Option {
  value: string;
  label: string;
  id: string;
}

const NewQuestion: React.FC = () => {
  const history = useHistory();
  const title = useRef<HTMLInputElement>(null);
  const description = useRef<HTMLTextAreaElement>(null);

  const [optionsCategories, setOptionsCategories] = useState([] as Option[]);
  const [optionsTags, setOptionsTags] = useState([] as Option[]);

  const [selectedCategories, setSelectedCategories] = useState<Option[]>();
  const [selectedTags, setSelectedTags] = useState<Option[]>();

  const [errors, setErrors] = useState([] as string[]);

  const getCategories = async () => {
    const categories = await getForumCategories();
    setOptionsCategories(
      categories.map(categ => ({
        value: categ.id,
        label: categ.description,
        id: categ.id,
      })),
    );
  };

  const getTags = () => {
    getTagsService(data => {
      getOptionsTags(data);
    });
  };

  const getOptionsTags = useCallback((listTags: ITag[]) => {
    if (listTags && listTags.length > 0) {
      const list = Array<Option>();
      listTags.map(tag => {
        if (tag.verified) {
          list.push({
            value: tag.tag_id,
            label: tag.description,
            id: tag.tag_id,
          });
        }
      });

      setOptionsTags(list);
    }
  }, []);

  useEffect(() => {
    getCategories();
    getTags();
  }, []);

  const submitQuestion = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      const data = {
        title: title.current?.value,
        categories: selectedCategories
          ? selectedCategories.map(opt => {
              return { category_id: opt.value };
            })
          : Array<Option>(),
        tags: selectedTags
          ? selectedTags.map(opt => {
              return { tag_id: opt.id };
            })
          : Array<Option>(),
        description: description.current?.value,
      };

      try {
        await schema.validate(data, { abortEarly: false });

        createNewQuestion(data, () => {
          history.push('/forum');
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          setErrors(error.errors || []);
        }
      }
    },
    [selectedTags, selectedCategories],
  );

  const handleChangeOption = (e: any, type: string) => {
    if (type === 'categories') setSelectedCategories(e);
    else if (type === 'tags') setSelectedTags(e);
  };

  return (
    <>
      <Container>
        <div className="content">
          <GoBack path="/forum" />
          <Card>
            <CardTitle>Nova discussão</CardTitle>

            {errors && errors.length ? (
              <DefaultAlert className="danger">
                <ul>
                  {errors.map((error, index) => (
                    <li key={index}>- {error}</li>
                  ))}
                </ul>
              </DefaultAlert>
            ) : (
              <></>
            )}

            <form onSubmit={submitQuestion}>
              <div className="form-group">
                <label>Titúlo</label>
                <input ref={title} name="title" type="text" />
              </div>
              <div className="form-group">
                <label>Categorias</label>
                <MultipeSelect
                  value={selectedCategories || Array<Option>()}
                  onChange={e => handleChangeOption(e, 'categories')}
                  options={optionsCategories}
                />
              </div>
              <div className="form-column">
                <div className="form-group">
                  <label>Tags</label>
                  <MultipeSelect
                    options={optionsTags}
                    value={selectedTags || Array<Option>()}
                    onChange={e => handleChangeOption(e, 'tags')}
                  />
                </div>
              </div>
              <div className="form-group textarea">
                <label>Descrição</label>
                <textarea
                  ref={description}
                  style={{ resize: 'none' }}
                  name="description"
                />
              </div>
              <FormFooter>
                <Button type="submit">Salvar</Button>
              </FormFooter>
            </form>
          </Card>
        </div>
      </Container>
    </>
  );
};

export default NewQuestion;
