import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { BsSearch } from 'react-icons/bs';
import { VscChromeClose } from 'react-icons/vsc';
import useDebounce from '../../hooks/useDebouce';
import { searchCourses } from '../../services/Course';
import { searchDisciplines } from '../../services/Discipline';
import { searchContents } from '../../services/Library';

import { Container, SearchResults, SearchResultItemWrapper } from './style';

interface SearchItemProps {
  data: Data;
}

interface Data {
  name: string;
  description: string;
  link: string;
}

interface SearchProps {
  typeSearch: string;
  callback?: boolean;
  updateList?: (data: any) => void;
  clear?: () => void;
}

const SearchResultItem: React.FC<SearchItemProps> = ({ data }) => {
  const history = useHistory();

  const goToCourse = () => {
    history.push(data.link);
  };

  return (
    <SearchResultItemWrapper onClick={() => goToCourse()}>
      <div className="data">
        <p className="name">{data.name}</p>
        <p className="description">{data.description}</p>
      </div>
    </SearchResultItemWrapper>
  );
};

const SearchComponent: React.FC<SearchProps> = ({
  typeSearch,
  callback,
  updateList,
  clear,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [searchParam, setSearchParam] = useState<string>('');
  const [searchResults, setSearchResults] = useState<Data[]>([]);
  let debouncedSearchParam = useDebounce(searchParam);
  const [isSearching, setSearching] = useState<boolean>(false);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParam(e.target.value);
  };

  const searchRequest = useCallback(async () => {
    if (debouncedSearchParam !== '') {
      setSearching(true);

      switch (typeSearch) {
        case 'courses':
          await searchCourses(debouncedSearchParam, data => {
            setSearchResults(data);
          });
          break;
        case 'disciplines':
          await searchDisciplines(debouncedSearchParam, data => {
            setSearchResults(data);
          });
          break;
        case 'content-video':
          await searchContents(debouncedSearchParam, 'VIDEO', data => {
            updateList && updateList(data);
          });
          break;
        case 'content-file':
          await searchContents(debouncedSearchParam, 'FILE', data => {
            updateList && updateList(data);
          });
          break;
      }
    }

    setSearching(false);
  }, [debouncedSearchParam]);

  useEffect(() => {
    if (debouncedSearchParam !== '') {
      searchRequest();
    }
  }, [debouncedSearchParam]);

  const toggleInput = (verifyState: boolean, clearInput?: boolean) => {
    if (verifyState) {
      !expanded && setExpanded(!expanded);
    } else {
      setExpanded(!expanded);
    }

    if (callback && expanded && clearInput) {
      clear && clear();
    }
  };

  useEffect(() => {
    if (!expanded) {
      setSearchParam('');
      setSearchResults([]);
      setSearching(false);
    }
  }, [expanded]);

  return (
    <Container onClick={() => toggleInput(true)} expanded={expanded}>
      <input
        type="text"
        value={searchParam}
        onChange={handleChangeSearch}
        placeholder="Buscar"
        required={true}
      />
      <button>
        {!expanded ? (
          <BsSearch />
        ) : (
          <VscChromeClose onClick={() => toggleInput(false, true)} />
        )}
      </button>
      {expanded && debouncedSearchParam !== '' && !callback && (
        <SearchResults id="no-loading">
          <>
            {searchResults.length >= 1 ? (
              <>
                {searchResults.map((result: any) => (
                  <SearchResultItem data={result} />
                ))}
              </>
            ) : !isSearching && searchResults.length === 0 ? (
              <li className="noResults">
                Nenhum resultado para a busca "{searchParam}"
              </li>
            ) : (
              <li className="noResults">Buscando por "{searchParam}"</li>
            )}
          </>
        </SearchResults>
      )}
    </Container>
  );
};

export default SearchComponent;
