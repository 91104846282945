import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import Swal from 'sweetalert2';
import getErrorMessage from '../../../../helpers/get-error-message';
import { setValueRating } from '../../../../services/Course';
import Content from '../../../../services/models/content';
import PlayerSambaVideos from '../../../../components/PlayerSambaVideos';
import DisciplineContext from '../../DisciplineContext';
import ContentTabs from './components/ContentTabs';
import {
  ContentMediaAndTitle,
  ContentTitle,
  CourseRateButton,
  CourseRateContainer,
  DisciplineContentContainer,
  PlayerContainer,
  TitleAndRateContainer,
} from './style';
import { DisciplineExtendedWindow } from '../..';
import PlayScorm from './components/PlayScorm';
import { useParams } from 'react-router';

declare let window: DisciplineExtendedWindow;

const CourseRate: React.FC<{ courseId: string; userRate: number }> = ({
  courseId,
  userRate,
}) => {
  const [initialRate, setInitialRate] = useState(userRate || 1);
  const [actualRate, setActualRate] = useState(initialRate);

  const starsMaxQuantity = 5;

  const changeStarQuantity = (newQuantity: number) => {
    setActualRate(newQuantity);
  };

  const updateStarsQuantity = async () => {
    try {
      setValueRating(actualRate, courseId);
      setInitialRate(actualRate);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        text: 'Erro ao avaliar curso! ' + errorMessage,
      });
    }
  };

  return (
    <CourseRateContainer>
      {Array(starsMaxQuantity)
        .fill('')
        .map((s, index) => (
          <CourseRateButton
            key={index}
            onMouseOver={() => changeStarQuantity(index + 1)}
            onMouseOut={() => changeStarQuantity(initialRate)}
            onClick={updateStarsQuantity}
          >
            {index + 1 <= actualRate ? (
              <AiFillStar size={22} />
            ) : (
              <AiOutlineStar size={22} />
            )}
          </CourseRateButton>
        ))}
    </CourseRateContainer>
  );
};

export interface PlayerEventListener {
  event: string;
  eventParam?: any;
  duration?: any;
}

interface DisciplineParams {
  disciplineId: string;
  courseId: string;
  contentId: string;
}

const PROGRESS_SAVE_RATE = 0; //in seconds

const DisciplineContent: React.FC = () => {
  const {
    content,
    playerType,
    course,
    startContent,
    finishContent,
    discipline,
    updateContentProgress,
    goToNextContent,
  } = useContext(DisciplineContext);
  const { disciplineId, courseId, contentId } = useParams<DisciplineParams>();

  const [showPlayer, setShowPlayer] = useState(true);

  const [hasFinishedVideo, setFinishedVideo] = useState<boolean>(false);
  const [lastWatchedTime, setLastWatchedTime] =
    useState<number>(PROGRESS_SAVE_RATE);
  const [progress, setProgress] = useState<number>(0);
  const [previousSelectedContent, setPreviousSelectedContent] = useState(
    {} as Content,
  );
  const [contentReference, setContentReference] = useState<string | undefined>(
    '',
  );

  const updateWatchTime = useCallback(
    (player: PlayerEventListener) => {
      const { event, eventParam } = player;

      if (event === 'onProgress') {
        setProgress(eventParam);
      }
      return null;
    },
    [lastWatchedTime],
  );

  useEffect(() => {
    setContentReference('');

    setTimeout(() => {
      if (content?.reference && content?.reference !== contentReference) {
        setContentReference('overview');
        setContentReference(content.reference);
      } else {
        setContentReference(discipline?.referenceUrl);
      }
    }, 100);
  }, [content?.reference, discipline?.referenceUrl]);

  useEffect(() => {
    if (progress >= lastWatchedTime + 60) {
      setLastWatchedTime(progress);
      updateContentProgress(progress);
      return;
    }
    if (progress < lastWatchedTime) {
      if (progress > 1) {
        setLastWatchedTime(progress);
        updateContentProgress(progress);
        return;
      }
      setLastWatchedTime(0);
      updateContentProgress(null);
    }
  }, [progress]);

  const getEventListeners = useCallback(
    async (player: PlayerEventListener) => {
      if (
        playerType === 'discipline'
          ? window.location.href.endsWith(
              `/${content?.disciplineId}/${content?.courseId}/${content?.id}`,
            )
          : window.location.href.endsWith(
              `/${content?.courseId}/${content?.id}`,
            )
      ) {
        if (player.event === 'onProgress') {
          if (content?.id && !content?.alreadyFinished) {
            updateWatchTime(player);
          }
          return;
        }
        if (content && content.id) {
          switch (player.event) {
            case 'onStart':
              await startContent();
              break;

            case 'onFinish':
              await finishContent();

              if (!!course?.is_single) {
                const { contents } = course;
                const isLastContent =
                  contents[contents.length - 1].id === contentId;

                !isLastContent && goToNextContent();
              }

              if (!!discipline) {
                const { courses } = discipline;

                const { contents } = courses[courses.length - 1];
                const isLastContent =
                  contents[contents.length - 1].id === contentId;

                !isLastContent && goToNextContent();
              }
              break;
            default:
              break;
          }
        }
      }
    },
    [content],
  );

  useEffect(() => {
    if (
      content &&
      (previousSelectedContent.disciplineId !== content.disciplineId ||
        previousSelectedContent.courseId !== content.courseId ||
        previousSelectedContent.id !== content.id)
    ) {
      setShowPlayer(false);
      setPreviousSelectedContent({ ...content });

      process.nextTick(() => {
        setShowPlayer(true);
      });
    }
  }, [content]);

  useEffect(() => {
    setFinishedVideo(false);
  }, [content]);

  const getControlsToEnable = () => {
    const controls = [
      'play',
      'pause',
      'quality',
      'fullscreen',
      'time',
      'volume',
      'backward',
      'speed'
    ];

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      controls.push('seekbar');
    }

    if (content && content.alreadyFinished) {
      controls.push('forward', 'seekbar');
    }

    return controls;
  };

  return (
    <DisciplineContentContainer>
      {content && content.reference ? (
        <>
          <ContentMediaAndTitle>
            <PlayerContainer>
              {contentReference &&
              contentReference.includes(
                process.env.REACT_APP_BASE_REFERENCE_URL!,
              ) ? (
                <PlayerSambaVideos
                  contentReference={content.reference}
                  controlsToEnable={getControlsToEnable()}
                  getEventListeners={getEventListeners}
                  resume={content.content_view || 0}
                />
              ) : (
                <PlayScorm contentReference={content.reference} />
              )}
            </PlayerContainer>

            <TitleAndRateContainer>
              <ContentTitle>{content.name}</ContentTitle>

              {playerType === 'curso' && course!.alreadyStarted && (
                <CourseRate
                  courseId={course!.id}
                  userRate={course?.userRate!}
                />
              )}
            </TitleAndRateContainer>
          </ContentMediaAndTitle>

          <ContentTabs
            contentDescription={content.description}
            contentId={content.id}
          />
        </>
      ) : (
        <></>
      )}
    </DisciplineContentContainer>
  );
};

export default DisciplineContent;
