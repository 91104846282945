import React from 'react';
import { LibraryItemCardContainer } from './style';
import { Link } from 'react-router-dom';
import { AiFillPlayCircle } from 'react-icons/ai';
import { FaBookOpen } from 'react-icons/fa';

export enum LibraryItemType {
  video = 1,
  text = 2,
  pdf = 3
}

export interface LibraryItem {
  id: number,
  title: string,
  type: LibraryItemType
}

interface LibraryItemCardProps {
  libraryItem: LibraryItem
}

const LibraryItemCard: React.FC<LibraryItemCardProps> = ({libraryItem}) => {
  return (
    <LibraryItemCardContainer>
      <Link to={`/biblioteca${libraryItem.type === 1 ? `-videos` : `-materiais-apoio`}`}>
        <div 
          className="library-item-image-container"
        >
          <div className="library-item-icon-container">
            {
              (() => {
                if (libraryItem.type === LibraryItemType.video) {
                  return <AiFillPlayCircle size={50} />
                } else if (libraryItem.type === LibraryItemType.text) {
                  return <FaBookOpen size={50} />
                } 
              })()
            }
          </div>
        </div>

        <h3 className="library-item-title">
          {libraryItem.title}
        </h3>
      </Link>
    </LibraryItemCardContainer>
  );
}

export default LibraryItemCard;
