import styled from 'styled-components';

const DisciplineCourseContainer = styled.div`
  width: 100%;
`;

const CourseTitleContainer = styled.div`
  cursor: pointer;
  padding: 15px 10px;
  font-size: var(--font-medium);
  background: #f8f8f8;
  border-bottom: solid 1px #009ee0;

  @keyframes turn-up {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  @keyframes turn-down {
    from {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  .icon {
    margin-right: 15px;

    &.closed {
      transform: rotate(0deg);
      animation: turn-down 0.3s;
    }

    &.opened {
      transform: rotate(180deg);
      animation: turn-up 0.3s;
    }
  }

  .title {
    color: #00a9e5;
    font-weight: bold;
    font-size: 18px;
    flex-grow: 1;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CourseContents = styled.div`
  & .get-certified-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  & .get-certified-button {
    cursor: pointer;
    outline: none;
    border: none;
    background: var(--primary-blue);
    border-radius: 5px;
    color: white;
    font-size: 16px;
    padding: 5px 10px;
    margin: 5px;
    opacity: 0.8;
    transition: all 0.3s;

    & * {
      color: white;
    }

    & > *:not(:last-child) {
      margin-right: 5px;
    }

    &:hover {
      opacity: 1;
    }

    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

const AvailableExamWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 8px;
  background-color: #00985f;
  color: white;
  font-size: 0.7rem;
  font-weight: bold;

  &.reproved {
    background-color: #dc3545;
  }

  p {
    color: white;
  }

  a,
  button {
    color: white;
    text-decoration: none;
    font-weight: bold;
    border: 1px solid white;
    transition: 0.5s ease;
    padding: 8px;
    text-align: center;
    outline: none;
    cursor: pointer;
    background: transparent;
    &:hover {
      color: #00985f;
      background-color: white;
    }
  }
`;

export {
  DisciplineCourseContainer,
  CourseTitleContainer,
  CourseContents,
  AvailableExamWrapper,
};
