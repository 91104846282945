import styled from 'styled-components';

export const CourseCardContainer = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 10px 10px 20px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 100%;

  &:hover {
    transform: translateY(-5px);
    transition: 0.5s;
  }

  > a {
    text-decoration: none;
    width: 100%;
  }

  .course-image-container {
    cursor: pointer;
    position: relative;
    width: 100%;

    img {
      object-fit: cover;
      width: 100%;
      min-height: 284px;
    }

    .course-duration {
      position: absolute;
      color: white;
      font-weight: bold;
      bottom: 8px;
      right: 15px;

      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;
    }
  }

  .lower-content {
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding: 20px 15px;

    .course-classification-and-added-to-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 5px;

      .course-classification {
        & > span {
          padding: 0 1px;
        }
      }

      .add-to-list {
        outline: none;
        border: none;
        cursor: pointer;
        background: transparent;
      }
    }

    .course-category {
      color: #009ee0;
      font-size: 14px;
      text-decoration: underline;
      margin-bottom: 10px;
    }

    .course-title {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 10px;
    }

    .course-discipline {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
`;
