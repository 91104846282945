import httpClient from '../../http-client';
import { toast } from 'react-toastify';
import ForumQuestionFromResponse from '../models/from-api-result/forum-question';

export const formatDate = (date: string) => {
  const convertedDate = new Date(date);
  const monthString = convertedDate.toLocaleString('pt-BR', {
    month: 'short',
  });
  const formatted = monthString[0].toUpperCase() + monthString.substr(1);

  return `${formatted} ${convertedDate.getFullYear()}`;
};

export const getQuestions = async (
  categoryId?: string,
  filters?: object,
): Promise<ForumQuestionFromResponse[]> => {
  let data = [];

  const conditionalFilters: any = {};
  if (categoryId) {
    conditionalFilters.category_id = [categoryId];
  }

  data = (
    await httpClient.get(`/forum/questions`, {
      params: { ...filters, ...conditionalFilters },
    })
  ).data;

  return data;
};

export const getQuestionById = async (
  questionId: string,
  callback: (data: any) => void,
) => {
  await httpClient
    .get(`/forum/questions/${questionId}`)
    .then((response: any) => callback(response.data));
};

export const getAnswers = async (
  questionId: string,
  callback: (data: any) => void,
  orderByRef?: string,
) => {
  if (orderByRef) {
    await httpClient
      .get(`/forum/answers?question_id=${questionId}?${orderByRef}`)
      .then((response: any) => callback(response.data));
  } else {
    await httpClient
      .get(`/forum/answers?question_id=${questionId}`)
      .then((response: any) => callback(response.data));
  }
};

export const sendAnswer = async (
  questionId: string,
  desc: string,
  callback: () => void,
) => {
  const data = {
    question_id: questionId,
    description: desc,
  };

  await httpClient.post('/forum/answers', data).then(() => {
    toast.success('Resposta enviada com sucesso!');
    callback();
  });
};

export const sendAnswerReply = async (
  answerId: string,
  desc: string,
  callback: () => void,
) => {
  const data = {
    answer_reference_id: answerId,
    description: desc,
  };

  await httpClient.post('/forum/answers', data).then(() => {
    toast.success('Resposta enviada com sucesso!');
    callback();
  });
};

export const updateRating = async (answer_id: string, rating: boolean) => {
  let { data } = await httpClient.put(`/forum/answer-ratings/${answer_id}`, {
    rating,
  });
  return data;
};

export const getCategoriesService = async (callback: (data: any) => void) => {
  await httpClient.get('/forum/categories').then(response => {
    callback(response.data);
  });
};

export const getTagsService = async (callback: (data: any) => void) => {
  await httpClient.get('/tags').then(response => {
    callback(response.data);
  });
};

export const createNewQuestion = async (
  dataQuestion: any,
  callback: () => void,
) => {
  let data = {};
  if (!dataQuestion.new_tags) {
    data = {
      title: dataQuestion.title,
      description: dataQuestion.description,
      categories: dataQuestion.categories,
      tags: dataQuestion.tags,
    };
  } else {
    data = dataQuestion;
  }

  httpClient
    .post('/forum/questions', data)
    .then(() => {
      toast.success('Discussão criada com sucesso!');
      callback();
    })
    .catch(error => toast.error('Ocorreu um erro: ' + error.message));
};
