import LiveFromResponse from '../../services/models/from-api-result/live';
import Live from '../../services/models/live';

const convertLive = (live: LiveFromResponse): Live => {
  return {
    liveId: live.live_id,
    name: live.name,
    description: live.description,
    transmissionId: live.transmission_id,
    start: live.start ? new Date(live.start) : new Date(),
    stop: live.stop ? new Date(live.stop) : undefined,
    status: live.status,
    thumbnail: live.thumbnail_url,
    channelId: live.channel_id,
    channelSvId: live.channel_sv_id
  };
};

export default convertLive;
