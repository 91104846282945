import styled from 'styled-components';

export const ScoreContainer = styled.main`
  margin: 60px 0 30px;
  width: 90vw;
  padding: 0 10px;
`;

export const Title = styled.h1`
  color: #009ee0;
  font-size: 40px;
  text-align: left;
  margin-bottom: 50px;
  position: relative;

  &::after{
    content: '';
    width: 100%;
    height: 5px;
    background: #009ee0;
    display: block;
    position: absolute;
    bottom: -20px;
    left: 0;
  }
`;

export const ScoreContent = styled.div`

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center
    padding: 20px;
  }

  box-shadow: 1px 1px 20px -4px rgba(118, 126, 173, 0.47);
  min-height: 500px;
  padding: 50px 20px;

  display: flex;
  align-items: center;
  flex-direction: row;

`;

export const ProfileBox = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }

  width: 35%;
  padding: 30px 20px 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export const Img = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;

export const RankingBox = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 30px;
  }

  width: 65%;
  margin-left: 15px;
`;

export const ClassificationsBoxes = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 20px;
`;

export const BoxItem = styled.div`
  width: 138px;
  height: 130px;
  margin-top: 10px;
  padding: 15px;
  border: 1px solid #d6d6d6;
  background-color: #fff;
  margin: 0 10px;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;

  svg {
    margin-bottom: 10px;
  }

  span {
    margin: 5px 0;
  }

  strong {
    margin-top: 5px;
    font-size: 20px;
    font-weight: 700;
  }
`;

export const RankingList = styled.ul`
  @media (max-width: 768px) {
    margin-top: 30px;
  }

  width: 100%;

  h2 {
    font-size: 25px;
    margin-bottom: 15px;
    color: #333;
  }
`;
