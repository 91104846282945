import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Container } from './style';

interface PaginationProps {
  getAllItems: (filters: any) => any;
  setAvailableItems: (data: any) => void;
  availableItems: Array<any>;
}

const Pagination: React.FC<PaginationProps> = ({
  getAllItems,
  setAvailableItems,
  availableItems,
}) => {
  const [canLoadMore, setCanLoadMore] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (page >= 1) {
      (async () => {
        try {
          const localItems = await getAllItems({
            limit: 12,
            offset: page > 1 ? (page - 1) * 12 : undefined,
          });
          const nextItem = await getAllItems({
            limit: 1,
            offset: (page) * 12,
          });
          setCanLoadMore(!!(nextItem && nextItem.length));
          if (localItems && localItems.length) {
            if (page === 1) {
              setAvailableItems(localItems);
              return;
            }

            setAvailableItems([...availableItems, ...localItems]);
          }
        } catch (e) {
          toast.error('Erro ao carregar os itens disponíveis.');
        }
      })();
    }
  }, [page]);

  const handleLoadMoreCourses = () => {
    setPage(page + 1);
  };

  return (
    <Container>
      {canLoadMore && (
        <span onClick={handleLoadMoreCourses}>Carregar mais</span>
      )}
    </Container>
  );
};

export default Pagination;
