import React, { useMemo } from 'react';
import { BsBookmark } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { addCourseToList } from '../../services/Course';
import { addDisciplineToList } from '../../services/Discipline';
import { BookmarkContainer } from './style';

interface BookmarkProps {
  disciplineId?: string;
  courseId?: string;
  addedToList: boolean;
  onUpdate: (addedToList: boolean) => void;
}

const Bookmark: React.FC<BookmarkProps> = ({
  disciplineId,
  courseId,
  addedToList,
  onUpdate,
}) => {
  const addToList = async () => {
    const shouldAddToList = !addedToList;
    try {
      if (isDiscipline) {
        await addDisciplineToList(disciplineId!, shouldAddToList);
      } else {
        await addCourseToList(courseId || '', shouldAddToList);
      }

      onUpdate(shouldAddToList);
    } catch (error) {
      const errorMessage =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message;

      toast.error(
        `Erro ao adicionar ${
          disciplineId ? 'a disciplina' : 'o curso'
        } aos favoritos. ${errorMessage}`,
      );
    }
  };

  const isDiscipline = useMemo(() => !!disciplineId, [disciplineId]);

  return (
    <BookmarkContainer
      onClick={addToList}
      className={addedToList ? 'added' : ''}
    >
      <span className="text">
        {!addedToList ? 'Adicionar' : 'Remover'}{' '}
        {isDiscipline ? 'a disciplina' : 'o curso'} {!addedToList ? 'aos' : 'dos'}{' '}
        favoritos
      </span>
      <BsBookmark
        color={addedToList ? 'white' : 'var(--primary-blue)'}
        size={18}
      />
    </BookmarkContainer>
  );
};

export default Bookmark;
