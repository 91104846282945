import React from 'react';

interface MyAnnotationContextProps {
  getAllAnnotations: () => Promise<void>;
}

const MyAnnotationContext = React.createContext<MyAnnotationContextProps>({
  getAllAnnotations: async () => {}
});

export default MyAnnotationContext;
