import styled from 'styled-components';

export const IndicatorsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 0 15px;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    margin: 15px;
    height: 300px;
    width: 380px;

    h3[class$=-title] {
      font-size: 23px;
    }

    span[class$=-text] {
      font-size: 14px;
      font-weight: 500;
    }

    a[class$=-link] {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold; 

      & > span {
        margin-right: 15px;
        color: #142649;
      }

      & > svg {
        path {
          color: #142649;
        }
      }
    }
  }
`;