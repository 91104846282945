import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import CertificateData from '../../../services/models/certificate-data';
import User from '../../../services/models/user';
import Discipline from '../../../services/models/discipline';
import RobotoRegular from '../fonts/Roboto-Regular.ttf';
import RobotoItalic from '../fonts/Roboto-Italic.ttf';
import RobotoBold from '../fonts/Roboto-Bold.ttf';
import SignatureGiovanna from '../../../assets/signature_giovanna.png';
import ManagementSchool from '../../../assets/vallourec-management-school.jpeg';

Font.register({ family: 'Roboto', src: RobotoRegular });
Font.register({ family: 'Roboto', src: RobotoItalic, fontStyle: 'italic' });
Font.register({ family: 'Roboto', src: RobotoBold, fontWeight: 'bold' });

const styles = StyleSheet.create({
  page: {
    padding: '40pt',
    fontSize: '16pt',
    fontFamily: 'Roboto',
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoUpperBlock: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    width: '150pt',
  },
  title: {
    color: '#1EA9E4',
    fontSize: '20pt',
  },
  italicSmall: {
    fontStyle: 'italic',
    fontSize: '12pt',
  },
  block: {
    margin: '8pt 0pt',
    width: '100%',
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  trailDescriptionContainer: {
    width: '100%',
    marginBottom: '5pt',
    marginTop: '5pt',
  },
  removeMargin: {
    margin: '0pt',
  },
  signatureGiovannaContainer: {},
  signatureGiovanna: {
    width: '300pt',
  },
  signaturesContainer: {
    width: '100%',
    marginTop: '50pt',
    paddingRight: '50pt',
    paddingLeft: '50pt',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  managementSchool: {
    width: '150pt',
  },
});

interface VallourecTemplateProps {
  certificateData: CertificateData;
  trail: Discipline;
  user: User;
}

const VallourecTemplate: React.FC<VallourecTemplateProps> = ({
  certificateData,
  trail,
  user,
}) => {
  const completionDate = certificateData.emitted_at
    ? new Intl.DateTimeFormat('pt-BR', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      }).format(new Date(certificateData.emitted_at))
    : '';

  const convertToTimeText = (duration: number) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor((duration % 3600) % 60);

    let timeText = '';

    if (hours > 0) {
      timeText += `${hours} hora${hours > 1 ? 's' : ''}, `;
    }

    if (minutes > 0) {
      timeText += `${minutes} minuto${minutes > 1 ? 's' : ''}, `;
    }

    if (seconds > 0) {
      timeText += `${seconds} segundo${seconds > 1 ? 's' : ''}.`;
    }

    return timeText;
  };

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.pageContainer}>
          <View style={{ ...styles.block, ...styles.logoUpperBlock }}>
            <Image
              style={styles.logo}
              src={`${process.env.PUBLIC_URL}/logo-vallourec.png`}
            />

            <Image style={styles.managementSchool} src={ManagementSchool} />
          </View>

          <View style={{ ...styles.block, ...styles.textCenter }}>
            <Text style={styles.title}>A Universidade Vallourec</Text>
          </View>

          <View style={{ ...styles.block, ...styles.textCenter }}>
            <Text style={styles.italicSmall}>Certifica que</Text>
          </View>

          <View style={{ ...styles.block, ...styles.textCenter }}>
            <Text>{user.name}</Text>
          </View>

          <View style={{ ...styles.block, ...styles.textCenter }}>
            <Text style={styles.italicSmall}>Concluiu com sucesso a disciplina</Text>
          </View>

          <View style={{ ...styles.block, ...styles.textCenter }}>
            <Text>{trail.name}</Text>
          </View>

          <View style={styles.trailDescriptionContainer}>
            <View style={styles.block}>
              <Text>Descrição: {trail.description}</Text>
            </View>

            <View style={styles.block}>
              <Text>Duração: {convertToTimeText(trail.duration)}</Text>
            </View>

            <View style={styles.block}>
              <Text>Data: {completionDate}</Text>
            </View>
          </View>

          <View style={{ ...styles.signaturesContainer }}>
            <View style={{ ...styles.signatureGiovannaContainer }}>
              <Image
                style={{ ...styles.signatureGiovanna }}
                src={SignatureGiovanna}
              />
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default VallourecTemplate;
