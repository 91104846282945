import styled from 'styled-components';

export const AvailableDisciplinesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 60px auto;

  .available-disciplines-title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .available-disciplines-title {
      font-size: 40px;
      color: #009ee0;
    }

    &::after {
      content: "";
      width: 60px;
      border-bottom: solid 3px #009ee0;
      margin-top: 25px;
      margin-bottom: 40px;  
    }
  }

  .available-disciplines-cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    margin-bottom: 10px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: 1200px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .available-disciplines-link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #142649;

    & > *:not(:last-child) {
      margin-right: 10px;
    }
  }
`;
