import styled from 'styled-components';

export const MyProgressContainer = styled.div`
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 80px;

  @media (max-width: 768px) {
    padding: 10px 15px;
  }

  .my-progress-title {
    font-size: 40px;
    color: #099ee0;
    width: 100%;

    &::after {
      content: '';
      display: block;
      border-bottom: solid 5px #099ee0;
      margin: 20px 0;
    }
  }
`;
