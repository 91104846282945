import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Annotation from '../../../../../../services/models/annotations';
import {
  getAllAnnotations as getAllAnnotationsService,
  createAnnotation as createAnnotationService,
  deleteAnnotation as deleteAnnotationService,
  updateAnnotation as updateAnnotationService,
} from '../../../../../../services/Annotations/index';
import { AnnotationsContainer } from './style';
import DisciplineContext from '../../../../DisciplineContext';
import checkEmptyString from '../../../../../../helpers/check-empty-string';
import getErrorMessage from '../../../../../../helpers/get-error-message';
import Swal from 'sweetalert2';

interface AnnotationsProps {
  contentId: string;
}

const Annotations: React.FC<AnnotationsProps> = ({ contentId }) => {
  const { course } = useContext(DisciplineContext);

  const [newAnnotationTitle, setNewAnnotationTitle] = useState('');
  const [newAnnotationContent, setNewAnnotationContent] = useState('');
  const [annotationsToBeShown, setAnnotationsToBeShown] = useState<
    Annotation[]
  >([]);
  const [idEditingAnnotation, setIdEditingAnnotation] = useState('');

  const validateCreationOrUpdate = () => {
    if (!course?.id) {
      throw new Error('Erro ao cadastrar anotação: informe o ID da disciplina');
    }

    if (checkEmptyString(newAnnotationTitle)) {
      throw new Error('Informe um título válido para a anotação!');
    }

    if (checkEmptyString(newAnnotationContent)) {
      throw new Error('Informe um conteúdo válido para a anotação!');
    }
  };

  const createNewAnnotation = async (event: React.FormEvent) => {
    event.preventDefault();

    const newAnnotation = {
      annotation_id: '',
      title: newAnnotationTitle,
      description: newAnnotationContent,
    };

    try {
      validateCreationOrUpdate();

      await createAnnotationService(newAnnotation, course?.id!, contentId);

      toast.success('Anotação criada com sucesso!');
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      toast.error('Erro ao cadastrar anotação. ' + errorMessage);
    }

    setNewAnnotationTitle('');
    setNewAnnotationContent('');

    await fetchNotes();
  };

  const removeAnnotation = (annotationId: string) => {
    Swal.fire({
      icon: 'question',
      text: 'Tem certeza que deseja remover esta anotação?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.isConfirmed) {
        try {
          await deleteAnnotationService(`${annotationId}`);
          await fetchNotes();
          toast.success('Anotação removida com sucesso!');
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          toast.error('Erro ao remover anotação. ' + errorMessage);
        }
      }
    });
  };

  const editAnnotation = (annotationId: string) => {
    setIdEditingAnnotation(annotationId);

    const annotation = annotationsToBeShown.find(
      annotation => annotationId === annotation.annotation_id,
    );

    if (annotation) {
      setNewAnnotationContent(annotation.description);
      setNewAnnotationTitle(annotation.title);
    }
  };

  const updateAnnotation = async (event: React.FormEvent) => {
    event.preventDefault();
    const uneditedAnnotation = annotationsToBeShown.find(
      annotation => idEditingAnnotation === annotation.annotation_id,
    );

    if (uneditedAnnotation) {
      const editedAnnotation = {
        annotation_id: uneditedAnnotation.annotation_id,
        date: uneditedAnnotation.date,
        title: newAnnotationTitle,
        description: newAnnotationContent,
      };

      try {
        validateCreationOrUpdate();

        await updateAnnotationService(idEditingAnnotation, editedAnnotation);
        toast.success('Anotação atualizada com sucesso!');
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        toast.error('Erro ao atualizar anotação' + errorMessage);
      }
    }

    setIdEditingAnnotation('');
    setNewAnnotationTitle('');
    setNewAnnotationContent('');

    await fetchNotes();
  };

  const fetchNotes = async () => {
    let annotations: Annotation[] = await getAllAnnotationsService(
      course?.id!,
      contentId,
    );
    setAnnotationsToBeShown(annotations);
  };

  useEffect(() => {
    if (course?.id && contentId) {
      fetchNotes();
    }
  }, [contentId, course?.id, idEditingAnnotation]);

  return (
    <AnnotationsContainer>
      <form
        onSubmit={e => {
          if (idEditingAnnotation !== '') {
            updateAnnotation(e);
          } else {
            createNewAnnotation(e);
          }
        }}
        className="new-annotation"
      >
        {idEditingAnnotation !== '' ? (
          <span className="editing-annotation-label">Edite sua anotação:</span>
        ) : (
          <span className="new-annotation-label">Crie uma anotação:</span>
        )}

        <input
          type="text"
          className="new-annotation-title-input"
          value={newAnnotationTitle}
          onChange={e => setNewAnnotationTitle(e.target.value)}
          placeholder="Titulo"
        />

        <textarea
          className="new-annotation-contenct-input"
          value={newAnnotationContent}
          onChange={e => setNewAnnotationContent(e.target.value)}
          placeholder="Escreva Aqui..."
        ></textarea>

        {idEditingAnnotation !== '' ? (
          <button type="submit" className="edditing-annotation-update">
            Atualizar Nota
          </button>
        ) : (
          <button type="submit" className="new-annotation-create">
            Criar Nota
          </button>
        )}
      </form>

      <ul className="annotations">
        {idEditingAnnotation === '' &&
          annotationsToBeShown.map((annotation, index) => (
            <li className="annotation" key={index}>
              <div className="annotation-top-content-container">
                <h3 className="annotation-title">{annotation.title}</h3>
                <div className="annotation-actions">
                  <button
                    className="action-button edit-annotation"
                    onClick={() => {
                      editAnnotation(annotation.annotation_id);
                    }}
                  >
                    <span>Editar</span>
                  </button>

                  <button
                    className="action-button remove-annotation"
                    onClick={() => {
                      removeAnnotation(annotation.annotation_id);
                    }}
                  >
                    <span>Excluir</span>
                  </button>
                </div>
              </div>

              <div className="annotation-content">{annotation.description}</div>
            </li>
          ))}
      </ul>
    </AnnotationsContainer>
  );
};

export default Annotations;
