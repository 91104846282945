import httpClient from '../../http-client';
import ForumCategory from '../models/forum-category';
import ForumCategoryFromResponse from '../models/from-api-result/forum-category';

const getForumCategories = async (filters?: Object) => {
  const categories = (
    await httpClient.get<ForumCategoryFromResponse[]>('/forum/categories')
  ).data;

  let convertedCategories = [] as ForumCategory[];
  if (categories && categories.length) {
    convertedCategories = categories.map(
      cat =>
        ({
          description: cat.description,
          id: cat.category_id,
        } as ForumCategory),
    );
  }

  return convertedCategories;
};

export { getForumCategories };
