import React from 'react';
import { FAQItemCardContainer } from './style';
import Faq from '../../../services/models/faq';

interface FAQItemProps {
  faqItem: Faq
}

const FAQItemCard: React.FC<FAQItemProps> = ({ faqItem }) => {

  return (
    <FAQItemCardContainer>
      <h4 className="faq-item-question">{faqItem.question}</h4>

      <p className="faq-item-answer">{faqItem.answer}</p>
    </FAQItemCardContainer>
  );
}

export default FAQItemCard;
