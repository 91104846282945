import React, { useEffect, useState } from 'react';
import { HomeCarouselContainer } from './style';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { BsCircle, BsFillCircleFill } from 'react-icons/bs';
import HomeInfoFromResponse from '../../../../services/models/from-api-result/home-info';

interface HomeCarouselProps {
  images: HomeInfoFromResponse[];
}

const HomeCarousel: React.FC<HomeCarouselProps> = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(images[0] || {});
  const [transitionClass, setTransitionClass] = useState('');

  const nextImage = () => {
    const indexOfActualImage = images.indexOf(selectedImage);

    if (indexOfActualImage > -1) {
      if (indexOfActualImage === images.length - 1) {
        setImage(images[0], 'entering-from-right');
      } else {
        setImage(images[indexOfActualImage + 1], 'entering-from-right');
      }
    }
  };

  const previousImage = () => {
    const indexOfActualImage = images.indexOf(selectedImage);

    if (indexOfActualImage > -1) {
      if (indexOfActualImage === 0) {
        setImage(images[images.length - 1], 'entering-from-left');
      } else {
        setImage(images[indexOfActualImage - 1], 'entering-from-left');
      }
    }
  };

  const setImage = (image: HomeInfoFromResponse, className: string) => {
    if (!className) {
      const indexOfActualImage = images.indexOf(image);
      const indexOfPreviousImage = images.indexOf(selectedImage);

      if (indexOfPreviousImage > indexOfActualImage) {
        className = 'entering-from-left';
      } else {
        className = 'entering-from-right';
      }
    }

    setSelectedImage(image);

    setEnteringClass(className);
  };

  const setEnteringClass = (className: string) => {
    setTransitionClass(className);

    setTimeout(() => {
      setTransitionClass('');
    }, 300);
  };

  useEffect(() => {
    setSelectedImage(
      images && images.length ? images[0] : ({} as HomeInfoFromResponse),
    );
  }, [images]);

  return (
    <HomeCarouselContainer>
      <div
        className={`
          home-carousel
          ${transitionClass}
        `}
        style={{
          backgroundImage: `linear-gradient(59deg, #172983, rgba(0, 158, 224, 0.39)), url(${
            selectedImage.image || ''
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <div className="top-content">
          <button onClick={previousImage} className="left-icon">
            <BiChevronLeft size={35} />
          </button>

          {selectedImage?.hyperlink && (
            <a href={`${selectedImage.hyperlink}`} className="title-container">
              <h2 className="title">{selectedImage.description || ''}</h2>
            </a>
          )}

          <button onClick={nextImage} className="right-icon">
            <BiChevronRight size={35} />
          </button>
        </div>
        <div className="lower-content">
          <div className="image-bread-crumbs">
            {images.map(image => (
              <button
                key={image.home_info_id}
                onClick={() => setImage(image, '')}
              >
                {!selectedImage ||
                selectedImage.home_info_id === image.home_info_id ? (
                  <BsFillCircleFill size={15} />
                ) : (
                  <BsCircle size={15} />
                )}
              </button>
            ))}
          </div>
        </div>
      </div>
    </HomeCarouselContainer>
  );
};

export default HomeCarousel;
