import React, { useState } from 'react';
import {
  DisciplinesContainer,
  DisciplinesList,
  DisciplinesTitle,
  NavContent,
} from './style';
import Discipline from '../../services/models/discipline';
import { getAllDisciplines } from '../../services/Discipline';
import DisciplineCard from '../../components/DisciplineCard';
import Search from '../../components/Search';
import Pagination from '../../components/Pagination';

const Disciplines: React.FC = () => {
  const [disciplines, setDisciplines] = useState([] as Discipline[]);

  const getDisciplines = async (filters?: object) => {
    const localDisciplines = await getAllDisciplines({
      ...filters,
      is_active: true,
    });

    return localDisciplines;
  };

  return (
    <DisciplinesContainer>
      <div className="content">
        <NavContent>
          <DisciplinesTitle>DISCIPLINAS</DisciplinesTitle>
          <Search typeSearch="disciplines" />
        </NavContent>

        <DisciplinesList>
          {disciplines && disciplines.length ? (
            disciplines.map(discipline => (
              <DisciplineCard key={discipline.id} discipline={discipline} />
            ))
          ) : (
            <></>
          )}
        </DisciplinesList>
      </div>
      <Pagination
        getAllItems={filters => getDisciplines(filters)}
        setAvailableItems={setDisciplines}
        availableItems={disciplines}
      />
    </DisciplinesContainer>
  );
};


export default Disciplines;
