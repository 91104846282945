import styled from 'styled-components';

export const LiveContainer = styled.div`
  max-width: 100vw;
  padding: 10px 80px;
  margin: 40px 0 100px 0;

  .live-title {
    font-size: 40px;
    color: #009ee0;

    &::after {
      content: '';
      display: block;
      border-bottom: solid 5px #009ee0;
      margin: 20px 0;
    }
  }

  .live-video-container {
    margin-bottom: 24px;

    h3 {
      font-size: 1.4em;
    }

    h4 {
      font-size: 1em;
    }

    iframe {
      width: calc(100vw - 175px);
      height: 500px;

      @media (max-width: 768px) {
        width: calc(100vw - 160px);
        height: calc(25vh);
      }
    }

    .live-video-title,
    .live-video-text {
      color: #333;
    }

    .live-video-title {
      margin: 20px 0 30px 0;
      font-size: 30px;
      font-weight: normal;
    }

    .live-video-text {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .next-lives {
    display: flex;
    flex-direction: column;
    border-top: solid 3px #009ee0;
    padding: 8px 0;

    button.slick-arrow {
      width: 36px;
      height: 36px;
      top: 0;
      transform: translateY(-115%);

      &.slick-prev {
        right: 78px;
      }

      svg {
        width: 16px;

        polyline {
          stroke: #fff;
        }
      }
    }

    h3 {
      font-size: 1.2em;
      padding: 0 0 16px 0;
    }

    h4 {
      font-size: 1em;
    }

    .live-card {
      padding: 16px;
      border-radius: 4px;
      background-repeat: no-repeat !important;
      background-size: cover !important;
      background-position: center !important;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 8px;
      min-height: 240px;
      max-width: 360px;

      & * {
        color: white;
      }
    }
  }
`;
