import React, { useContext, useMemo } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { AiOutlineLock, AiOutlinePlayCircle } from 'react-icons/ai';
import Content from '../../../../../../services/models/content';
import DisciplineContext from '../../../../DisciplineContext';
import {
  LessonContent,
  LessonDuration,
  LessonFlux,
  LessonFluxCircle,
  LessonFluxLowerBar,
  LessonFluxUpperBar,
  LessonTitle,
  ModuleLessonContainer,
} from './style';
import { useHistory, useParams } from 'react-router-dom';
import { secondsToTimeText } from '../../../../../../helpers/time';

interface CourseContentProps {
  content: Content;
}

interface DisciplineParams {
  disciplineId: string;
  contentId: string;
  courseId: string;
}

const CourseContent: React.FC<CourseContentProps> = ({ content }) => {
  const history = useHistory();
  const {
    content: selectedContent,
    playerType,
    discipline,
  } = useContext(DisciplineContext);

  const goToContent = () => {
    if (!content.isLocked) {
      if (playerType === 'curso') {
        return history.push(`/curso/${content.courseId}/${content.id}`);
      }

      return history.push(
        `/disciplina/${content.disciplineId}/${content.courseId}/${content.id}`,
      );
    }
  };

  const contentFluxIcon = useMemo(() => {
    if (content.alreadyFinished) {
      return <AiOutlineCheck size={24} color={'#2E2E2E'} />;
    } else if (content.isLocked) {
      return <AiOutlineLock size={24} color={'#2E2E2E'} />;
    } else {
      return <AiOutlinePlayCircle size={24} color={'#2E2E2E'} />;
    }
  }, [content, discipline]);

  const fluxClassName = useMemo(() => {
    return content.isLocked
      ? 'locked'
      : content.alreadyFinished
      ? 'finished'
      : 'unlocked';
  }, [content, discipline]);

  const isSelected = useMemo(() => {
    return (
      content.id === selectedContent?.id &&
      content.courseId === selectedContent?.courseId &&
      content.disciplineId === selectedContent.disciplineId
    );
  }, [content, discipline, selectedContent]);

  return (
    <ModuleLessonContainer
      className={content.isLocked ? 'locked' : ''}
      onClick={goToContent}
    >
      <LessonFlux>
        <LessonFluxUpperBar
          className={`${fluxClassName} ${
            content.isFirstOfCourse ? 'first' : ''
          }`}
        />
        <LessonFluxCircle className={fluxClassName}>
          {contentFluxIcon}
        </LessonFluxCircle>
        <LessonFluxLowerBar
          className={`${fluxClassName} ${content.isLastOfCourse ? 'last' : ''}`}
        />
      </LessonFlux>
      <LessonContent>
        <LessonTitle className={isSelected ? 'selected' : ''}>
          {content.name}
        </LessonTitle>
        <LessonDuration>{secondsToTimeText(content.duration)}</LessonDuration>
      </LessonContent>
    </ModuleLessonContainer>
  );
};

export default CourseContent;
