import styled from 'styled-components';

export const WhoWeAreContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;

  @media (min-width: 1300px) {
    flex-wrap: nowrap;
  }

  .who-we-are-image {
    width: 100%;

    @media (min-width: 1300px) {
      width: 50%;
    }
  }

  .who-we-are-text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1c3383;
    padding: 25px;
    width: 100%;
    
    @media (min-width: 1300px) {
      width: 50%;
    }

    @media (min-width: 768px) {
      padding: 100px;
    }

    .who-we-are-title,
    .who-we-are-name,
    .who-we-are-text {
      color: white;
    }

    .who-we-are-title {
      width: 100%;

      &::after {
        content: '';
        border-bottom: solid 5px white;
        width: 100%;
        display: block;
        margin: 15px 0;
      }
    }

    .who-we-are-name {
      font-size: 46px;
      width: 100%;
      margin-bottom: 15px;
    }

    .who-we-are-text {
      font-size: 17px;
      line-height: 20px;
      font-weight: bold;
    }
  }
`;
