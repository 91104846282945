import React, { useContext, useState } from 'react';
import { AnnotationCardContainer } from './style';
import {
  updateAnnotation as updateAnnotationService,
  deleteAnnotation as removeAnnotationService,
} from '../../../../services/Annotations';
import Annotation from '../../../../services/models/annotations';
import MyAnnotationContext from '../../MyAnnotationsContext';
import Swal from 'sweetalert2';
import checkEmptyString from '../../../../helpers/check-empty-string';
import getErrorMessage from '../../../../helpers/get-error-message';

interface AnnotationProps {
  annotation: Annotation;
}

const AnnotationCard: React.FC<AnnotationProps> = ({ annotation }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [annotationTitle, setAnnotationTitle] = useState('');
  const [annotationContent, setAnnotationContent] = useState('');
  const { getAllAnnotations } = useContext(MyAnnotationContext);

  const edit = () => {
    setIsEditing(true);
    setAnnotationTitle(annotation.title);
    setAnnotationContent(annotation.description);
  };

  const remove = () => {
    Swal.fire({
      text: 'Tem certeza que deseja remover esta anotação?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.isConfirmed) {
        await removeAnnotationService(annotation.annotation_id);
        await getAllAnnotations();
      }
    });
  };

  const validate = () => {
    if (checkEmptyString(annotationTitle)) {
      throw new Error('Informe um título valido para a anotação!');
    }

    if (checkEmptyString(annotationContent)) {
      throw new Error('Informe um conteúdo valido para a anotação!');
    }
  };

  const updateAnnotation = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validate();

      annotation.title = annotationTitle;
      annotation.description = annotationContent;
      await updateAnnotationService(annotation.annotation_id, annotation);
      await getAllAnnotations();
      setIsEditing(false);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        text: 'Erro ao atualizar anotação. ' + errorMessage,
      });
    }
  };

  const cancelEdit = () => {
    setIsEditing(false);
  };

  return (
    <AnnotationCardContainer>
      <form
        onSubmit={e => updateAnnotation(e)}
        className="annotation-content-edit"
      >
        <div className="annotation-title-container">
          <h4 className="annotation-title">
            {isEditing ? (
              <input
                className="annotation-title-input"
                type="text"
                value={annotationTitle}
                onChange={e => setAnnotationTitle(e.target.value)}
              />
            ) : (
              <span>{annotation.title}</span>
            )}
          </h4>

          <div className="annotation-action-buttons">
            {isEditing ? (
              <>
                <button
                  type="button"
                  className="annotation-cancel-button"
                  onClick={cancelEdit}
                >
                  Cancelar
                </button>
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="annotation-edit-button"
                  onClick={edit}
                >
                  Editar
                </button>

                <button
                  type="button"
                  className="annotation-remove-button"
                  onClick={remove}
                >
                  Excluir
                </button>
              </>
            )}
          </div>
        </div>

        <div className="annotation-content">
          {isEditing ? (
            <>
              <textarea
                onChange={e => setAnnotationContent(e.target.value)}
                className="annotation-content-textarea"
                rows={10}
                value={annotationContent}
              ></textarea>

              <input
                type="submit"
                className="annotation-content-submit"
                value="Salvar"
              />
            </>
          ) : (
            <span>{annotation.description}</span>
          )}
        </div>
      </form>
    </AnnotationCardContainer>
  );
};

export default AnnotationCard;
