import React, { useEffect, useState } from 'react';
import { AiFillEye, AiFillStar } from 'react-icons/ai';
import { BsPencil } from 'react-icons/bs';
import { AiOutlinePaperClip } from 'react-icons/ai';
import Annotations from '../Annotations';
import GeneralView from '../GeneralView';
import SupportMaterials from '../SupportMaterials';
import { ContentTabsContainer, Tab, TabContent, Tabs } from './style';
import CourseReviews from '../CourseReview';

interface ContentTabsProps {
  contentId: string;
  contentDescription: string;
}

interface ITab {
  name: string;
  title: React.ReactElement;
  component: React.ReactElement;
}

const ContentTabs: React.FC<ContentTabsProps> = ({
  contentDescription,
  contentId,
}) => {
  const [selectedTab, setSelectedTab] = useState('overview');

  const tabs: ITab[] = [
    {
      name: 'overview',
      title: (
        <Tab
          type="button"
          onClick={() => changeContent('overview')}
          className={`${selectedTab === 'overview' ? 'active' : ''}`}
          key={'overview'}
        >
          <span className="icon">
            <AiFillEye color="var(--primary-blue)" size={22} />
          </span>
          <span className="text">Visão geral</span>
        </Tab>
      ),
      component: <GeneralView description={contentDescription} />,
    },
    {
      name: 'support-material',
      title: (
        <Tab
          type="button"
          onClick={() => changeContent('support-material')}
          className={`${selectedTab === 'support-material' ? 'active' : ''}`}
          key={'support-material'}
        >
          <span className="icon">
            <AiOutlinePaperClip color="var(--primary-blue)" size={22} />
          </span>
          <span className="text">Materiais de Apoio</span>
        </Tab>
      ),
      component: <SupportMaterials contentId={contentId} />,
    },
    {
      name: 'annotations',
      title: (
        <Tab
          type="button"
          onClick={() => changeContent('annotations')}
          className={`${selectedTab === 'annotations' ? 'active' : ''}`}
          key={'annotations'}
        >
          <span className="icon">
            <BsPencil color="var(--primary-blue)" size={22} />
          </span>
          <span className="text">Anotações</span>
        </Tab>
      ),
      component: <Annotations contentId={contentId} />,
    },
    {
      name: 'reviews',
      title: (
        <Tab
          type="button"
          onClick={() => changeContent('reviews')}
          className={`${selectedTab === 'reviews' ? 'active' : ''}`}
          key={'reviews'}
        >
          <span className="icon">
            <AiFillStar color="var(--primary-blue)" size={22} />
          </span>
          <span className="text">Depoimentos</span>
        </Tab>
      ),
      component: <CourseReviews />,
    },
  ];

  const [actualTab, setActualTab] = useState(tabs[0]);

  const changeContent = (tabName: string) => {
    const tabToBeChanged =
      tabs && tabs.length
        ? tabs.find(tab => tab.name === tabName)
        : ({} as ITab);

    if (tabToBeChanged) {
      setActualTab(tabToBeChanged);
      setSelectedTab(tabToBeChanged.name);
    }
  };

  useEffect(() => {
    changeContent('overview');
  }, [contentId]);

  return (
    <ContentTabsContainer>
      <Tabs>{tabs && tabs.length ? tabs.map(tab => tab.title) : <></>}</Tabs>
      <TabContent>{actualTab && actualTab.component}</TabContent>
    </ContentTabsContainer>
  );
};

export default ContentTabs;
