import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 0 30px;
  > a {
      width: 100px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      text-decoration: none;
      > span {
        margin-left: 5px;
        color: var(--primary-blue);
        font-weight: bold;
        font-size: 20px;
      }
      transition: opacity 250ms ease;

      &:hover {
        opacity: .7;
      }
    }
`;
