import React from 'react';
import { ProgressBarContainer } from './style';

interface ProgressBarProps {
  progress?: number;
  width?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, width }) => {
  return (
    <ProgressBarContainer style={{ width: `${width}px` }}>
      <span className="progress">{+(progress || 0).toFixed(0)}%</span>

      <div className="graphical-progress-container">
        <span
          className="graphical-progress"
          style={{ width: `${progress}%` }}
        ></span>
      </div>
    </ProgressBarContainer>
  );
};

export default ProgressBar;
