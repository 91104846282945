import httpClient from '../../http-client';
import Annotation from '../models/annotations';

const getMyAnnotations = async () => {
  const myAnnotations = (await httpClient.get('/annotations')).data;
  return myAnnotations;
}

const getAllAnnotations = async (courseId: string, contentId: string) => {
  if (!courseId || !contentId) {
    throw new Error('Erro ao buscar anotações.');
  }

  const annotations = (
    await httpClient.get(`/annotations/${courseId}/${contentId}`)
  ).data;

  return annotations;
};

const createAnnotation = async (
  newAnnotation: Annotation,
  courseId: string,
  contentId: string,
) => {
  if (!newAnnotation) {
    throw new Error('Os dados para criação da anotação são obrigatórios');
  }

  const response = await httpClient({
    method: 'post',
    url: '/annotations',
    data: {
      content_id: contentId,
      course_id: courseId,
      title: newAnnotation.title,
      description: newAnnotation.description,
    },
  });

  return response;
};

const deleteAnnotation = async (annotationId: string) => {
  if (!annotationId) {
    throw new Error('ID obrigatório para exclusão!');
  }

  const response = await httpClient({
    method: 'delete',
    url: `/annotations/${annotationId}`,
  });

  return response;
};

const updateAnnotation = async (
  annotationId: string,
  newAnnotation: Annotation,
) => {
  if (!annotationId) {
    throw new Error('ID obrigatório para edição de anotação!');
  }

  if (!newAnnotation) {
    throw new Error('Dados para edição não encontrados!');
  }

  const response = await httpClient({
    method: 'put',
    url: `/annotations/${annotationId}`,
    data: {
      title: newAnnotation.title,
      description: newAnnotation.description,
    },
  });

  return response;
};

export {
  getAllAnnotations,
  updateAnnotation,
  deleteAnnotation,
  createAnnotation,
  getMyAnnotations
};
