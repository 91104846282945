import styled from 'styled-components';

const GeneralViewContainer = styled.div`
  > h4 {
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: 0px;
    font-size: 16px;
  }

  > p {
    margin-top: 10px;
    margin-bottom: 0px;
  }
`;

export {
  GeneralViewContainer
}
