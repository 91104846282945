import React from 'react'; 
import { FaMedal } from 'react-icons/fa';
import { BoxItem } from '../../styles';
import { setMedalColor } from '../../index';

interface IClassificationBox {
  position: number;
}

const ClassificationBox: React.FC<IClassificationBox> = ({ position }) => {
  const medalColor = setMedalColor(position);
  return (
    <BoxItem>
      <FaMedal size={35} color={medalColor} />
      <span>Classificação</span>
      <strong>{position}º</strong>
    </BoxItem>
  );
}

export default ClassificationBox;
