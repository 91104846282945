import React, { useEffect, useState } from 'react';
import { AvailableDisciplinesContainer } from './style';
import { Link } from 'react-router-dom';
import { BsArrowRight } from 'react-icons/bs';
import DisciplineCard from '../../../../components/DisciplineCard';
import Discipline from '../../../../services/models/discipline';
import { getHomeAvailableDisciplines } from '../../../../services/Discipline';

const AvailableDisciplines: React.FC = () => {
  const [disciplines, setDisciplines] = useState([] as Discipline[]);

  useEffect(() => {
    (async () => {
      let localDisciplines = await getHomeAvailableDisciplines();
      setDisciplines(localDisciplines);
    })();
  }, []);
  return (
    <AvailableDisciplinesContainer>
      <div className="available-disciplines-title-container">
        <h2 className="available-disciplines-title">CONTEÚDOS DISPONÍVEIS</h2>
      </div>

      <div className="available-disciplines-cards">
        {disciplines &&
          disciplines.map(discipline => (
            <DisciplineCard key={discipline.id} discipline={discipline} />
          ))}
      </div>

      <Link to="/disciplinas" className="available-disciplines-link-container">
        <span>Veja todos as disciplinas disponíveis</span>
        <BsArrowRight size={20} />
      </Link>
    </AvailableDisciplinesContainer>
  );
};

export default AvailableDisciplines;
