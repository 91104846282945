import styled from 'styled-components';

export const UserProfileDropdownContainer = styled.div`
  position: relative;

  .user-profile-dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 200px;
    border: solid 1px #e3e3e3;
    padding: 10px 20px;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    *:not(:first-child) {
      margin-left: 15px;
    }

    .user-image {
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }

    .greetings {
      text-align: left;
      font-size: 14px;

      & strong {
        display: block;
        white-space: nowrap;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .user-profile-dropdown-content {
    position: absolute;
    z-index: 2;
    background: white;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 0 5px;
    border: solid 1px #e3e3e3;

    &.visible {
      display: flex;
    }

    a,
    button {
      color: #333;
      text-decoration: none;
      padding: 20px;
      width: 100%;
      outline: none;
      border: none;
      background: transparent;
      cursor: pointer;
      font-size: 14px;
      text-align: left;

      &:not(:last-child) {
        border-bottom: solid 1px #e3e3e3;
      }
    }
  }
`;
