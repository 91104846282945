import styled from 'styled-components';

const CourseRateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  border-radius: 4px;
`;

const CourseRateButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: var(--primary-blue);
  border-radius: 4px;
  font-size: 1.1em;
  color: white;
  padding: 8px 16px;
`;

const DisciplineContainer = styled.div`
  width: 100%;
`;

const DisciplineTitle = styled.h2`
  color: #009ee0;
  padding-bottom: 20px;
  border-bottom: solid 5px #009ee0;
  font-size: var(--font-larger);
  margin: 30px 0 5px 0;
`;

const ProgressAndBookmarkContainer = styled.div`
  border-bottom: solid 5px #009ee0;
  padding: 15px 0;
  margin-bottom: 10px;

  span.progress-container {
    & > :first-child {
      margin-right: 5px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContentAndCoursesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const DisciplineCourses = styled.div`
  margin-left: 10px;
  width: 100%;

  & .get-certified-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  & .get-certified-button {
    cursor: pointer;
    outline: none;
    border: none;
    background: var(--primary-blue);
    border-radius: 5px;
    color: white;
    font-size: 16px;
    padding: 5px 10px;
    margin: 5px;
    opacity: 0.8;
    transition: all 0.3s;

    & * {
      color: white;
    }

    & > *:not(:last-child) {
      margin-right: 5px;
    }

    &:hover {
      opacity: 1;
    }
  }
  

`;

const CourseInfoAndProgress = styled.div`
  display: grid;
  gap: 15px;
  grid-template-columns: 69% 30%;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const BookmarkButton = styled.button`
  background: var(--light-gray);
  border: none;
  outline: none;
  padding: 10px 15px;
  border-radius: 5px;
  color: #555555;
  cursor: pointer;

  &.added {
    background: var(--leve-color);
    color: white;
  }

  display: flex;
  align-items: center;
  gap: 5px;
`;

const CourseProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
 
  strong {
    margin:5px;
  }
`;

const CourseProgress = styled.div`
  height: 20px;
  flex-grow: 1;
  background: var(--border-gray);
  border-radius: 3px;
  overflow: hidden;
  gap: 5px;
  

  & > span {
    background: var(--primary-blue);
    border-radius: 10px;
  }


  display: flex;
  align-items: stretch;
`;

const CourseProgressBarContainer = styled.div`
  display: flex;
  flex-direction:column;
  align-items: center;
  gap: 2px;
  margin: 10px;
`;

const CourseLowerContent = styled.div`
  display: grid;
  grid-template-columns: calc(70% - 15px) 30%;
  gap: 15px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

export {
  DisciplineContainer,
  DisciplineTitle,
  ProgressAndBookmarkContainer,
  ContentAndCoursesContainer,
  DisciplineCourses,
  CourseInfoAndProgress,
  BookmarkButton,
  CourseProgressContainer,
  CourseProgress,
  CourseProgressBarContainer,
  CourseLowerContent,
  CourseRateContainer,
  CourseRateButton,
};
