import styled from 'styled-components';

export const MyDataContainer = styled.div`
  width: 100vw;
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    padding: 15px;
  }

  .my-data-title {
    font-size: 40px;
    color: #099EE0;
    width: 100%;

    &::after {
      content: "";
      display: block;
      border-bottom: solid 5px #099EE0;
      margin: 20px 0;
    }
  }

  .my-data-content {
    margin-top: 30px;

    .my-data-content-tabs-links {
      display: flex;
      justify-content: center;
      align-items: center;

      & > button:not(:last-child) {
        margin-right: 15px;
      }

      & > button {
        padding: 5px 10px;
        border: none;
        outline: none;
        background: transparent;
        cursor: pointer;
        color: #AAA8A8;
        font-size: 18px;
        font-weight: bold;

        &::after {
          content: "";
          display: block;
          border-bottom: solid 3px #AAA8A8;
          margin: 10px 0;
        }

        &.active {
          color: #099EE0;

          &::after {
            border-color: #099EE0;
          }
        }
      }
    }

    .user-data-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .user-image-container {
        position: relative;
        margin-top: 20px;
        margin-bottom: 40px;

        .user-image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }

        .change-image-button {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: none;
          background: #302A6C;
          outline: none;
          cursor: pointer;

          display: flex;
          justify-content: center;
          align-items: center;

          position: absolute;
          bottom: -5px;
          right: 5px;
        }
      }

      .user-name-and-occupation {
        text-align: center;
        margin-bottom: 40px;

        .user-name {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 5px;
        }

        .user-occupation {
          font-size: 14px;
        }
      }

      .user-data {
        .user-data-title {
          text-align: center;
          font-size: 24px;
          margin-bottom: 10px;
        }

        .user-data-form {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          & label {
            display: block;
            font-weight: bold;
            font-size: 16px;
          }

          & input:not([type=submit]) {
            width: 350px;
            height: 40px;
            padding: 10px;
            margin-top: 2px;
            margin-bottom: 8px;
            border: solid 1px #E3E3E3;
            outline: none;

            &:focus {
              outline: solid 1px #099EE0;
            }
          }

          & > input[type=submit] {
            padding: 5px 10px;
            border: none;
            outline: none;
            color: white;
            background: #099EE0;
            cursor: pointer;
            font-weight: bold;
          }
        }
      }
    }

    .change-password-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;

      .change-password-title {
        text-align: center;
        font-size: 24px;
        margin-bottom: 10px;
      }

      .change-password-content {
        .change-password-form {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          & > input:not([type=submit]) {
            width: 350px;
            margin: 8px 0;
            height: 40px;
            padding: 10px;
            border: solid 1px #E3E3E3;
            outline: none;

            &:focus {
              outline: solid 1px #099EE0;
            }
          }

          & > input[type=submit] {
            padding: 5px 10px;
            border: none;
            outline: none;
            color: white;
            background: #099EE0;
            cursor: pointer;
            font-weight: bold;
          }
        }
      }
    }
  }
`;