import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  align-content: center;
  align-items: center;

  .content {
    width: 80%;
    margin-top: 60px;
  }
`;

export const CardTitle = styled.h2``;

export const Button = styled.button`
  padding: 9px 15px;
  display: block;
  max-width: 220px;
  border: 1px solid #0d61a2;
  border-radius: 8px;
  background-color: transparent;
  transition: color 500ms ease, background-color 500ms ease;
  color: #333;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: #3898ec;
    color: white;
  }
`;

export const FormFooter = styled.div`
  margin-top: 30px;
  text-align: right;
  text-align: -webkit-right;
`;

export const Card = styled.div`
  border: 1px solid #e3e3e3;
  margin-top: 50px;
  padding: 40px;

  border-radius: 6px;

  form {
    width: 100%;

    .form-column {
      display: flex;
      justify-content: space-between;
    }

    .form-group {
      width: 100%;
      margin-top: 30px;

      .multi-select {
        margin-right: 5px;

        .dropdown-content {
          input[type='checkbox'] {
            height: 20px;
            width: auto;
          }
        }
      }

      label {
        display: block;
        margin-bottom: 5px;
      }

      input,
      textarea,
      select {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 5px 10px;
      }

      input,
      select {
        height: 40px;
      }

      textarea {
        min-height: 80px;
      }
    }
  }
`;
