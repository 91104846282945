import React, { useEffect, useState } from 'react';
import { getOngoingDisciplines as getOngoingDisciplinesService } from '../../../../../../services/MyProgress';
import { OngoingDisciplinesContainer } from './styles';
import Discipline from '../../../../../../services/models/discipline';
import DisciplineCard from '../../../../../../components/DisciplineCard';

const OngoingDisciplines: React.FC = () => {
  const [OngoingDisciplines, setOngoingDisciplines] = useState(
    [] as Discipline[],
  );

  const getOngoingDisciplines = async () => {
    const localOngoingDisciplines = await getOngoingDisciplinesService();
    setOngoingDisciplines(localOngoingDisciplines);
  };

  useEffect(() => {
    getOngoingDisciplines();
  }, []);

  return (
    <OngoingDisciplinesContainer>
      {OngoingDisciplines && OngoingDisciplines.length ? (
        OngoingDisciplines.map(discipline => (
          <DisciplineCard
            key={discipline.id}
            discipline={discipline}
            onAddToList={getOngoingDisciplines}
          />
        ))
      ) : (
        <h4>Não foram encontradas disciplinas em andamento!</h4>
      )}
    </OngoingDisciplinesContainer>
  );
};
export default OngoingDisciplines;
