import React from 'react';

import MultiSelect from 'react-multi-select-component';

interface CategoriesProps {
  options: Option[];
  value: Option[];
  onChange: (options: any) => void;
}

interface Option {
  value: string;
  label: string;
  id: string;
}

const labels = {
  allItemsAreSelected: 'Todos os itens estão selecionados.',
  clearSearch: 'Limpar',
  noOptions: 'Nenhuma opção disponível',
  search: 'Buscar',
  selectSomeItems: 'Selecione...',
};

const Categories: React.FC<CategoriesProps> = ({
  options,
  value,
  onChange,
}) => {
  return (
    <MultiSelect
      labelledBy={'Selecione uma opção'}
      onChange={(e: any) => onChange(e)}
      value={value}
      options={options}
      hasSelectAll={false}
      overrideStrings={labels}
    />
  );
};

export default Categories;
