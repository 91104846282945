import styled from 'styled-components';

export const AnnotationCardContainer = styled.div`
  width: 100%;
  margin: 10px 0;
  padding: 30px 15px;
  border: solid 1px #D9D9D9;

  .annotation-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .annotation-title {
      color: #2DBBF0;
      flex-grow: 1;
      margin-right: 15px;

      .annotation-title-input {
        padding: 10px;
        border: solid 1px #D9D9D9;
        width: 100%;

        &:focus {
          outline: solid 1px #2DBBF0;
        }
      }

      span{
        color: #009ee0;
      }
    }

    .annotation-action-buttons {
      & > button:not(:last-child) {
        margin-right: 15px;
      }

      .annotation-edit-button,
      .annotation-remove-button,
      .annotation-cancel-button {
        border: solid 1px #D9D9D9;
        outline: none;
        background: transparent;
        padding: 10px 15px;
        cursor: pointer;
        font-weight: bold;
      }

      .annotation-edit-button {
        color: #333;
      }

      .annotation-remove-button {
        color: #F24429;
      }
    }
  }

  .annotation-content {
    margin: 15px 0;
    font-weight: bold;
    color: #333;
    line-height: 20px;
    flex-grow: 1;

    .annotation-content-textarea {
      width: 100%;
      padding: 10px;
      border: solid 1px #D9D9D9;
      resize: none;

      &:focus {
        outline: solid 1px #2DBBF0;
      }
    }

    .annotation-content-submit {
      margin-top: 15px;
      padding: 5px 10px;
      border: solid 1px #D9D9D9;
      outline: none;
      background: #2DBBF0;
      color: white;
      font-weight: bold;
      cursor: pointer;
    }
  }
`;