import styled from 'styled-components';

const SupportMaterialsContainer = styled.div`

`;

const ExtraMaterialList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
`;

export {
  SupportMaterialsContainer,
  ExtraMaterialList
}