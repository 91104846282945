import styled from 'styled-components';

export const HomeCarouselContainer = styled.div`
  overflow: hidden;
  @keyframes slide-right {
    0%   { right: -60vw; }
    100% { right: 0; }
  }
  @keyframes slide-left {
    0%   { left: -60vw; }
    100% { left: 0; }
  }
  .entering-from-right {
    animation: slide-right 0.3s;
  }
  .entering-from-left {
    animation: slide-left 0.3s;
  }
  .home-carousel {
    position: relative;
    width: 100vw;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    .top-content {
      height: 93%;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left-icon,
      .right-icon {
        border: none;
        outline: none;
        background: transparent;
        padding: 5px;
        cursor: pointer;
        -webkit-transition-duration: 0.4s;
        transition-duration: 0.4s;
        svg {
          path {
            color: white;
          }
        }
        :hover {
          -webkit-transform: scale(1.6) translateZ(0);
          transform: scale(1.6) translateZ(0);
        }
      }
      .title-container {
        flex-grow: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 50vh;
        text-decoration: none;
        cursor: pointer;
        @media (min-width: 768px) {
          padding-left: 150px;
        }
        .title {
          max-width: 370px;
          font-size: 48px;
          color: white;
        }
      }
    }
    .lower-content {
      display: flex;
      justify-content: center;
      align-items: center;
      .image-bread-crumbs {
        button {
          border: none;
          outline: none;
          background: transparent;
          cursor: pointer;
          
          svg {
            path,
            circle {
              color: white;
            }
          }
        }
        & > *:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
`;
