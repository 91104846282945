import styled from 'styled-components';

export const Item = styled.li`
  margin-bottom: 40px;
  padding: 0 20px;
  box-shadow: -1px -1px 10px 10px #e6e6e6;
  position: relative;
  display: block;
  transition: height 250ms ease;

  &:hover {
    box-shadow: -1px -1px 5px 5px #e6e6e6;
  }

  .question-container {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 10px 0;
    color: #fff;
    background: #fff;
    cursor: pointer;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    &.closed {
      .module-title-container {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

    > h3 {
      width: 100%;
      padding: 10px;
      color: #0f4dbc;
      font-size: 18px;
      font-weight: 700;
      text-transform: none;
      display: flex;
      align-items: center;
    }

    .question-content-container {
      width: 100%;
      background: #fff;
      padding: 20px;
    }
  }

  .question-content-container {
    > h3 {
      margin-bottom: 15px;
    }

    .question-content {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .submit-section {
        height: 50px;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        > button {
          padding: 10px 38px;
          border-radius: 6px;
          border: 1px solid #00985f;
          font-weight: 900;
          text-align: center;
          cursor: pointer;
          background: transparent;
          color: #00985f;
          transition: background 250ms ease, color 250ms ease;

          &:disabled {
            pointer-events: none;
            border-color: darkgray;
            background-color: darkgray;
            color: white;
            cursor: not-allowed;
          }

          &:hover {
            background: #00985f;
            color: #fff;
          }
        }
      }
    }
  }
`;

export const Alternative = styled.label`
  width: 100%;
  margin: 10px 0;
  border: 1px solid #ddddddd1;
  border-radius: 8px;
  padding: 15px 10px;

  > input {
    color: #ddddddd1;
    font-size: 15px;
  }

  > span {
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
  }
`;
