import styled from 'styled-components';

const CertificateCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 10px 10px 20px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    transition: 0.5s;
  }
`;

const CertificateDownload = styled.button`
  margin: 5px 3px;
  border: none;
  background: #efedf3;
  padding: 3px;
  cursor: pointer;
  font-weight: bold;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CertificateTitle = styled.h3`
  margin: 5px 3px;
`;

const CertificateCardThumb = styled.img`
  width: 100%;
`;

export {
  CertificateCardContainer,
  CertificateDownload,
  CertificateTitle,
  CertificateCardThumb,
};
