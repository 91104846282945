import React, { useEffect, useState } from 'react';
import { RecommendedCoursesContainer } from './style';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import CourseCard from '../../../../components/CourseCard';
import { getCourses } from '../../../../services/Course';
import Course from '../../../../services/models/course';

const RecommendedCourses: React.FC = () => {
  const [courses, setCourses] = useState([] as Course[]);

  const getRecommendedCourses = async () => {
    const localCourses = await getCourses({
      is_active: true,
      limit: 4,
      is_single: true,
    });
    setCourses(localCourses);
  };

  const onCourseAddedToList = async (
    courseId: string,
    addedToList: boolean,
  ) => {
    const foundCourse = courses.find(c => c.id === courseId);
    if (foundCourse && foundCourse.id) {
      foundCourse.book_mark = addedToList;
    }

    setCourses([...courses]);
  };

  useEffect(() => {
    getRecommendedCourses();
  }, []);

  return (
    <RecommendedCoursesContainer>
      <span className="recommended-courses-label">
        CURSOS QUE TALVEZ SEJAM DO SEU INTERESSE
      </span>

      <h2 className="recommended-courses-title">RECOMENDADOS</h2>

      <div className="recommended-courses-cards-container">
        <div className="all-courses-container">
          <Link to="/cursos">
            <span>Veja todos os cursos</span>

            <BsArrowRight size={20} />
          </Link>
        </div>
        <div className="recommended-courses-cards">
          {courses.map(course => (
            <CourseCard
              key={course.id}
              course={course}
              onAddToList={onCourseAddedToList}
            />
          ))}
        </div>
      </div>
    </RecommendedCoursesContainer>
  );
};

export default RecommendedCourses;
