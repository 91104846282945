import React, { useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import Answer from '../../../../services/models/exam-answer';
import Question from '../../../../services/models/exam-question';
import EvaluationItem from '../EvaluationItem';
import { Container, List, SubmitEvaluation } from './style';
import {
  getAnswers as getAnswersService,
  getQuestion,
} from '../../../../services/Exam';
import { useHistory } from 'react-router';
import { finishExam as finishExamService } from '../../../../services/Exam';

interface QuestionProps {
  courseId: string;
  examUserId: string;
}

const EvaluationList: React.FC<QuestionProps> = ({
  examUserId,
  courseId,
}) => {
  const history = useHistory();
  const [answers, setAnswers] = useState([] as Answer[]);
  const [questions, setQuestions] = useState([] as Question[]);

  const finishExam = async () => {
    try {
      await finishExamService(examUserId);

      const disciplineId = new URLSearchParams(window.location.search).get(
        'disciplineId',
      );
      history.push(
        !!disciplineId
          ? `/disciplina/${disciplineId}/${courseId}`
          : `/curso/${courseId}`,
      );
    } catch (error) {
      Swal.fire({
        title: 'Erro',
        text: 'Erro ao finalizar a prova.',
        icon: 'error',
      });
    }
  };

  const getRespectiveAnswer = (questionId: string): Answer => {
    return answers.find(ans => ans.questionId === questionId)!;
  };

  const getAnswers = async () => {
    const localAnswers = await getAnswersService(examUserId);
    if (localAnswers && localAnswers.length) {
      setAnswers(
        localAnswers.map(
          ans =>
            ({
              alternativeId: ans.answer_id,
              examAnswerUserId: ans.exam_answer_user_id,
              questionId: ans.question_id,
            } as Answer),
        ),
      );

      let localQuestions = [] as Question[];
      for (let answer of localAnswers) {
        const question = await getQuestion(answer.question_id);
        localQuestions.push(question);
      }

      setQuestions(localQuestions);
    }
  };

  const setAnswered = (questionId: string, alternativeId: string) => {
    const answer = getRespectiveAnswer(questionId);
    answer.alternativeId = alternativeId;
    setAnswers([...answers]);
  };

  const allQuestionsAnswered = useMemo(() => {
    return (
      answers &&
      !!answers.length &&
      answers.every(answer => !!answer.alternativeId)
    );
  }, [answers]);

  useEffect(() => {
    if (examUserId) {
      getAnswers();
    }
  }, [examUserId]);

  return (
    <Container>
      <List>
        {questions.map((question, index) => (
          <EvaluationItem
            key={index}
            id={index.toString()}
            question={question}
            respectiveAnswer={getRespectiveAnswer(question.id)}
            onAnswer={setAnswered}
          />
        ))}
      </List>

      <SubmitEvaluation>
        <button
          disabled={!allQuestionsAnswered}
          onClick={() => (allQuestionsAnswered ? finishExam() : undefined)}
        >
          Concluir
        </button>
      </SubmitEvaluation>
    </Container>
  );
};

export default EvaluationList;
