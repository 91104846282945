import React from 'react';
import { GeneralViewContainer } from './style';

interface GeneralViewProps {
  description: string;
}

const GeneralView: React.FC<GeneralViewProps> = ({ description }) => {
  return (
    <GeneralViewContainer>
      <h4>Descrição da aula</h4>
      <p>{description}</p>
    </GeneralViewContainer>
  );
};

export default GeneralView;
