import styled from 'styled-components';
import { Container as GoBack } from '../../../components/GoBack/style';

export const Container = styled.div`
  justify-items: center;
  align-items: center;
  align-content: center;

  text-align: -webkit-center;

  ${GoBack} {
    text-align: left;
    margin-bottom: 70px;
  }
`;

export const QuestionContent = styled.div`
  .question {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .question-data {
      text-align: left;
    }

    .user-content {
      img {
        height: 100px;
        width: auto;

        border-radius: 50px;
        object-fit: cover;
      }
    }
  }
`;

export const Content = styled.div`
  width: 90%;
  margin-top: 60px;
  text-align: -webkit-center;

  .reply-question {
    margin-top: 100px;

    label,
    button {
      display: block;
    }

    label {
      text-align: left;
      margin-bottom: 10px;
      margin-top: 15px;
    }

    textarea {
      width: 100%;
      min-height: 200px;

      padding: 8px;
      margin-bottom: 10px;
      border-radius: 4px;
      resize: none;
    }

    button {
      text-align: right;
      margin-bottom: 20px;

      padding: 9px 15px;
      display: block;
      max-width: 220px;
      border: 1px solid #0d61a2;
      border-radius: 0px;
      background-color: #3898ec;
      transition: color 500ms ease, background-color 500ms ease;
      color: white;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;

      &:hover {
        background-color: transparent;
        color: #333;
      }
    }
  }
`;

export const ReplyContainer = styled.div`
  width: 60%;
  border-top: 1px solid #009ee0;

  label,
  button {
    display: block;
  }

  label {
    text-align: left;
    margin-bottom: 10px;
    margin-top: 15px;
  }

  textarea {
    width: 100%;
    min-height: 80px;
    padding: 5px;

    margin-bottom: 10px;
    border-radius: 4px;
    resize: none;
  }

  button {
    text-align: right;
    margin-bottom: 20px;

    padding: 9px 15px;
    display: block;
    max-width: 220px;
    border: 1px solid #0d61a2;
    border-radius: 0px;
    background-color: #3898ec;
    transition: color 500ms ease, background-color 500ms ease;
    color: white;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      background-color: transparent;
      color: #333;
    }
  }
`;

export const QuestionInfo = styled.div`
  font-size: 32px;
  display: flex;

  text-align: -webkit-left;

  .card-categorie {
    height: auto;
    margin-right: 10px;
    padding: 5px;
    border-radius: 8px;
    background-color: #4f5258;
    color: #fff;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
  }
`;

export const OrderNav = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  margin-top: 100px;

  span {
    margin-right: 10px;
  }

  button {
    padding: 9px 15px;
    margin-left: 2px;
    display: block;
    max-width: 220px;
    border: 1px solid #0d61a2;
    border-radius: 8px;
    background-color: #3898ec;
    transition: color 500ms ease, background-color 500ms ease;
    color: white;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;

    &:hover {
      background-color: transparent;
      color: #333;
    }
  }
`;

export const AnswersContainer = styled.ul`
  margin-top: 50px;
  width: 80%;

  .answer-section {
    border-bottom: 3px solid #009ee0;
  }
`;

export const Answer = styled.li`
  display: flex;
  justify-content: space-between;
  min-height: 150px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  &.reference {
    margin-left: 7%;
    border-top: 1px solid #009ee0;
  }

  .answer-content {
    padding-left: 10px;
    width: 60%;
    text-align: left;
    display: flex;

    align-items: center;
  }

  .user-content {
    img {
      height: 80px;
      width: auto;

      border-radius: 50px;
      object-fit: cover;
    }
  }

  .answer-interaction {
    width: 20%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    padding-bottom: 15px;

    svg {
      width: auto;
      height: 30px;
      cursor: pointer;

      :hover,
      :active {
        path {
          fill: #009ee0;
        }
      }
    }
  }
`;
