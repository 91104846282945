import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import * as S from './styles';
import { AiFillStar } from 'react-icons/ai';
import Loading from '../../../../../../components/Loading';
import DisciplineContext from '../../../../DisciplineContext';
import { getReviews } from '../../../../../../services/Course';

const CourseReviews: React.FC = () => {
  const { trailId } = useContext(DisciplineContext);
  const [reviews, setReviews] = useState([] as any);
  const [limit, setLimit] = useState(5);
  const [isShowingMoreText, setIsShowingMoreText] = useState(false);
  const [loading, showLoading] = useState(true);

  const getReviewsHere = useCallback(async () => {
    if (trailId) {
      showLoading(true);

      const reviews = await getReviews(trailId);
      setReviews(reviews);

      showLoading(false);
    }
  }, [trailId, setReviews]);

  const showAllReviews = () => {
    setLimit(reviews.length);
  };

  const reviewsToShow = useMemo(() => {
    return reviews?.length ? [...reviews].splice(0, limit) : [];
  }, [reviews, limit]);

  useEffect(() => {
    getReviewsHere();
  }, [getReviewsHere]);

  return (
    <S.CourseReviewsContainer>
      {reviewsToShow?.filter(review => review.show_to_all_users === true)
        .length ? (
        <>
          {reviewsToShow
            ?.filter(review => review.show_to_all_users === true)
            ?.filter(review => review.show_to_all_users === true)
            .map(review => (
              <S.CourseReviewCard>
                <p className="date">
                  <strong>Data do depoimento</strong>
                  <span>
                    {Intl.DateTimeFormat('pt-BR').format(
                      new Date(review.created_at),
                    )}
                  </span>
                </p>

                <p className="user">
                  <strong>Nome do Aluno</strong>
                  <span title={review.user_name}>{review.user_name}</span>
                </p>

                <p className="stars">
                  <strong>Avaliação</strong>
                  <span>
                    {Array(5)
                      .fill('')
                      .map((_, index) => (
                        <AiFillStar
                          size={24}
                          key={index}
                          color={
                            index + 1 <=
                            (+review.stars % 1 >= 0.5
                              ? Math.ceil(+review.stars)
                              : Math.floor(+review.stars))
                              ? '#FCC201'
                              : '#333'
                          }
                        />
                      ))}
                  </span>
                </p>
                <p className="description">
                  <strong>Descrição</strong>
                  <span>
                    {review.description
                      ? review.description.length < 128
                        ? review.description
                        : isShowingMoreText
                        ? review.description
                        : review.description.substring(0, 96) + '...'
                      : 'N/A'}
                    {review.description.length > 128 && (
                      <p
                        className="show-button"
                        onClick={() => setIsShowingMoreText(!isShowingMoreText)}
                      >
                        {isShowingMoreText ? 'ver menos' : 'ver mais'}
                      </p>
                    )}
                  </span>
                </p>
              </S.CourseReviewCard>
            ))}
          {limit < reviews.length && (
            <S.ShowAllButton onClick={showAllReviews}>
              Ver todos
            </S.ShowAllButton>
          )}
        </>
      ) : loading ? (
        <Loading />
      ) : (
        <h3>Este curso ainda não possui depoimentos.</h3>
      )}
    </S.CourseReviewsContainer>
  );
};

export default CourseReviews;
