import httpClient from '../../http-client';
import User from '../models/user';

const getActualUser = async (): Promise<User> => {
  let userToReturn = (await httpClient.get('/users/me')).data;

  return userToReturn;
};

const updateUserImage = async (imageUrl: string) => {
  await httpClient.patch(`/users/avatar`, {
    avatar: imageUrl,
  });
};

export { getActualUser, updateUserImage };
