import React, { useEffect, useState } from 'react';
import { getOngoingCourses as getOngoingCoursesService } from '../../../../../../services/MyProgress';
import { OngoingCoursesContainer } from './styles';
import Course from '../../../../../../services/models/course';
import CourseCard from '../../../../../../components/CourseCard';

const OngoingCourses: React.FC = () => {
  const [OngoingCourses, setOngoingCourses] = useState(
    [] as Course[],
  );

  const getOngoingCourses = async () => {
    const localOngoingCourses = await getOngoingCoursesService();
    setOngoingCourses(localOngoingCourses);
  };

  useEffect(() => {
    getOngoingCourses();
  }, []);

  return (
    <OngoingCoursesContainer>
      {OngoingCourses && OngoingCourses.length ? (
        OngoingCourses.map(course => (
          <CourseCard
            key={course.id}
            course={course}
            onAddToList={getOngoingCourses}
          />
        ))
      ) : (
        <h4>Não foram encontrados cursos em andamento!</h4>
      )}
    </OngoingCoursesContainer>
  );
};

export default OngoingCourses;
