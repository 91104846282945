import React, { useState } from 'react';
import ProgressBar from '../ProgressBar';
import { addCourseToList } from '../../services/Course';
import { CourseCardContainer } from './style';
import ThumbDefault from '../../assets/thumb-default.png';
import { useHistory } from 'react-router-dom';
import { BsBookmark, BsBookmarkFill } from 'react-icons/bs';
import Course from '../../services/models/course';
import { toast } from 'react-toastify';
import getErrorMessage from '../../helpers/get-error-message';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';

interface CourseCardProps {
  course: Course;
  onAddToList:
    | ((courseId: string, addedToList: boolean) => void)
    | (() => Promise<void>);
}

const CourseCard: React.FC<CourseCardProps> = ({ course, onAddToList }) => {
  const history = useHistory();
  const [thumb, setThumb] = useState(course.thumbnail);

  const addToList = async () => {
    const shouldAddToList = !course.book_mark;
    try {
      await addCourseToList(course.id, shouldAddToList);
      onAddToList(course.id, shouldAddToList);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      toast.error(
        'Erro ao ' + shouldAddToList
          ? 'Adicionar'
          : 'Remover' + ' curso ' + shouldAddToList
          ? 'aos'
          : 'dos' + ' favoritos. ' + errorMessage,
      );
    }
  };

  const goToCourse = () => {
    history.push(`/curso/${course.id}`);
  };

  const onErrorThumb = () => {
    setThumb(ThumbDefault);
  };

  const getCourseDuration = (): string => {
    let miliseconds = 0;

    if (course && course.contents && course.contents.length) {
      miliseconds = course.contents
        .map(c => +c.duration)
        .reduce((a, b) => a + b);
    }

    const hours = Math.floor(miliseconds / 3600);
    const minutes = Math.floor((miliseconds % 3600) / 60);
    const seconds = Math.floor((miliseconds % 3600) % 60);

    let timeText = '';

    if (hours > 0) {
      timeText += `${hours} h, `;
    }

    if (minutes > 0) {
      timeText += `${minutes} min`;
    }

    return timeText;
  };

  return (
    <CourseCardContainer onClick={goToCourse}>
      <div className="course-image-container">
        <img
          src={thumb || ThumbDefault}
          alt="Curso"
          className="course-image"
          onError={onErrorThumb}
          
        />
        <span className="course-duration">{getCourseDuration()}</span>
      </div>

      <div className="lower-content">
        <div className="course-classification-and-added-to-list">
          <span
            onClick={e => e.stopPropagation()}
            className="course-classification"
          >
            {Array(5)
              .fill('')
              .map((s, index) =>
                index + 1 <= (course.course_rating || 0) ? (
                  <AiFillStar key={index} color="#FCB139" />
                ) : (
                  <AiOutlineStar key={index} color="#FCB139" />
                ),
              )}
          </span>

          <button
            onClick={e => {
              e.stopPropagation();
              addToList();
            }}
            className="add-to-list"
          >
            {course.book_mark ? (
              <BsBookmarkFill color="#009ee0" size={25} />
            ) : (
              <BsBookmark color="#009ee0" size={25} />
            )}
          </button>
        </div>

        <h3 className="course-title">{course.title}</h3>

        <ProgressBar progress={(course.course_user_progress || 0) * 100} />
      </div>
    </CourseCardContainer>
  );
};

export default CourseCard;
