import styled from 'styled-components';

const BookmarkContainer = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 10px 15px;
  font-size: var(--font-small);
  border-radius: 5px;
  transition: all 0.3s;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  & > :first-child {
    margin-right: 5px;
  }

  &.added {
    background: var(--primary-blue);
    
    span {
      color: white;
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export { BookmarkContainer };
