import convertLive from '../../helpers/converters/convert-live';
import httpClient from '../../http-client';
import LiveFromResponse from '../models/from-api-result/live';
import Live from '../models/live';

const getLives = async (filters?: object): Promise<Live[]> => {
  const lives = (
    await httpClient.get<LiveFromResponse[]>('/lives', { params: filters })
  ).data;

  let convertedLives = [] as Live[];
  if (lives && lives.length) {
    convertedLives = lives.map(convertLive);
  }

  return convertedLives;
};

const getActiveLive = async (): Promise<Live[]> => {
  const lives = (await httpClient.get<LiveFromResponse[]>('/lives/in-live'))
    .data;

  let convertedLive = [] as Live[];
  if (lives) {
    convertedLive = lives.map(convertLive);
  }

  return convertedLive;
};

export { getLives, getActiveLive };
