import styled from 'styled-components';

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 35px;
  box-shadow: 1px 1px 20px -4px rgba(118, 126, 173, 0.47);

  .menu-hamburguer {
    display: none;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    padding: 5px;

    @media (max-width: 1000px) {
      display: block;
    }
  }

  .hamburguer-content {
    width: calc(100% - 350px);
    min-width: 950px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 30px;
    }

    @media (max-width: 1000px) {
      min-width: 100%;
      flex-direction: column;

      &.show {
        display: flex;
      }

      &.hide {
        display: none;
      }
    }
  }

  & > *:not(:last-child) {
    @media (max-width: 1300px) {
      margin-bottom: 15px;
    }
  }

  .logos {
    margin-right: 20px;

    img {
      width: 140px;

      &:not(:first-child) {
        padding-left: 10px;
      }
    }
  }
`;
