import styled from 'styled-components';

const ContactContainer = styled.div`
  padding: 45px 60px;
`;

const NavContent = styled.div`
  border-bottom: solid 5px #009ee0;
  padding-bottom: 20px;

  margin-bottom: 40px;

  @media (min-width: 847px) {
    display: flex;
    justify-content: space-between;
  }
`;

const ContactTitle = styled.h2`
  color: #009ee0;

  font-size: var(--font-larger);
  font-weight: bold;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 0 20px;

  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
  }

  & label {
    font-weight: bold;

    &.required {
      &::after {
        content: '*';
        color: red;
      }
    }
  }

  & input {
    padding: 10px 5px;
    border: solid 1px gainsboro;
    outline: none;

    &:focus {
      border: solid 1px #009ee0;
    }
  }

  & button {
    background: rgb(38, 114, 236);
    color: white;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 10px 15px;
    opacity: 0.9;
    transition: all 0.3s;

    &:hover {
      opacity: 1;
    }
  }
`;

export { ContactContainer, ContactTitle, NavContent, ContactForm };
