import React, { useEffect, useState } from 'react';
import { getMyAnnotations } from '../../services/Annotations';
import Annotation from '../../services/models/annotations';
import AnnotationCourseCard from './components/AnnotationCourseCard';
import { MyAnnotationsContainer } from './style';
import MyAnnotationsContext from './MyAnnotationsContext';

export interface AnnotationLesson {
  content_id: number;
  title: string;
  annotations: Annotation[];
}

export interface AnnotationCourse {
  course_id: string;
  course_title: string;
  contents: AnnotationLesson[];
}

const MyAnnotations: React.FC = () => {
  const [annotationCourses, setAnnotationCourses] = useState<
    AnnotationCourse[]
  >([]);

  const getAnnotations = async () => {
    setAnnotationCourses([]);
    const annotationCoursesItems = await getMyAnnotations();
    setAnnotationCourses(annotationCoursesItems);
  };

  useEffect(() => {
    getAnnotations();
  }, []);

  return (
    <MyAnnotationsContext.Provider
      value={{ getAllAnnotations: getAnnotations }}
    >
      <MyAnnotationsContainer>
        <h3 className="my-annotations-title">ANOTAÇÕES</h3>

        <ul className="my-annotations-content">
          {annotationCourses && annotationCourses.length ? (
            annotationCourses.map((annotationCourse, index) => (
              <li key={index}>
                <AnnotationCourseCard
                  key={annotationCourse.course_id}
                  annotationCourse={annotationCourse}
                />
              </li>
            ))
          ) : (
            <h4>Não foram encontradas anotações!</h4>
          )}
        </ul>
      </MyAnnotationsContainer>
    </MyAnnotationsContext.Provider>
  );
};

export default MyAnnotations;
