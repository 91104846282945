import React from 'react';
import MenuItem from './components/MenuItem';
import { MenuContainer } from './style';

const Menu: React.FC = () => {
  return (
    <MenuContainer>
      <MenuItem to="/disciplinas">Disciplinas</MenuItem>

      <MenuItem to="/cursos">Cursos</MenuItem>

      <MenuItem to="/live">Live</MenuItem>

      <MenuItem to="/biblioteca">Biblioteca</MenuItem>

      <MenuItem to="/forum">Fórum</MenuItem>

      <MenuItem to="/faq">FAQ</MenuItem>
    </MenuContainer>
  );
};

export default Menu;
