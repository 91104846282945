import styled from 'styled-components';

export const CourseReviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
`;

export const CourseReviewCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 160px 240px;

  @media (max-width: 850px) {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  & > p {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    margin: 0 4px;
  }

  padding: 8px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);

  > .user {
    > span {
      width: 180px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  > .description {
    > span {
      > .show-button {
        margin-left: 8px;
        display: inline;
        font-weight: 700;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

export const ShowAllButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  background: var(--primary-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
  margin: 8px 0;
`;
