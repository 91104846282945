const secondsToTimeText = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);

  if (minutes >= 60) {
    const hours = Math.floor(minutes / 60);

    return `~ ${hours} hora${hours > 1 ? 's' : ''}`;
  } else {
    return `~ ${minutes} minuto${minutes > 1 ? 's' : ''}`;
  }
}

export {
  secondsToTimeText
}
