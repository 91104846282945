import React, { useState } from 'react';
import CardDocs from './CardDocs';
import {
  getAllLibraryItems as getAllLibraryItemsService,
} from '../../../../services/Library/index';
import { CardContainer, NavContainer } from './styles';
import GoBack from '../../../../components/GoBack';
import Search from '../../../../components/Search';
import Pagination from '../../../../components/Pagination';

const LibraryDocs: React.FC = () => {
  const [docs, setDocsItems] = useState<[]>([] as any);
  const getExtraMaterials = async (filters?: Object) => {
    let localFilters: any = {
      type: 'file'
    }

    if (filters) {
      localFilters = { ...filters, ...localFilters };
    }

    const extraMaterials = await getAllLibraryItemsService(localFilters);
    return extraMaterials;
  };

  return (
    <CardContainer>
      <GoBack path="/biblioteca" />
      <NavContainer>
        <h2 className="library-title">Todos os materiais de apoio</h2>
        <Search
          typeSearch="content-file"
          callback
          updateList={setDocsItems}
          clear={getExtraMaterials}
        />
      </NavContainer>
      <div className="list-cards">
        {docs &&
          docs.map((doc, index) => {
            return <CardDocs doc={doc} key={index} download />;
          })}
      </div>
      <Pagination
        availableItems={docs}
        setAvailableItems={setDocsItems}
        getAllItems={filters => getExtraMaterials(filters)}
      />
    </CardContainer>
  );
};

export default LibraryDocs;
