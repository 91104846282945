import styled from 'styled-components';

export const CardContainer = styled.div`
  width: calc(100% / 4 - 30px);
  min-height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 10px;
  position: relative;
  margin: 15px;
  box-shadow: 1px 1px 1px 1px rgba(0,0,0, .1);
  transition: transform 250ms ease;
  
  @media(max-width: 1200px){
    width: calc(100% / 3 - 30px);
  }

  @media(max-width: 992px){
    width: calc(100% / 2 - 30px);
  }

  @media(max-width: 768px) {
    width: calc(100% / 1 - 40px);
  }

  &:hover {
    transform: translateY(-5px);
  }

  .discipline-image-container {
    min-height: 270px;
    height: 100%;
    width: 100%;
    position: relative;
    cursor: pointer;

    img {
      width: 100%;
      object-fit: cover;

      @media(max-width: 1200px){
        height: 270px;
      }
      
      @media(max-width: 992px){
        height: 290px;
      }
      
      @media(max-width: 768px) {
        height: 270px;
      }

      @media(min-width: 1200px){
        height: 270px;
      }
    }

    .discipline-duration {
      position: absolute;
      bottom: 5px;
      right: 20px;
      color: white;
    }
  }

  .discipline-infos{
    height: 100%;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    .video-type {
      color: #009ee0;
      text-decoration: none;
      font-size: 10px;
      font-weight: bold;
    }
  
    .discipline-title {
      color: #333;
      margin-top: 10px;
      font-size: 18px;
    }
  }

`;
