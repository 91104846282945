import Discipline from '../models/discipline';
import httpClient from '../../http-client/index';
import convertToDiscipline from '../../helpers/converters/convert-discipline';
import DisciplineFromResponse from '../models/from-api-result/discipline';
import CourseFromResponse from '../models/from-api-result/course';
import Course from '../models/course';
import convertToCourse from '../../helpers/converters/convert-course';
import CertificateFromResponse from '../models/from-api-result/certificate';

const getCertificates = async () => {
  const certificates = (
    await httpClient.get<CertificateFromResponse[]>('/certificates')
  ).data;

  return certificates;
};

const getFavoriteDisciplines = async () => {
  const disciplines = (
    await httpClient.get<DisciplineFromResponse[]>('/trails', {
      params: { is_book_mark: true },
    })
  ).data;

  let convertedDisciplines = [] as Discipline[];
  if (disciplines && disciplines.length) {
    convertedDisciplines = disciplines.map(dis => convertToDiscipline(dis));
  }

  return convertedDisciplines;
};

const getFavoriteCourses = async () => {
  const courses = (
    await httpClient.get<CourseFromResponse[]>('/courses', {
      params: { is_active: true, is_bookmark: true },
    })
  ).data;

  let convertedCourses = [] as Course[];
  if (courses && courses.length) {
    convertedCourses = courses.map(course => convertToCourse(course));
  }

  return convertedCourses;
};

const getOngoingDisciplines = async () => {
  const disciplines = (
    await httpClient.get<DisciplineFromResponse[]>('/trails', {
      params: { is_active: true, is_finished: false },
    })
  ).data;

  let convertedDisciplines = [] as Discipline[];
  if (disciplines && disciplines.length) {
    convertedDisciplines = disciplines.map(dis => convertToDiscipline(dis));
  }

  return convertedDisciplines;
};

const getOngoingCourses = async () => {
  const courses = (
    await httpClient.get<CourseFromResponse[]>('/courses', {
      params: { is_active: true, is_finished: false, is_single: true },
    })
  ).data;

  let convertedCourses = [] as Course[];
  if (courses && courses.length) {
    convertedCourses = courses.map(course => convertToCourse(course));
  }

  return convertedCourses;
};

const getProgress = async (trails_id: any) => {
  const progress = (await httpClient.get(`/trail-users/progress/${trails_id}`))
    .data;
  return progress;
};

export {
  getCertificates,
  getFavoriteDisciplines,
  getOngoingDisciplines,
  getProgress,
  getFavoriteCourses,
  getOngoingCourses,
};
