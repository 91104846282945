import React, { useContext, useState } from 'react';
import { MyDataContainer } from './style';
import { AiOutlineCamera } from 'react-icons/ai';
import User from '../../services/models/user';
import { updateUserImage } from '../../services/User';
import { hideModal, showModal } from '../../components/Modal';
import CutImage from '../../components/CutImage';
import { uploadFile } from '../../services/Files';
import getErrorMessage from '../../helpers/get-error-message';
import Swal from 'sweetalert2';
import UserContext from '../../UserContext';

export interface UserData {
  id: number;
  imageUrl: string;
  name: string;
  occupation: string;
  email: string;
  phone: string;
}

const MyData: React.FC = () => {
  const { user, setUser } = useContext(UserContext);
  const [actualTab, setActualTab] = useState('my-data');
  const [isEntering, setIsEntering] = useState(false);

  const changeToTab = (tabName: string) => {
    setActualTab(tabName);
    setIsEntering(true);

    setTimeout(() => {
      setIsEntering(false);
    }, 300);
  };

  const changeImage = async (event: React.MouseEvent) => {
    showModal('Selecionar Imagem', <CutImage onCutImage={changeUserImage} />);
  };

  const changeUserImage = async (image: File) => {
    if (image) {
      const formData = new FormData();
      formData.append('file', image);

      try {
        const { reference } = await uploadFile(formData);
        updateUserImage(reference);
        setUser!({ ...user!, avatar: reference });
        hideModal();
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        Swal.fire({
          icon: 'error',
          text: 'Houve um erro ao mudar a imagem do usuário. ' + errorMessage,
        });
      }
    }
  };

  return (
    <MyDataContainer>
      <h2 className="my-data-title">MEUS DADOS</h2>

      <div
        className={`
        my-data-content
        ${isEntering ? 'entering' : ''}
      `}
      >
        <div className="my-data-content-tabs-links">
          <button
            className={actualTab === 'my-data' ? 'active' : ''}
            onClick={() => {
              changeToTab('my-data');
            }}
          >
            Meus dados
          </button>
        </div>

        <div className="my-data-content-tabs-contents">
          <>
            {(() => {
              switch (actualTab) {
                case 'my-data':
                  return (
                    <div className="user-data-container">
                      <div className="user-image-container">
                        <img
                          src={
                            user?.avatar ||
                            `${process.env.PUBLIC_URL}/default-user-image.png`
                          }
                          alt="Usuário"
                          className="user-image"
                        />

                        <button
                          onClick={changeImage}
                          className="change-image-button"
                        >
                          <span className="change-image-icon-container">
                            <AiOutlineCamera size={20} color="#FFF" />
                          </span>
                        </button>
                      </div>

                      <div className="user-name-and-occupation">
                        <p className="user-name">{user?.name}</p>

                        <p className="user-occupation">{user?.occupation}</p>
                      </div>

                      <div className="user-data">
                        <h3 className="user-data-title">Dados</h3>

                        <form className="user-data-form">
                          <div>
                            <label htmlFor="name">Nome</label>
                            <input
                              type="text"
                              readOnly
                              className="user-name-input"
                              defaultValue={user?.name}
                            />
                          </div>
                          <div>
                            <label htmlFor="email">Email</label>
                            <input
                              type="email"
                              readOnly
                              className="user-name-email"
                              defaultValue={user?.email}
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  );
              }
            })()}
          </>
        </div>
      </div>
    </MyDataContainer>
  );
};

export default MyData;
