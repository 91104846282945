import styled from 'styled-components';

export const FAQItemCardContainer = styled.div`
  background: #099EE0;
  width: 100%;
  padding: 20px 30px;

  .faq-item-question,
  .faq-item-answer {
    color: white;
  }

  .faq-item-question {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .faq-item-answer {
    font-size: 14px;
    line-height: 20px;
  }
`; 