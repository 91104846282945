import React, { useContext, useState } from 'react';
import { UserProfileDropdownContainer } from './style';
import { Link } from 'react-router-dom';
import UserContext from '../../../../UserContext';

const UserProfileDropdown: React.FC = () => {
  const { user } = useContext(UserContext);
  const [shouldDisplayContent, setShouldDisplayContent] = useState(false);

  const logout = () => {
    window.localStorage.removeItem('vallourec-api-token');
    window.location.href = `${process.env.REACT_APP_REDIRECT_LOGOUT}`;
  };

  const redirectToAdmin = () => {
    const adminUrl = process.env.REACT_APP_ADMIN_URL;
    const apiToken = window.localStorage.getItem('vallourec-api-token')!;
    window.location.href = `${adminUrl}?token=${apiToken}`;
  };

  return (
    <UserProfileDropdownContainer>
      <button
        className="user-profile-dropdown"
        onMouseOver={() => setShouldDisplayContent(true)}
        onMouseOut={() => setShouldDisplayContent(false)}
        onClick={() => setShouldDisplayContent(!shouldDisplayContent)}
      >
        <img
          className="user-image"
          src={
            user?.avatar || `${process.env.PUBLIC_URL}/default-user-image.png`
          }
          alt="usuário"
        />

        <div className="greetings">
          <span>
            Bem vindo(a), <strong title={user?.name}>{user?.name}</strong>
          </span>
        </div>
      </button>
      <div
        className={`
          user-profile-dropdown-content
          ${shouldDisplayContent ? 'visible' : ''}
        `}
        onMouseOver={() => setShouldDisplayContent(true)}
        onMouseOut={() => setShouldDisplayContent(false)}
      >
        <Link to="/perfil">Meus dados</Link>
        <Link to="/meus-cursos">Meu progresso</Link>
        <Link to="/anotacoes">Minhas anotações</Link>
        {user && user.is_admin && (
          <button onClick={redirectToAdmin}>Painel do Administrador</button>
        )}
        <button onClick={logout}>Sair</button>
      </div>
    </UserProfileDropdownContainer>
  );
};

export default UserProfileDropdown;
