import React, { useCallback, useEffect, useState } from 'react';
import {
  EvaluationContainer,
  EvaluationContent,
  Title,
  BeforeInitExam,
} from './style';
import EvaluationList from './components/EvaluationList';
import { useHistory, useParams } from 'react-router-dom';
import GoBack from '../../components/GoBack';
import {
  getExam as getExamService,
  getExamUser,
  startExam,
} from '../../services/Exam';
import Exam from '../../services/models/exam';
import Swal from 'sweetalert2';
import getErrorMessage from '../../helpers/get-error-message';

interface ExtendedWindow extends Window {
  hasStartedExam: boolean;
}

declare const window: ExtendedWindow;

interface ExamParams {
  examId: string;
  courseId: string;
}

export interface Register {
  exam_answer_user_id: string;
  question_id: string;
  answer_id?: string;
  date?: Date;
}

const Evaluation: React.FC = () => {
  const history = useHistory();

  const { courseId, examId } = useParams<ExamParams>();
  const [exam, setExam] = useState({} as Exam);
  const [examUserId, setExamUserId] = useState('');
  const [userAttempts, setUserAttempts] = useState(
    undefined as number | undefined,
  );

  const getExam = useCallback(async () => {
    const localExam = await getExamService(examId);
    if (localExam && Object.keys(localExam).length) {
      setExam(localExam);
    }
  }, [examId]);

  useEffect(() => {
    (async () => {
      if (examId) {
        getExam();
      }

      let localNumberOfAttempts = 0;
      if (!examUserId) {
        const examUsers = await getExamUser(examId, courseId);
        localNumberOfAttempts = examUsers.length;

        const notFinalizedExamUsers = examUsers.filter(
          examU => !examU.final_date,
        );
        if (notFinalizedExamUsers && notFinalizedExamUsers.length) {
          setExamUserId(notFinalizedExamUsers[0].exam_user_id);
        } else if (!window.hasStartedExam) {
          try {
            window.hasStartedExam = true;
            const startedExamData = await startExam(examId, courseId);
            if (startedExamData && startedExamData.exam_user_id) {
              localNumberOfAttempts++;
              setExamUserId(startedExamData.exam_user_id);
            }
          } catch (error) {
            const errorMessage = getErrorMessage(error);
            window.hasStartedExam = false;
            Swal.fire({
              title: 'Erro',
              text: 'Erro ao iniciar prova. ' + errorMessage,
              icon: 'error',
            }).then(() => history.go(-1));
          }
        }

        setUserAttempts(localNumberOfAttempts);
      }
    })();
  }, [examId, getExam, courseId, examUserId, exam.attempts]);

  useEffect(() => {
    window.hasStartedExam = false;
  }, []);

  useEffect(() => {
    if (exam.attempts && userAttempts !== undefined) {
      Swal.fire({
        title: `Tentativa ${userAttempts}/${exam.attempts}`,
        icon: 'warning',
        html: `<p>Não será possível mudar a resposta após clicar em responder.</p>`,
      });
    }
  }, [exam.attempts, userAttempts]);

  return (
    <EvaluationContainer>
      <EvaluationContent>
        <GoBack path="/home" />
        {exam && exam.enabled && (
          <>
            <Title>{exam.title}</Title>
            <BeforeInitExam>
              <div className="instructions">
                <span>{exam.instructions}</span>
                {exam && exam.average && (
                  <div className="average">
                    <h4>Média exigida:</h4>
                    <span>{(+exam.average).toFixed(2) || 0}%</span>
                  </div>
                )}
                <div className="execution">
                  {exam.limitTime > 0 ? (
                    <>
                      <h4>Tempo de execução:</h4>
                      <span>{exam.limitTime}</span>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </BeforeInitExam>
            <EvaluationList courseId={courseId} examUserId={examUserId} />
          </>
        )}
      </EvaluationContent>
    </EvaluationContainer>
  );
};

export default Evaluation;
