import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
  ForumContainer,
  Content,
  LeftDiv,
  Main,
  Select,
  UserForum,
  LiUser,
  DivGeral,
  UserInfo,
  QuestionGroup,
  QuestionGroupTitle,
} from './style';
import { BsChatSquare } from 'react-icons/bs';
import Pagination from '../../components/Pagination';
import { formatDate, getQuestions } from '../../services/Forum';
import { getForumCategories } from '../../services/ForumCategories';
import ForumCategory from '../../services/models/forum-category';
import ForumQuestionFromResponse from '../../services/models/from-api-result/forum-question';

interface CategoryWithQuestions {
  categoryId: string;
  questions: ForumQuestionFromResponse[];
}

const UserQuestion = ({ post }: any) => {
  const formatTags = (tags: { description: string }[]) => {
    if (tags && tags.length) {
      return tags.map(t => (t ? t.description : '')).join(', ');
    }

    return '';
  };

  return (
    <Link to={`/answers/${post.question_id}`}>
      <LiUser>
        <UserInfo>
          <div className="user-name">
            <h3>{post.title}</h3>
            <h3 className="user-inicio">
              {`${post.user.name} iniciado em - ${formatDate(post.created_at)}`}
            </h3>
          </div>
        </UserInfo>
        <DivGeral>
          <h2 className="status-topic">{formatTags(post.tags)}</h2>
          <div className="answers">
            <BsChatSquare />
            <span>{post.total_answers || 0}</span>
          </div>
        </DivGeral>
      </LiUser>
    </Link>
  );
};

const ForumHome: React.FC = () => {
  const history = useHistory();
  const [categoriesWithQuestions, setCategoriesWithQuestions] = useState<
    CategoryWithQuestions[]
  >([]);
  const [selectedCategory, setSelectedCategory] = useState(
    undefined as string | undefined,
  );
  const [categories, setcategories] = useState([] as ForumCategory[]);
  const [questions, setQuestions] = useState([] as ForumQuestionFromResponse[]);


  const getForum = async (filters?: object) => {
    const localQuestions = await getQuestions(selectedCategory, filters);

    setQuestions(localQuestions);
    return localQuestions;
  };

  const getForumForPagination = async (filters?: object) => {
    const localQuestions = await getQuestions(selectedCategory, filters);

    return localQuestions;
  };

  const getCategoriesWithQuestions = () => {
    categoriesWithQuestions.splice(0);
    for (let question of questions) {
      const categoriesFromQuestion = question.categories;
      for (let category of categoriesFromQuestion) {
        if (
          !categoriesWithQuestions
            .map(cwq => cwq.categoryId)
            .includes(category.category_id)
        ) {
          const questionsOfThisCategory = questions.filter(q =>
            q.categories.map(q => q.category_id).includes(category.category_id),
          );
          if (questionsOfThisCategory && questionsOfThisCategory.length) {
            categoriesWithQuestions.push({
              categoryId: category.category_id,
              questions: questionsOfThisCategory,
            });
          }
        }
      }
    }

    setCategoriesWithQuestions([...categoriesWithQuestions]);
  };

  const getCategoryName = (categoryId: string) => {
    const category = categories.find(ctg => ctg.id === categoryId) || {
      description: '',
    };
    return category.description;
  };

  const getCategories = useCallback(async () => {
    const categList = await getForumCategories();

    setcategories(categList);
  }, []);

  const newDiscursion = useCallback(() => {
    history.push('/forum-new');
  }, []);

  const handleFilter = async (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(event.target.value);
  };

  useEffect(() => {
    getCategoriesWithQuestions();
  }, [questions]);

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory !== undefined) {
      getForum();
    }
  }, [selectedCategory]);

  return (
    <ForumContainer>
      <h3 className="forum-title">Fórum</h3>
      <Content>
        <Main>
          <div className="top-group">
            <LeftDiv>
              <button className="button-discursion" onClick={newDiscursion}>
                Iniciar uma discussão
              </button>
            </LeftDiv>
            <Select
              onChange={handleFilter}
              name="select"
              defaultValue="recents"
            >
              <option value="">Selecione uma categoria</option>
              {categories &&
                categories.length > 0 &&
                categories.map(categ => (
                  <option key={categ.id} value={categ.id}>
                    {categ.description}
                  </option>
                ))}
            </Select>
          </div>
          <section>
            <ul>
              {categoriesWithQuestions && categoriesWithQuestions.length ? (
                categoriesWithQuestions.map(cwq => (
                  <QuestionGroup key={cwq.categoryId}>
                    <QuestionGroupTitle>
                      {getCategoryName(cwq.categoryId)}
                    </QuestionGroupTitle>
                    {cwq.questions.map(
                      (post: ForumQuestionFromResponse, index: number) => (
                        <UserForum key={index}>
                          <UserQuestion post={post} />
                        </UserForum>
                      ),
                    )}
                  </QuestionGroup>
                ))
              ) : (
                <h1>Não existe nenhuma discussão disponível!</h1>
              )}
            </ul>
          </section>
        </Main>
      </Content>
      {!selectedCategory && (
        <Pagination
          getAllItems={filters => getForumForPagination(filters)}
          availableItems={questions}
          setAvailableItems={setQuestions}
        />
      )}
    </ForumContainer>
  );
};

export default ForumHome;
