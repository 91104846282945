import styled from 'styled-components';

export const FooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  font-size: 12px;
  font-weight: bold;
  height: 80px;

  & > *:not(:last-child) {
    margin-right: 20px;
  }

  .logo-footer {
    width: 150px;
    height: 35px;

    @media (min-width: 550px) {
      margin: 0;
    }

    @media (max-width: 550px) {
      margin-bottom: 15px;
    }
  }

  .spacer {
    height: 50px;
    border-left: solid 1px #C0C0C0;
    margin: 0 40px;

    @media (max-width: 550px) {
      display: none;
    }
  }

  .contact {
    text-decoration: none;
  }
`;