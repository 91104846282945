import React, { useState } from 'react';
import { HeaderContainer } from './style';
import { Link } from 'react-router-dom';
import Menu from './components/Menu';
import UserProfileDropdown from './components/UserProfileDropdown';
import { GiHamburgerMenu } from 'react-icons/gi';

const Header: React.FC = () => {
  const [
    shouldShowHamburguerContent,
    setShouldShowHamburguerContent,
  ] = useState(false);

  return (
    <HeaderContainer>
      <div className="logos">
        <Link to="/home">
          <img
            src={`${process.env.PUBLIC_URL}/logo-vallourec.jpg`}
            alt="Logo Vallourec"
          />
          <img
            src={`${process.env.PUBLIC_URL}/logo-universidade.svg`}
            alt="Logo Universidade"
          />
        </Link>
      </div>

      <button
        onClick={() =>
          setShouldShowHamburguerContent(!shouldShowHamburguerContent)
        }
        className="menu-hamburguer"
      >
        <GiHamburgerMenu size={20} color="#009ee0" />
      </button>

      <div
        className={`
        hamburguer-content
        ${shouldShowHamburguerContent ? 'show' : 'hide'}
      `}
      >
        <Menu />

        <UserProfileDropdown />
      </div>
    </HeaderContainer>
  );
};

export default Header;
