import { checkIfDisciplineContentsAreAvailable } from '../../helpers/check-contents-available';
import convertToDiscipline from '../../helpers/converters/convert-discipline';
import httpClient from '../../http-client';
import Discipline from '../models/discipline';
import DisciplineFromResponse from '../models/from-api-result/discipline';
import Annotation from '../models/annotations';

const getDiscipline = async (disciplineId: string) => {
  const discipline = (await httpClient.get(`/trails/${disciplineId}`)).data;

  let convertedDiscipline = {} as Discipline;
  convertedDiscipline = convertToDiscipline(discipline, discipline.user);

  if (convertedDiscipline && Object.keys(convertedDiscipline).length) {
    convertedDiscipline =
      checkIfDisciplineContentsAreAvailable(convertedDiscipline);

    if (convertedDiscipline.courses && convertedDiscipline.courses.length) {
      const allContents = convertedDiscipline.courses
        .map(course => course.contents)
        .flat();
      if (allContents && allContents.length) {
        const numberOfContents = allContents.length;
        const numberOfFinishedContents = allContents.filter(
          content => content.alreadyFinished,
        ).length;

        if (numberOfContents && numberOfFinishedContents) {
          convertedDiscipline.progress = Math.floor(
            (numberOfFinishedContents / numberOfContents) * 100,
          );
        }
      }
    }
  }

  return convertedDiscipline;
};

const getAllDisciplines = async (filters?: object) => {
  const disciplines = (
    await httpClient.get<DisciplineFromResponse[]>('trails', {
      params: filters,
    })
  ).data;

  let convertedDisciplines = [] as Discipline[];
  if (disciplines && disciplines.length) {
    convertedDisciplines = disciplines.map(discipline =>
      convertToDiscipline(discipline),
    );
  }

  return convertedDisciplines;
};

const searchDisciplines = async (
  value: string,
  callback: (data: any) => void,
) => {
  const disciplines = (await httpClient.get(`trails?search=${value}`)).data;

  const disciplineResults = Array<any>();
  disciplines.map((discipline: any) =>
    disciplineResults.push({
      name: discipline.name,
      description: discipline.description,
      link: `disciplina/${discipline.trail_id}`,
    }),
  );

  callback(disciplineResults);
};

const getHomeAvailableDisciplines = async (): Promise<Discipline[]> => {
  const homeAvailableDisciplines = (
    await httpClient.get<DisciplineFromResponse[]>('trails', {
      params: { limit: 3 },
    })
  ).data;

  let convertedDisciplines = [] as Discipline[];
  if (homeAvailableDisciplines && homeAvailableDisciplines.length) {
    convertedDisciplines = homeAvailableDisciplines
      .splice(0, 3)
      .map(discipline => convertToDiscipline(discipline));
  }

  return convertedDisciplines;
};

const startDiscipline = async (disciplineId: string) => {
  await httpClient.post(`/trail-users`, {
    trail_id: disciplineId,
  });
};

const startCourse = async (courseId: string) => {
  await httpClient.post(`/course-users/${courseId}/start`);
};

const updateContentProgress = async (
  moduleId: string,
  lessonId: string,
  content_view: number | null,
) => {
  if (!moduleId || !lessonId || (content_view || 0) <= 0) {
    return {};
  }

  return (
    await httpClient.patch(
      `/course-content-users/${moduleId}/content-view/${lessonId}`,
      {
        content_view,
      },
    )
  ).data;
};


const updatePreviewProgress = async (
  disciplineId: string,
  courseId: string,
  contentId: string,
  content_view: number | null,
) => {
  return (
    await httpClient.patch(`/trail-course-content-users/content-view`, {
      trail_id: disciplineId,
      course_id: courseId,
      content_id: contentId,
      content_view: content_view,
    })
  ).data;
};


const addDisciplineToList = async (
  disciplineId: string,
  addToList: boolean,
) => {
  if (addToList) {
    await httpClient.post('/book-marks/trail', { trail_id: disciplineId });
  } else {
    await httpClient.delete(`/book-marks/trail/${disciplineId}`);
  }
};

const startPreview = async (disciplineId: string) => {
  return (
    await httpClient.post(`/trail-course-content-users`, {
      trail_id: disciplineId,
      content_id: null,
      course_id: null,
    })
  ).data;
};



const toogleBookmark = async (courseId: string, added: boolean) => {
  if (!added) {
    await httpClient.post('/book-marks', { trail_id: courseId });
  } else {
    await httpClient.delete(`/book-marks/${courseId}`);
  }
};

const startContent = async (courseId: string, contentId: string) => {
  if (!courseId || !contentId) {
    return;
  }

  await httpClient.post(`/course-content-users/${courseId}/start`, {
    content_id: contentId,
  });
};

const finishDiscipline = async (disciplineId: string) => {
  await httpClient.put(`/trail-users/finish`, {
    trail_id: disciplineId,
  });
};

const finishCourse = async (courseId: string) => {
   await httpClient.patch(`/course-users/${courseId}/finish`);
};

const finishContent = async (courseId: string, contentId: string) => {
  if (!courseId || !contentId) {
    return;
  }

  await httpClient.patch(`/course-content-users/${courseId}/finish`, {
    content_id: contentId,
  });
};

const finishPreview = async (courseId: string) => {
  return (
    await httpClient.put(`/trail-course-content-users/finish`, {
      trail_id: courseId,
      course_id: null,
      content_id: null,
    })
  ).data;
};

export {
  getAllDisciplines,
  getHomeAvailableDisciplines,
  getDiscipline,
  startDiscipline,
  startCourse,
  finishDiscipline,
  finishCourse,
  searchDisciplines,
  updateContentProgress,
  addDisciplineToList,
  startPreview,
  updatePreviewProgress,
  toogleBookmark,
  startContent,
  finishContent,
  finishPreview
};
