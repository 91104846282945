import React from 'react';
import {UserDescriptionsBox } from './styles';

interface IUserDescriptions {
  name: string;
  points: number;
}

const UserDescriptions: React.FC<IUserDescriptions> = ({ name, points }) => {
  return (
    <UserDescriptionsBox>
      <h3>{name}</h3>
      <span>{`${points}.000`}</span>
    </UserDescriptionsBox>
  );
}

export default UserDescriptions;
