import styled from 'styled-components';

export const DisciplineCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-bottom: 10px;
  position: relative;
  box-shadow: 10px 10px 20px 10px rgba(0, 0, 0, 0.1);
  &:hover {
    transform: translateY(-5px);
    transition: 0.3s;
  }

  div.upper-content {
    width: 100%;
    .discipline-image-container {
      height: 200px;
      width: 100%;
      position: relative;
      cursor: pointer;

      @media (min-width: 537px) {
        height: 284px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .discipline-duration {
        position: absolute;
        bottom: 5px;
        right: 20px;
        color: white;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
          1px 1px 0 #000;
      }
    }
  }

  div.lower-content {
    padding: 15px;
    width: 100%;

    .discipline-infos {
      display: flex;
      justify-content: space-between;

      & svg {
        color: rgb(0, 158, 224);
      }
    }

    .discipline-category {
      color: #009ee0;
      text-decoration: underline;
      font-size: 12px;
    }

    .discipline-title {
      color: #333;
      margin-bottom: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .download-certificate {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 3px 5px;
      font-size: 12px;
      font-weight: 600;
      color: #333;
      background: #efedf3;
      cursor: pointer;
    }
  }
`;

export const DisciplineBookMarkContainer = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 3px;
`;
