import React, { useEffect, useState } from 'react';
import { ILibraryItem } from '../LibraryVideos/index';
import PlayerSambaVideos from '../../../../components/PlayerSambaVideos';
import GoBack from '../../../../components/GoBack';
import { getLIbraryItem as getLIbraryItemService } from '../../../../services/Library/index';
import { PageContainer, Content, Frame, Title, Description } from './style';
import { useParams } from 'react-router';

export interface PlayerEventListener {
  event: string;
}

const PageVideo: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [videoInfos, setVideoInfos] = useState<ILibraryItem>();
  const [contentUrl, setContentUrl] = useState('');
  const [shouldShowPlayer, setShouldShowPlayer] = useState(false);

  useEffect(() => {
    (async () => {
      const localItems: ILibraryItem = await getLIbraryItemService(id);
      setVideoInfos(localItems);
      setContentUrl(localItems.reference);
    })();
  }, [id]);

  useEffect(() => {
    setShouldShowPlayer(false);
    process.nextTick(() => {
      setShouldShowPlayer(true);
    });
  }, [contentUrl]);

  const getControlsToEnable = () => {
    const controls = [
      'play',
      'pause',
      'quality',
      'fullscreen',
      'time',
      'volume',
      'backward',
      'speed'
    ];

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      controls.push('seekbar');
    }
    return controls;
  };

  return (
    <PageContainer>
      <Content>
        <GoBack path="/biblioteca-videos" />
        {videoInfos ? (
          <>
            {shouldShowPlayer && (
              <PlayerSambaVideos
                  contentReference={contentUrl}
                  controlsToEnable={getControlsToEnable()}
                />
            )}
            <Title>{videoInfos?.title}</Title>
            <Description>{videoInfos?.description}</Description>
          </>
        ) : (
          <span></span>
        )}
      </Content>
    </PageContainer>
  );
};

export default PageVideo;
