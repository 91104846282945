import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AiOutlineDown} from 'react-icons/ai';
import Course from '../../../../services/models/course';
import DisciplineContext from '../../DisciplineContext';
import CourseContent from './components/CourseContent';
import {
  CourseTitleContainer,
  DisciplineCourseContainer,
  CourseContents,
  AvailableExamWrapper,
} from './style';
import Exam from '../../../../services/models/exam';
import {
  getExam as getExamService,
  getExamUser,
} from '../../../../services/Exam';
import Swal from 'sweetalert2';

interface DisciplineCourseProps {
  course: Course;
  onCheckApprove: (courseId: string, approved: boolean) => void;
}

const DisciplineCourse: React.FC<DisciplineCourseProps> = ({
  course,
  onCheckApprove,
}) => {
  const {
    content,
    certificateAlreadyCreated,
  } = useContext(DisciplineContext);
  const history = useHistory();
  const [opened, setOpened] = useState(false);
  const [exam, setExam] = useState({} as Exam);
  const [hasReachedExamAttemptLimit, setHasReachedExamAttemptLimit] =
    useState(false);
  const [approved, setApproved] = useState(undefined as boolean | undefined);
  const [userAverage, setUserAverage] = useState(0);

  const toogleCourse = () => {
    setOpened(!opened);
  };

  const getExam = async () => {
    const localExam = await getExamService(course.exam.exam_id);
    setExam(localExam);

    const localAttempts = await getExamUser(localExam.id, course.id);
    const finalizedAttempts = localAttempts.filter(att => !!att.final_date);
    setHasReachedExamAttemptLimit(
      finalizedAttempts.length >= localExam.attempts,
    );

    const localUserAverage = getUserAverage();
    const localUserApproved = localUserAverage >= localExam.average;

    setUserAverage(localUserAverage);
    setApproved(localUserApproved);

    if (course.approved === undefined || course.approved === null) {
      onCheckApprove(course.id, localUserApproved);
    }
  };

  const hasTakenExam = useMemo(() => {
    return (
      course &&
      course.exam &&
      (typeof course.exam.result === 'string' ||
        typeof course.exam.result === 'number')
    );
  }, []);

  const getUserAverage = () => {
    if (course.exam && course.exam.result) {
      return (+course.exam.result / +course.exam.total_questions) * 100;
    }

    return 0;
  };

  useEffect(() => {
    if (content?.courseId === course.id) {
      setOpened(true);
    }
  }, [content]);

  useEffect(() => {
    if (course.exam && course.exam.exam_id) {
      getExam();
    } else if (course && !course.exam) {
      setApproved(course.alreadyFinished);
      if (course.approved === undefined || course.approved === null) {
        onCheckApprove(course.id, course.alreadyFinished);
      }
    }
  }, [course.alreadyFinished]);



  return (
    <DisciplineCourseContainer>
      <CourseTitleContainer onClick={toogleCourse}>
        <div className={`icon ${opened ? 'opened' : 'closed'}`}>
          <AiOutlineDown color="var(--primary-blue)" />
        </div>
        <span className="title">{course.title}</span>
      </CourseTitleContainer>
      {opened && (
        <CourseContents>
          {course.contents.every(e => e.alreadyFinished) &&
            course.exam?.exam_id &&
            approved !== undefined && (
              <AvailableExamWrapper
                className={hasTakenExam && !approved ? 'reproved' : ''}
              >
                {course.exam.result ? (
                  <>
                    <p>
                      {approved ? 'Parabéns!' : 'Que pena!'} você obteve{' '}
                      {userAverage.toFixed(2)}% da prova.
                    </p>
                    {!hasReachedExamAttemptLimit && !certificateAlreadyCreated && (
                      <button
                        onClick={() => {
                          Swal.fire({
                            icon: 'warning',
                            text: 'A pontuação que prevalecerá será sempre a da última tentativa! Tem certeza que deseja refazer esta prova? ',
                            showCancelButton: true,
                            cancelButtonText: 'Cancelar',
                          }).then(result => {
                            if (result.isConfirmed) {
                              history.push(
                                `/avaliacao/${course.exam.exam_id}/${
                                  course.id
                                }${
                                  course.disciplineId
                                    ? '?disciplineId=' + course.disciplineId
                                    : ''
                                }`,
                              );
                            }
                          });
                        }}
                      >
                        Refazer Avaliação
                      </button>
                    )}
                  </>
                ) : (
                  <>
                    <p>Curso concluído!</p>
                    {!hasReachedExamAttemptLimit && (
                      <Link
                        to={`/avaliacao/${course.exam.exam_id}/${course.id}${
                          course.disciplineId
                            ? '?disciplineId=' + course.disciplineId
                            : ''
                        }`}
                      >
                        Fazer Avaliação
                      </Link>
                    )}
                  </>
                )}
              </AvailableExamWrapper>
            )}

          {course.contents && course.contents.length && (
            course.contents.map(content => (
              <CourseContent key={content.id} content={content} />
            ))
          ) }

        </CourseContents>
      )}
    </DisciplineCourseContainer>
  );
};

export default DisciplineCourse;
