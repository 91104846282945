import styled from 'styled-components';

const ExtraMaterialCardContainer = styled.div`
  width: 100%;
  padding: 1px;
  border-radius: 5px;
  border: solid 2px var(--primary-blue);
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background: var(--primary-blue);

    & > * {
      color: white;
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ExtraMaterialCardName = styled.h3`
  font-size: 18px;
  flex: 1;
`;

const ExtraMaterialTagRequired = styled.div`
  font-size: 18px;
  overflow: hidden;
  border-radius: inherit;
  padding: 3px;
  margin-right: 5px;
  background-color: #0086cc;
  color: white;
  width: auto;
`

export { ExtraMaterialCardContainer, ExtraMaterialCardName, ExtraMaterialTagRequired };
