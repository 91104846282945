import React from 'react';
import { ScoreContainer, Title, ScoreContent, ProfileBox, RankingBox, RankingList, Img, ClassificationsBoxes } from './styles';
import RankingItem from './components/RankingItem';
import ClassificationBox from './components/ClassificationBox';
import MedalBox from './components/MedalBox';
import UserDescriptions from './components/UserDescriptions';
 
// const ranking = Array(6).fill('');

const ranking = [
  {
    id: 1,
    position: 1,
    userName: "Talitha Flores",
    avatarUrl: "https://picsum.photos/1920/650?v=1",
    points: 70.000,
    medal: "Ouro",
    actualUser: false,
  },
  {
    id: 2,
    position: 2,
    userName: "Talitha Maria",
    avatarUrl: "https://picsum.photos/1920/650?v=1",
    points: 34.000,
    medal: "Prata",
    actualUser: true,
  },
  {
    id: 3,
    position: 3,
    userName: "Talitha da Silva",
    avatarUrl: "https://picsum.photos/1920/650?v=1",
    points: 56.000,
    medal: "Bronze",
    actualUser: false,
  },
  {
    id: 4,
    position: 4,
    userName: "Marcus Flores",
    avatarUrl: "https://picsum.photos/1920/650?v=1",
    points: 34.000,
    medal: "",
    actualUser: false,
  },
  {
    id: 5,
    position: 5,
    userName: "Talitha Flores",
    avatarUrl: "https://picsum.photos/1920/650?v=1",
    points: 56.000,
    medal: "",
    actualUser: false,
  },
  {
    id: 6,
    position: 6,
    userName: "Marcus Flores",
    avatarUrl: "https://picsum.photos/1920/650?v=1",
    points: 40.000,
    medal: ""
  }
]

export function setMedalColor(position: number){
  switch (position) {
    case 1:{
     return '#cbb400';
    }

    case 2:{
     return '#aaa8a8';
    }

    case 3:{
     return '#bd7005';
    }
  
    default:{
     return '#172983';
    } 
  }
}

const Score: React.FC = () => {
  return (
    <ScoreContainer>
      <Title>PONTUAÇÃO</Title>
      <ScoreContent>
        <ProfileBox>
          <Img src="https://picsum.photos/1920/650?v=1" alt="avatar" className="user-avatar"/>    
          <UserDescriptions name="Talitha FLores" points={38.000}/>
          <ClassificationsBoxes>
            <MedalBox medal="Ouro" position={1}/>
            <ClassificationBox position={1} />
          </ClassificationsBoxes>    
        </ProfileBox>

        <RankingBox>
          <RankingList>
            <h2>Ranking</h2>
            {
              ranking.map((item,index) => (
                <RankingItem 
                  actualUser={item.actualUser}
                  key={index} 
                  id={`${item.id}`}
                  userName={item.userName} 
                  avatarUrl={item.avatarUrl}
                  points={item.points} 
                  position={item.position} 
                />
              ))
            }
          </RankingList>
        </RankingBox>
      </ScoreContent>
    </ScoreContainer>
  );
}

export default Score;
