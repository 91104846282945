import styled from 'styled-components';

export const ForumContainer = styled.div`
  width: 100vw;
  padding: 60px 5% 65px;

  .forum-title {
    font-size: 40px;
    color: #099ee0;
    text-transform: uppercase;

    &::after {
      content: '';
      display: block;
      border-bottom: solid 5px #099ee0;
      margin: 20px 0;
    }
  }

  .forum-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    & > div {
      margin-bottom: 20px;
    }
  }
`;

export const Content = styled.div`
  align-items: center;

  @media (min-width: 766px) {
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
`;

export const LeftDiv = styled.div`
  .button-discursion {
    padding: 9px 15px;
    display: block;
    max-width: 220px;
    border: 1px solid #0d61a2;
    border-radius: 0px;
    background-color: transparent;
    transition: color 500ms ease, background-color 500ms ease;
    color: #333;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    margin-right: 15px;

    &:hover {
      background-color: #3898ec;
      color: white;
    }
  }
`;

export const AsideMenu = styled.aside`
  display: flex;
  flex-direction: column;

  @media (min-width: 766px) {
    width: 30%;
  }
`;

export const Main = styled.main`
  width: 100%;
  padding-bottom: 50px;

  @media (min-width: 766px) {
    margin-left: 30px;

    section {
      margin-top: 0px;
    }
  }

  .top-group {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    position: relative;
    margin-bottom: 20px;
    margin-top: 50px;

    @media (min-width: 766px) {
      margin-top: 0px;
    }
  }

  section {
    margin-top: 30px;
  }

  .button-group {
    button {
      margin-right: 10px;
      border-radius: 6px;
      background-color: #f5f5f5;
      padding: 10px 17px;
      cursor: pointer;

      svg {
        height: 20px;
        width: auto;
      }
    }
  }
`;

export const SideButton = styled.button`
  display: flex;
  font-family: Helveticaneue, sans-serif;
  padding-bottom: 15px;
  color: #333;
  background-color: transparent;
  border: none;
  font-size: 14px;
  cursor: pointer;

  svg {
    margin-right: 10px;

    height: 20px;
    width: auto;
  }
`;

export const Select = styled.select`
  border: none;
  background: transparent;
  color: #000;
  font-size: 16px;
  padding: 10px;
`;

export const TitlePage = styled.h1`
  font-size: 20px;
  font-weight: 700;
  color: #333;
`;

export const UserForum = styled.div`
  width: 100%;

  a {
    text-decoration: none;
  }
`;

export const LiUser = styled.li`
  width: 100%;
  display: flex;
  padding: 20px 20px;
  margin-top: 10px;
  align-items: center;
  border-radius: 6px;
  background-color: rgba(51, 51, 51, 0.04);
  justify-content: center;
  position: relative;
  justify-content: space-between;

  cursor: pointer;
`;

export const UserInfo = styled.div`
  display: flex;

  .user-name {
    h3 {
      font-size: 18px;
      font-weight: 400;
      color: #333;
      text-decoration: none;
    }
    .user-inicio {
      font-size: 13px;
      color: #333;
      line-height: 20px;
      position: relative;
    }
  }
`;

export const DivGeral = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .status-topic {
    height: auto;
    margin-right: 10px;
    padding: 5px;
    border-radius: 8px;
    background-color: #4f5258;
    color: #fff;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
  }

  .answers {
    height: 40px;
    display: flex;
    align-items: center;

    span {
      font-size: 15px;
      margin-left: 5px;
    }
  }

  svg {
    vertical-align: middle;
    height: 20px;
    width: auto;
  }
`;

export const QuestionGroup = styled.div``;

export const QuestionGroupTitle = styled.h3`
  margin: 25px 0;
  font-size: 24px;
`;
