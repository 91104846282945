import ExtraMaterial from '../../services/models/extra-material';
import ExtraMaterialFromResponse from '../../services/models/from-api-result/extra-material';

const convertToExtraMaterial = (
  extraMaterial: ExtraMaterialFromResponse,
): ExtraMaterial => {
  return <ExtraMaterial>{
    name: extraMaterial.name,
    reference: extraMaterial.reference,
    id: extraMaterial.extra_material_id,
    info: extraMaterial.info,
  };
};

export default convertToExtraMaterial;
