import React, { useState } from 'react';
import CardVideos from './CardVideos';
import { CardContainer, NavContainer } from './styles';
import { getAllLibraryItems as getAllLibraryItemsService } from '../../../../services/Library/index';
import Search from '../../../../components/Search';
import GoBack from '../../../../components/GoBack';
import Pagination from '../../../../components/Pagination';

enum LibraryItemEnum {
  video = 'VIDEO',
  arquivo = 'FILE',
}

export interface ILibraryItem {
  content_id: string;
  description: string;
  duration: string;
  is_active: boolean;
  reference: string;
  thumbnail: string;
  title: string;
  type: LibraryItemEnum;
}

const LibraryVideos: React.FC = () => {
  const [videos, setVideosItems] = useState([] as ILibraryItem[]);

  
  const getAllItens = async (filters?: object) => {
    let localFilters: any = {
      type: 'video',
    };

    if (filters) {
      localFilters = { ...filters, ...localFilters };
    }

    const localItems = await getAllLibraryItemsService(localFilters);

    return localItems;
  };

  return (
    <CardContainer>
      <GoBack path="/biblioteca" />
      <NavContainer>
        <h2 className="library-title">Todos os videos</h2>
        <Search
          typeSearch="content-video"
          callback
          updateList={setVideosItems}
          clear={getAllItens}
        />
      </NavContainer>
      <div className="list-cards">
        {videos && videos.length ? (
          videos.map(video => {
            return <CardVideos key={video.content_id} video={video} />;
          })
        ) : (
          <span></span>
        )}
      </div>
      <Pagination
        availableItems={videos}
        setAvailableItems={setVideosItems}
        getAllItems={filters => getAllItens(filters)}
      />
    </CardContainer>
  );
};

export default LibraryVideos;
