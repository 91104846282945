import httpClient from '../../http-client/index';

const getAllLibraryItems = async (filters?: Object) => {
  let contents = (
    await httpClient.get('/contents', {
      params: { is_library: true, is_active: true, ...filters },
    })
  ).data;

  return contents;
};

const searchContents = async (
  value: string,
  type: string,
  callback: (data: any) => void,
) => {
  const contents = (
    await httpClient.get(`/contents?is_library=true&search=${value}`)
  ).data;

  if (contents && contents.length) {
    if (type === 'VIDEO') {
      contents.filter(
        (item: any) => item.is_active === true && item.type === type,
      );
    } else {
      contents.filter(
        (item: any) => item.is_active === true && item.type !== type,
      );
    }

    callback(contents);
  }
};

const getLIbraryItem = async (contentId: any) => {
  if (!contentId) {
    throw new Error('O id do conteúdo é obrigatório para obter seus dados!');
  }

  let content = (await httpClient.get(`/contents/${contentId}`)).data;

  return content;
};

export {
  getAllLibraryItems,
  getLIbraryItem,
  searchContents,
};
