import React, { useCallback, useEffect, useState } from 'react';
import { Container } from './components/Container';
import Footer from './components/Footer';
import Header from './components/Header';
import Loading from './components/Loading';
import Routes from './components/Routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { login as loginService } from './services/Login';
import { getActualUser as getActualUserService } from './services/User';
import UserContext from './UserContext';
import User from './services/models/user';

const App: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [user, setUser] = useState({} as User);

  const login = useCallback(async () => {
    if (!window.localStorage.getItem('vallourec-api-token')) {
      await tryLogin();
    } else {
      try {
        await getActualUserService();
        changeToHome();
        setIsAuthorized(true);
      } catch (error) {
        window.localStorage.removeItem('vallourec-api-token');
        await tryLogin();
      }
    }
  }, [history]);

  const tryLogin = async () => {
    try {
      await loginService();
      changeToHome();
      setIsAuthorized(true);
    } catch (error) {
      window.location.href = `${process.env.REACT_APP_REDIRECT_LOGIN}`;
    }
  };

  const changeToHome = () => {
    if (
      location.pathname === '' ||
      location.pathname === '/' ||
      location.pathname === '/login' ||
      location.pathname === '/home'
    ) {
      history.push('/home');
    }
  };

  const getActualUser = async () => {
    const localUser = await getActualUserService();
    setUser(localUser);
  };

  useEffect(() => {
    login();
  }, [login]);

  useEffect(() => {
    if (isAuthorized) {
      getActualUser();
    }
  }, [isAuthorized, location.pathname]);

  return isAuthorized ? (
    <UserContext.Provider value={{ user, setUser }}>
      <Container>
        <ToastContainer position="bottom-right" />
        <Header />

        <Routes />
        <Loading />

        <Footer />
      </Container>
    </UserContext.Provider>
  ) : (
    <></>
  );
};

export default App;