import styled from 'styled-components';

export const AnnotationLessonCardContainer = styled.div`
  width: 100%;
  background: #FFF;
  padding: 20px 40px;
  margin: 10px;

  .annotation-lesson-title-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    & > *:first-child {
      margin-right: 15px;
    }

    .annotation-lesson-toogle-icon-container {
      transition: 0.3s;

      &.arrow-up {
        transform: rotate(180deg);
      }

      &.arrow-down {
        transform: rotate(0deg);
      }
    }

    .annotation-lesson-title {
      color: #333;
    }
  }

  .annotation-lesson-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    transform: scaleY(0);
    height: 0;
    overflow: hidden;

    &.show {
      transition: 0.3s;
      height: auto;
      transform: scaleY(1);
      margin-top: 10px;
    }

    & > li {
      list-style: none;
      width: 100%;
    }
  }

  .annotation-lesson-link {
    display: block;
    margin-top: 10px;
    font-weight: bold;
  }
`;