import React from 'react';
import LibraryItemCard from './components/LibraryItemCard';
import GoBack from '../../components/GoBack';
import { LibraryContainer } from './style';

const LetItems = [
  {
    "id": 1,
    "title": "Videos",
    "type": 1
  },
  {
    "id": 2,
    "title": "Material de apoio",
    "type": 2
  }
]

const Library: React.FC = () => {
  return (
    <LibraryContainer>
      <GoBack path="/home"/>
      <h2 className="library-title">
        BIBLIOTECA
      </h2>

      <div className="library-content">
        {
          LetItems
            .map(
              libraryItem => (
                <LibraryItemCard key={libraryItem.id} libraryItem={libraryItem} />
              )
            )
        }
      </div>
    </LibraryContainer>
  )
}

export default Library;
