import Content from '../../services/models/content';
import ContentFromResponse from '../../services/models/from-api-result/content';
import ContentUserFromResponse from '../../services/models/from-api-result/content-user';

const convertToContent = (
  content: ContentFromResponse,
  user?: ContentUserFromResponse,
  disciplineId?: string,
  courseId?: string,
  isFirstContentOfCourse?: boolean,
  isFirst?: boolean,
  isLast?: boolean,
  isLastCourse?: boolean,
): Content => {
  return {
    id: content.content_id,
    disciplineId: !!disciplineId ? disciplineId : '',
    courseId: !!courseId ? courseId : '',
    name: content.title,
    description: content.description,
    alreadyStarted: !!(user && user.start_at),
    alreadyFinished: !!(user && user.finish_at),
    reference: content.reference,
    duration: content.duration,
    content_view: user ? user.content_view : null,
    position: content.position,
    isLocked: !isFirstContentOfCourse,
    scorm_version: content.info ? content.info.scorm_version : '',
    isLastContent: isLastCourse && isLast,
    type: content.type,
    isLastOfCourse: isLast,
    isFirstOfCourse: isFirst,
  };
};

export default convertToContent;
