import React from 'react';
import { FaMedal } from 'react-icons/fa';
import { RankingComponent, Position, ProfileAvatar } from './styles';

interface IRankingItem {
  id: string;
  position: number;
  avatarUrl: string;
  userName: string;
  points: number;
  actualUser: boolean | undefined;
}

const RankingItem: React.FC<IRankingItem> = ({ id, position, avatarUrl, userName, points, actualUser }) => {
  return (
    <RankingComponent className={`${actualUser ? 'blue-border' : ''}`}>
      <Position>{position}º</Position>
      <ProfileAvatar>
        <img src={avatarUrl} alt="avatar" />
        <span>{userName}</span>                    
      </ProfileAvatar>
      <strong>{`${points}.000`}s</strong>
        {(position === 1 ? (<FaMedal size={20} color="#cbb400" />) : '' )}
        {(position === 2 ? (<FaMedal size={20} color="#aaa8a8" />) : '' )}
        {(position === 3 ? (<FaMedal size={20} color="#bd7005" />) : '' )}
        {(position > 3 ? (<FaMedal size={20} color="#172983" />) : '')}
    </RankingComponent>
  );
}

export default RankingItem;
