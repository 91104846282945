import QuestionFromResponse from '../../services/models/from-api-result/exam-question';
import Question from '../../services/models/exam-question';

const convertToQuestion = (question: QuestionFromResponse): Question => {
  return {
    id: question.question_id,
    answer: question.answer,
    enunciated: question.enunciated,
    enabled: !!question.enabled,
    isCanceled: !!question.is_canceled,
    points: question.points ? question.points : 0,
    questionType: question.question_type,
    randomizeAlternatives: !!question.randomize_alternatives,
    alternative:
      question.alternative && question.alternative.length
        ? !question.randomize_alternatives &&
          question.alternative.every(alternative => !!alternative.order)
          ? question.alternative
              .sort((altA, altB) =>
                altA.order && altB.order && altA.order > altB.order ? 1 : -1,
              )
              .map(alt => ({
                id: alt.alternative_id,
                order: alt.order ? +alt.order : 0,
                value: alt.value,
              }))
          : question.alternative
              .sort(() => (Math.random() > Math.random() ? 1 : -1))
              .map(alt => ({
                id: alt.alternative_id,
                order: alt.order ? +alt.order : 0,
                value: alt.value,
              }))
        : [],
  };
};

export default convertToQuestion;
