import React, { useEffect } from 'react';
import { useContext } from 'react';
import DisciplineContext from '../../../../DisciplineContext';
import { PlayScormContainer } from './style';
import 'scorm-again';

declare let window: any;

interface PlayScormProps {
  contentReference?: string;
}

const PlayScorm: React.FC<PlayScormProps> = ({ contentReference }) => {

  const { startContent, finishContent, goToNextContent, content } =
    useContext(DisciplineContext);
 

    useEffect(() =>{ 
  if (content?.scorm_version === '2004') {
    window.API_1484_11 = new window.Scorm2004API({}); ;

    window.API_1484_11.on("Initialize", async function() {
      await startContent();
    });
    
    let completionStatus = "";
    let successStatus = "";
    window.API_1484_11.on(
      'SetValue.cmi.*',
      async (CMIElement: string, value: string) => {
        if (CMIElement === 'cmi.completion_status' && value === 'completed'){
          completionStatus=value;
          if(successStatus === 'passed'){
            try {
              await finishContent();
            } catch (error) {}
            goToNextContent();
          }
        }
        if(CMIElement === 'cmi.success_status' && value === 'passed') {
          successStatus=value;
          if(completionStatus === 'completed'){
            try {
              await finishContent();
            } catch (error) {}
            goToNextContent();
          }
        }
      },
    );
  } else {
    window.API = new window.Scorm12API({});

    window.API.on('LMSInitialize', async function () {
      await startContent();
    });

    window.API.on(
      'LMSSetValue.cmi.core.lesson_status',
      async (CMIElement: any, value: string) => {
        if (value === 'completed') {
          try {
            await finishContent();
          } catch (error) {}
          goToNextContent()
        }
      },
    );
  }

  return () => {
    if (content?.scorm_version === '2004') {
      window.API_1484_11.clear('Initialize');
      window.API_1484_11.clear('SetValue.cmi.*');
      window.API_1484_11 = undefined;
    } else {
      window.API.clear('LMSInitialize');
      window.API.clear('LMSSetValue.cmi.core.lesson_status');
      window.API = undefined;
    }
  };
}, []);

return <div>
    <PlayScormContainer>
       <iframe
         title="referenced-scorm"
         src={contentReference}
         frameBorder={0}
         allowFullScreen
         className="iframe-scorm"
       ></iframe>
     </PlayScormContainer>
</div>;
};

export default PlayScorm;

