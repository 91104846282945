import styled from 'styled-components';

export const EvaluationContainer = styled.div`
  width: 100vw;
`;

export const EvaluationContent = styled.div`
  width: 95vw;
  padding: 0 20px;
  margin: 0 auto;
  margin-top: 30px;
`;

export const Title = styled.h1`
  color: var(--primary-blue);
  text-transform: uppercase;
  font-size: 40px;
  margin: 55px 0 50px;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 5px;
    background: var(--primary-blue);
    position: absolute;
    bottom: -20px;
    left: 0;
  }
`;

export const BeforeInitExam = styled.div`
  margin-bottom: 20px;

  .instructions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 24px;
      font-weight: 700;
    }

    .execution,
    .average {
      display: flex;
      align-items: center;

      h4 {
        margin-right: 10px;
      }

      span {
        color: var(--primary-blue);
        text-align: center;
        font-size: 30px;
      }
    }
  }

  .initialize {
    text-align: center;
    margin-top: 50px;

    button {
      padding: 10px 38px;
      border: 1px solid #fff;
      background-color: var(--primary-blue);
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      cursor: pointer;

      :hover {
        background-color: #fff;
        color: var(--primary-blue);
        border: 1px solid var(--primary-blue);
      }
    }
  }
`;
