import React, { useEffect, useState } from 'react';
import CertificateFromResponse from '../../../../../../services/models/from-api-result/certificate';
import { CertificatesContainer } from './styles';
import { getCertificates as getCertificatesService } from '../../../../../../services/MyProgress';
import CertificateCard from '../../../../../../components/CertificateCard';

const Certificates: React.FC = () => {
  const [certificates, setCertificates] = useState(
    [] as CertificateFromResponse[],
  );

  const getCertificates = async () => {
    const localCertificates = await getCertificatesService();
    setCertificates(localCertificates);
  };

  useEffect(() => {
    getCertificates();
  }, []);

  return (
    <CertificatesContainer>
      {certificates && certificates.length ? (
        certificates.map(certificate => (
          <CertificateCard
            key={certificate.certificate_id}
            certificate={certificate}
          />
        ))
      ) : (
        <h4>Não foram encontrados certificados!</h4>
      )}
    </CertificatesContainer>
  );
};

export default Certificates;
