import React from 'react';
import { useState } from 'react';
import { AiFillStar } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { hideModal } from '../../../../../../components/Modal';
import getErrorMessage from '../../../../../../helpers/get-error-message';
import { rateCourse } from '../../../../../../services/Course';
import * as S from './styles';

interface CourseRateProps {
  trailId: string;
  onRate: (stars: number) => void;
  setShowReviewButton: (value: React.SetStateAction<boolean>) => void;
}

const CourseRate: React.FC<CourseRateProps> = ({
  trailId,
  onRate,
  setShowReviewButton,
}) => {
  const numberOfStars = 5;
  const [stars, setStars] = useState(5);
  const [deposition, setDeposition] = useState('');

  const rateCourseHere = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      await rateCourse(trailId, stars, deposition);
      onRate(stars);
      hideModal();
      setShowReviewButton(false);
      Swal.fire({ icon: 'success', title: 'Curso avaliado com sucesso!' });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        title: 'Houve um erro ao avaliar o curso.',
        text: errorMessage,
      });
    }
  };

  return (
    <S.CourseRateContainer onSubmit={rateCourseHere}>
      <div className="stars">
        <label htmlFor="stars" className="required">
          Estrelas
        </label>
        <div>
          {Array(numberOfStars)
            .fill('')
            .map((_, index) => (
              <button type="button" onClick={() => setStars(index + 1)}>
                <AiFillStar
                  size={24}
                  key={index}
                  color={index + 1 <= stars ? '#FCC201' : '#333'}
                />
              </button>
            ))}
        </div>
      </div>
      <div>
        <label htmlFor="deposition">Depoimento</label>
        <textarea
          id="deposition"
          value={deposition}
          onChange={e => setDeposition(e.target.value)}
        ></textarea>
      </div>
      <div className="buttons">
        <button>Enviar</button>
      </div>
    </S.CourseRateContainer>
  );
};

export default CourseRate;
