import React from 'react';
import { CardContainer } from './styles';
import { useHistory } from 'react-router-dom';

export interface Video {
  content_id: string;
  thumbnail: string;
  title: string;
  duration: string;
  type: string;
}

interface VideoProps {
  video: Video;
}

const CardVideos: React.FC<VideoProps> = ({ video }) => {
  const history = useHistory();

  const goToDiscipline = () => {
    history.push(`video/${video.content_id}`);
  };
  const timeInt = parseInt(video.duration, 10);
  const durationConvert = Math.floor(timeInt / 60);

  return (
    <CardContainer>
      <div className="discipline-image-container" onClick={goToDiscipline}>
        {video.thumbnail === null ? (
          <img
            src={`${process.env.PUBLIC_URL}/video-default-thumbnail.svg`}
            alt="thumbnail"
          />
        ) : (
          <img src={video.thumbnail} alt="disciplina" />
        )}
        <span className="discipline-duration">
          {durationConvert < 1 ? '< 1' : durationConvert} min
        </span>
      </div>

      <div className="discipline-infos">
        <span className="video-type">{video.type}</span>

        <h3 className="discipline-title">{video.title}</h3>
      </div>
    </CardContainer>
  );
};

export default CardVideos;
